import { useEffect, useRef, useState } from 'react'
import { useOuterClick } from '../../../../common/hooks'
import {
  analytics,
  analyticsEvent,
  analyticsEventLabel,
} from '../../../../common/utility/analytics'
import { Tag } from '../../../../redux/features/OnDemand/onDemandWorkouts'
import { FilterProps } from '../../OnDemandWorkouts.d'

type UseFilters = {
  deviceWidth: number
  filters: Tag[]
  isFavoriteFilterApplied: boolean
  isHideCompletedFilterApplied: boolean
  isLoadingFilters: boolean
  isLoadingWorkouts: boolean
  isLoggedIn: boolean
  tagFiltersApplied: number[]
  onGetFilteredWorkouts: (
    tagFilterIds: number[],
    hideCompleted: boolean,
  ) => void
  onGetFavoriteWorkouts: () => void
  setIsOverlayOn?: (arg: boolean) => void
}

const { trackCustomEvent } = analytics
const { BUTTON_CLICK, CLOSE, MODAL } = analyticsEvent
const {
  WORKOUTS: { CLEAR, FILTERS, ON_DEMAND_FILTERS },
} = analyticsEventLabel

const useFilters = ({
  deviceWidth,
  filters,
  isFavoriteFilterApplied,
  isHideCompletedFilterApplied,
  isLoadingFilters,
  isLoadingWorkouts,
  isLoggedIn,
  tagFiltersApplied,
  onGetFilteredWorkouts,
  onGetFavoriteWorkouts,
  setIsOverlayOn = () => {},
}: UseFilters) => {
  const numberOfFiltersApplied =
    tagFiltersApplied.length + (isHideCompletedFilterApplied ? 1 : 0)

  const filtersApplied = !!numberOfFiltersApplied

  const filterListRef = useRef<HTMLElement>(null)

  const [filtersVisible, setFiltersVisible] = useState(false)

  useEffect(() => {
    filtersVisible && trackCustomEvent(MODAL(FILTERS))
  }, [filtersVisible])

  const showFiltersListAndOverlay = (toBeVisible: boolean) => {
    setFiltersVisible(toBeVisible)
    setIsOverlayOn(toBeVisible)
  }

  const handleFiltersBtnClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    showFiltersListAndOverlay(true)
    trackCustomEvent(BUTTON_CLICK(FILTERS))
  }

  const handleClearBtnClick = (e: React.MouseEvent) => {
    onGetFilteredWorkouts([], false)
    trackCustomEvent(BUTTON_CLICK(CLEAR))
  }

  const countAllFiltersApplied = (): number => {
    const numOfTags = tagFiltersApplied.length
    const additionalFilters = isHideCompletedFilterApplied ? 1 : 0
    return numOfTags + additionalFilters
  }

  useOuterClick(filterListRef, () => {
    showFiltersListAndOverlay(false)
    trackCustomEvent(CLOSE(ON_DEMAND_FILTERS))
  })

  const filterProps: FilterProps = {
    deviceWidth,
    filterListRef,
    filters,
    filtersApplied,
    filtersVisible,
    isFavoriteFilterApplied,
    isHideCompletedFilterApplied,
    isLoadingFilters,
    isLoadingWorkouts,
    isLoggedIn,
    tagFiltersApplied,
    countAllFiltersApplied,
    handleClearBtnClick,
    handleFiltersBtnClick,
    onGetFilteredWorkouts,
    onGetFavoriteWorkouts,
    onOverlayIsSet: setIsOverlayOn,
    showFiltersListAndOverlay,
  }

  return filterProps
}

export default useFilters
