import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { Alert } from '@campgladiator/cgui-core.molecules.alert'
import * as grid from '@campgladiator/cgui-core.styles.helpers.grid'
import { Growl } from '../../common/components/Growl'
import { useCheckin, useDeviceWidth } from '../../common/hooks'
import {
  analytics,
  analyticsEvent,
  analyticsEventLabel,
} from '../../common/utility/analytics'
import { trackFavoriteEvents } from '../../common/utility/commonAnalytics'
import configuration from '../../configuration'
import { authSelector, redirectToPath } from '../../redux/features/Auth'
import {
  checkInReset,
  checkInSelector,
} from '../../redux/features/OnDemand/CheckIn'
import {
  favorite,
  favoriteSelector,
} from '../../redux/features/OnDemand/Favorite'
import { Tag, Trainer } from '../../redux/features/OnDemand/onDemandWorkouts.d'
import {
  getWorkout,
  selectedWorkoutSelector,
} from '../../redux/features/OnDemand/SelectedWorkout'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { Skeleton } from './components/Skeleton'
import { WorkoutCheckIn } from './components/WorkoutCheckIn'
import { WorkoutHeader } from './components/WorkoutHeader/'
import { WorkoutHighlightsAndOverview } from './components/WorkoutHighlightsAndOverview'
import { WorkoutPageControls } from './components/WorkoutPageControls/'
import { WorkoutTrainer } from './components/WorkoutTrainer/'
import { WorkoutVideo } from './components/WorkoutVideo'
import { WorkoutVideoOverlay } from './components/WorkoutVideoOverlay'
import styles from './OnDemandWorkoutsDetail.module.scss'
import { UserCheckInStatusType, checkInAlertContent, checkInErrors } from '.'

const mobileBreakpoint = 576

const { cgWebsiteUrl } = configuration
const { trackCustomEvent } = analytics
const { AUTOMATED_CHECKIN, BUTTON_CLICK, CLOSE, MODAL } = analyticsEvent
const {
  WORKOUT_DETAILS: {
    AUTOMATED_CHECK_IN,
    CHECK_IN,
    CHECKIN_BADGE,
    CHECK_IN_INSIDE_MODAL,
    AUTOMATED_CHECK_IN_FAILED,
    TRAINER_INFORMATION,
  },
} = analyticsEventLabel

const checkinBadgeVisibilityTimeInSeconds = 90

const OnDemandWorkoutsDetail = ({ ...props }) => {
  const initialCheckInDone = useRef(false)
  const [isPageInitialized, setIsPageInitialized] = useState(false)
  const [checkInReady, setCheckInReady] = useState<boolean>(false)
  const [isCheckinInProgress, setIsCheckinInProgress] = useState<boolean>(false)
  const [countdown, setCountdown] = useState<number>(
    checkinBadgeVisibilityTimeInSeconds,
  )
  const [automatedCheckInStatus, setAutomatedCheckInStatus] = useState<
    'idle' | 'success' | 'fail'
  >('idle')
  const [showCheckInBadge, setShowCheckInBadge] = useState<boolean>(false)
  const [allowManualCheckIn, setAllowManualCheckIn] = useState<boolean>(false)
  const [isCheckInEligible, setIsCheckInEligible] = useState<boolean>(true)
  const workoutIdFromUrl = parseInt(props.match.params.workoutId)
  const { selectedWorkout, isLoading: isLoadingWorkout } = useAppSelector(
    selectedWorkoutSelector,
  )
  const { user, isLoggedIn, token } = useAppSelector(authSelector)
  const { createCheckin } = useCheckin()
  const decodedUrl = decodeURIComponent(window.location.href)
  const url = new URL(decodedUrl)
  const params = new URLSearchParams(url.search)
  const shouldTriggerCheckin = params.get('checkin') === 'true'

  const {
    isCheckedIn,
    error,
    percentRequiredForCheckin,
    isLoading: isCheckinLoading,
  } = useAppSelector(checkInSelector)
  const allFavorites = useAppSelector(favoriteSelector.selectEntities)

  const deviceWidth = useDeviceWidth()
  const intervalRef = useRef(0)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const history = useHistory()
  const isMobile = deviceWidth < mobileBreakpoint

  const userCheckInStatusMsg: UserCheckInStatusType = !isCheckInEligible
    ? 'notEligible'
    : error === checkInErrors.alreadyCheckedInToday
    ? 'duplicate'
    : error === checkInErrors.checkInLimitReached
    ? 'limit'
    : error === checkInErrors.tokenExpired
    ? 'tokenExpired'
    : isCheckedIn
    ? 'checkedIn'
    : 'none'
  const needToLoginAgain = userCheckInStatusMsg === 'tokenExpired'
  const alertMessage = needToLoginAgain
    ? 'Your session has tokenExpired. Please login again to check in to this workout..'
    : 'Oops! Something is wrong with check-in automation. Please check in manually.'

  const isLoadingFavorite =
    (allFavorites &&
      allFavorites[selectedWorkout.id] &&
      allFavorites[selectedWorkout.id].isLoading) ||
    false

  const sendCheckInCall = useCallback(() => {
    if (!isCheckinInProgress) {
      const result =
        user?.id &&
        createCheckin({
          token,
          workoutId: workoutIdFromUrl,
          userId: user.id,
          type: 'ONDEMAND',
        })
      setIsCheckinInProgress(true)
      return result
    }
  }, [user.id, isCheckinInProgress, createCheckin, token, workoutIdFromUrl])

  const provisionCheckIn = useCallback(
    (percentPlayed: number) =>
      isCheckInEligible &&
      percentPlayed >= percentRequiredForCheckin &&
      setCheckInReady(true),
    [isCheckInEligible, percentRequiredForCheckin],
  )

  const triggerCheckIn = () => {
    user?.id &&
      createCheckin({
        token,
        workoutId: workoutIdFromUrl,
        userId: user.id,
        type: 'ONDEMAND',
      })
  }

  const triggerInitialCheckin = useCallback(() => {
    if (
      shouldTriggerCheckin &&
      user?.id &&
      token &&
      workoutIdFromUrl &&
      initialCheckInDone.current === false
    ) {
      initialCheckInDone.current = true
      user?.id &&
        createCheckin({
          token,
          workoutId: workoutIdFromUrl,
          userId: user.id,
          type: 'ONDEMAND',
        })
    }
  }, [createCheckin, shouldTriggerCheckin, token, user.id, workoutIdFromUrl])

  const handleOnCheckInClick = async (component: 'modal' | 'button') => {
    trackCustomEvent(
      BUTTON_CLICK(component === 'modal' ? CHECK_IN_INSIDE_MODAL : CHECK_IN),
    )

    if (needToLoginAgain) {
      history.push(
        `/login?redirectUrl=${encodeURIComponent(
          window.location.href,
        )}?checkin=true`,
      )
    }

    triggerCheckIn()
  }

  const handleWorkoutCheckInBadgeRender = () => {
    trackCustomEvent(MODAL(CHECKIN_BADGE))
  }

  const handleSuccessfulAutomatedCheckin = () => {
    trackCustomEvent(AUTOMATED_CHECKIN(AUTOMATED_CHECK_IN))
  }

  const handleAllowManualCheckIn = () => {
    trackCustomEvent(MODAL(AUTOMATED_CHECK_IN_FAILED))
  }

  const handleGrowlClose = () => {
    trackCustomEvent(CLOSE(AUTOMATED_CHECK_IN_FAILED))
  }

  const handleOnTrainerClick = (trainer: Trainer) => {
    const trainerId = trainer.email?.split('@')[0]

    trackCustomEvent(BUTTON_CLICK(TRAINER_INFORMATION))
    window.open(`${cgWebsiteUrl}/trainer/${trainerId}`, '_blank')
  }

  const [videoEnded, setVideoEnded] = useState(false)

  const handleOnFavoriteClick = (isFavorite: boolean) => {
    const favoriteParams = {
      workoutId: workoutIdFromUrl,
      isFavorite,
    }
    trackFavoriteEvents(isFavorite)
    dispatch(favorite(favoriteParams))
  }

  const handleOnLoginClick = () => {
    dispatch(redirectToPath(location.pathname))
    history.push(
      `/login?redirectUrl=${encodeURIComponent(window.location.href)}`,
    )
  }

  useEffect(() => {
    dispatch(checkInReset())
    dispatch(getWorkout(workoutIdFromUrl))
    setVideoEnded(false)
    setIsPageInitialized(true)
  }, [dispatch, isLoggedIn, workoutIdFromUrl])

  useEffect(() => {
    setAllowManualCheckIn(
      !!error &&
        error !== checkInErrors.alreadyCheckedInToday &&
        error !== checkInErrors.checkInLimitReached,
    )
  }, [error])

  useEffect(() => {
    allowManualCheckIn && handleAllowManualCheckIn()
  }, [allowManualCheckIn])

  useEffect(() => {
    const constraintTag = selectedWorkout?.tags?.find(
      (tag: Tag) => tag.tagType === 'constraint' && tag.title === 'CheckIn',
    )
    setIsCheckInEligible(!constraintTag)
  }, [selectedWorkout.tags])

  useEffect(() => {
    checkInReady && !isCheckedIn && isLoggedIn && sendCheckInCall()
  }, [checkInReady, isCheckedIn, isLoggedIn, sendCheckInCall])

  useEffect(() => {
    if (automatedCheckInStatus === 'idle') {
      if (isCheckedIn) {
        setAutomatedCheckInStatus('success')
        handleSuccessfulAutomatedCheckin()
      } else if (!!error) {
        setAutomatedCheckInStatus('fail')
      }
    }
  }, [automatedCheckInStatus, error, isCheckedIn])

  useEffect(() => {
    setShowCheckInBadge(isCheckedIn)
  }, [isCheckedIn])

  useEffect(() => {
    showCheckInBadge && handleWorkoutCheckInBadgeRender()
  }, [showCheckInBadge])

  useEffect(() => {
    videoEnded && setShowCheckInBadge(false)
  }, [videoEnded])

  useEffect(() => {
    if (isCheckedIn && countdown > 0) {
      intervalRef.current = window.setInterval(() => {
        setCountdown(countdown - 1)
      }, 1000)
    } else if (isCheckedIn && countdown <= 0) {
      setShowCheckInBadge(false)
      clearInterval(intervalRef.current)
    } else {
      clearInterval(intervalRef.current)
    }

    return () => clearInterval(intervalRef.current)
  }, [countdown, isCheckedIn])

  useEffect(() => {
    triggerInitialCheckin()
  }, [triggerInitialCheckin])

  return (
    <>
      {allowManualCheckIn && (
        <Growl
          alertType="warning"
          button={{
            onClick: () => handleOnCheckInClick('modal'),
            disabled: isCheckinLoading,
            text: needToLoginAgain ? 'Login' : 'Check In',
          }}
          onClose={handleGrowlClose}
          dismissable
          icon={{ type: 'line', name: 'icon-exclamation-triangle' }}
          theme="light"
          title={alertMessage}
          size="xsmall"
        />
      )}
      <div className={classNames(grid.container, styles.content)}>
        <WorkoutPageControls />
        {!isPageInitialized || isLoadingWorkout ? (
          <Skeleton isMobile={isMobile} />
        ) : (
          selectedWorkout.hasOwnProperty('id') && (
            <>
              <WorkoutHeader
                isLoadingFavorite={isLoadingFavorite}
                isLoggedIn={isLoggedIn}
                isFavorite={selectedWorkout.favorite}
                title={selectedWorkout.title}
                onFavoriteClick={() =>
                  handleOnFavoriteClick(selectedWorkout.favorite)
                }
              />
              <div className={styles.videoWrapper}>
                <WorkoutVideo
                  hasOnlineAccess={user.hasOnlineAccess}
                  isLoggedIn={isLoggedIn}
                  onLoginClick={handleOnLoginClick}
                  onPercentPlayedChange={
                    isCheckInEligible ? provisionCheckIn : undefined
                  }
                  onVideoEnded={setVideoEnded}
                  src={selectedWorkout.link}
                  workoutId={selectedWorkout.id}
                />
                {isLoggedIn && videoEnded && user && (
                  <WorkoutVideoOverlay
                    userName={user.firstName || ''}
                    showConfirmationCard={false}
                  />
                )}
              </div>
              <section className={styles.userInformationContainer}>
                {selectedWorkout.trainer && (
                  <WorkoutTrainer
                    trainer={selectedWorkout.trainer}
                    onTrainerClick={() =>
                      handleOnTrainerClick(selectedWorkout.trainer as Trainer)
                    }
                  />
                )}
                {allowManualCheckIn ? (
                  <WorkoutCheckIn
                    disabled={isCheckinLoading}
                    checkInStatus={userCheckInStatusMsg}
                    onCheckInClick={() => handleOnCheckInClick('button')}
                  />
                ) : (
                  <Alert
                    icon={checkInAlertContent[userCheckInStatusMsg].icon}
                    title={checkInAlertContent[userCheckInStatusMsg].title}
                    size="xsmall"
                    theme="light"
                    alertType={checkInAlertContent[userCheckInStatusMsg].type}
                    className={styles.checkinAlert}
                  >
                    {checkInAlertContent[userCheckInStatusMsg].text}
                  </Alert>
                )}
              </section>
              <WorkoutHighlightsAndOverview
                deviceWidth={deviceWidth}
                workout={selectedWorkout}
              />
            </>
          )
        )}
      </div>
    </>
  )
}

export default OnDemandWorkoutsDetail
