import configuration from '../../../configuration'
import { AuthToken } from '../../../redux/features/Auth/auth'
import { CheckInJsonSuccessResponse } from '../../../redux/features/OnDemand/CheckIn'
import { WorkoutType } from '../../types'
import { removeAuthToken } from '../../utility/authTokenStorage'
import { request } from '../../utility/fetch'

export type CheckinApiParams = {
  authToken: AuthToken
  type: WorkoutType
  userId: string | null | undefined
  workoutId: number
}

const { apiGatewayUrl } = configuration

const checkinsApi = () => {
  const checkinUser = async ({
    type,
    userId,
    workoutId,
    authToken,
  }: CheckinApiParams): Promise<CheckInJsonSuccessResponse> => {
    const baseUrl = apiGatewayUrl
    const endpoint = '/engagement/v1/checkins'
    const method = 'POST'

    const body = JSON.stringify({
      type,
      userId,
      workoutId,
    })
    const response = await request({
      baseUrl,
      endpoint,
      method,
      body,
      authToken,
      invalidateUserAccessCallback: removeAuthToken,
    })
    const result = await response.json()

    return response.ok
      ? (result as CheckInJsonSuccessResponse)
      : Promise.reject(new Error(result.message))
  }

  return {
    checkinUser,
  }
}

export default checkinsApi
