import { Button } from '@campgladiator/cgui-core.atoms.button'
import { TagsDTO } from '../../../types/workout'
import { analytics, analyticsEvent } from '../../../utility/analytics'
import styles from './TagFilter.module.scss'

const { trackCustomEvent } = analytics
const { SET_SELECTOR } = analyticsEvent

type TagFilterProps = {
  onFilterChange: (tagId?: number) => void
  selectedTagId: number | null | undefined
  styleFilterOptions: TagsDTO[]
}

const TagFilter = ({
  onFilterChange,
  selectedTagId,
  styleFilterOptions,
}: TagFilterProps) => {
  const onClickHandler = (tagId?: number, tagTitle?: string) => {
    onFilterChange(tagId)
    trackCustomEvent(SET_SELECTOR(tagTitle!))
  }

  return (
    <div className={styles.content}>
      {styleFilterOptions.map((option) => (
        <Button
          variation="outline"
          emphasis={option.id === selectedTagId ? 'primary' : 'secondary-alt'}
          size="small"
          onClick={() =>
            selectedTagId === option.id
              ? onClickHandler()
              : onClickHandler(option.id, option.title)
          }
          data-testId="chip"
          className={styles.cursorPointer}
        >
          {option.title}
        </Button>
      ))}
    </div>
  )
}

export default TagFilter
