import {
  SharedAttributeKey,
  SpanAttribute,
} from '@campgladiator/cg-common.utility.hooks.tracing'
import * as OnDemanCheckin from '../../redux/features/OnDemand/CheckIn'
import * as VirtualCheckin from '../../redux/features/Virtual/CheckIn'
import { useAppDispatch } from '../../redux/store'
import { checkinsApi } from '../networking/checkins'
import { WorkoutType } from '../types'
import useTracing from './useTracing'

const getActions = (type: WorkoutType, workoutId: number) => {
  let checkInPending, checkinSuccess, checkInFailure

  switch (type) {
    case 'ONDEMAND':
      checkInPending = OnDemanCheckin.checkInPending
      checkinSuccess = OnDemanCheckin.checkInSuccess()
      checkInFailure = OnDemanCheckin.checkInFailure
      break
    case 'ONLINE':
      checkInPending = VirtualCheckin.checkInPending
      checkinSuccess = VirtualCheckin.checkInSuccess(workoutId)
      checkInFailure = VirtualCheckin.checkInFailure
      break
    default:
      throw new Error(`Invalid check-in type: ${type}`)
  }

  return { checkInPending, checkinSuccess, checkInFailure }
}

export const useCheckin = () => {
  const { traceSpan } = useTracing()
  const dispatch = useAppDispatch()

  const createCheckin = (
    args: OnDemanCheckin.CheckInParams | VirtualCheckin.CheckInParams,
  ) => {
    const { token, type, userId, workoutId } = args
    const spanName = `workout-${type.toLocaleLowerCase()}-checkin`

    const attrs = Array<SpanAttribute>()
    attrs.push({
      key: 'Workout' as SharedAttributeKey,
      value: workoutId.toString(),
    })
    attrs.push({
      key: 'User' as SharedAttributeKey,
      value: userId!,
    })

    const { checkInPending, checkinSuccess, checkInFailure } = getActions(
      type,
      workoutId,
    )
    const createCheckin = async () => {
      dispatch(checkInPending(true))
      try {
        const { checkinUser } = checkinsApi()

        await checkinUser({
          authToken: token,
          workoutId,
          userId,
          type,
        })

        dispatch(checkinSuccess)
      } catch (error: any) {
        attrs.push({
          key: 'Error' as SharedAttributeKey,
          value: JSON.stringify(error),
        })
        const errorMessage = error.message
          ? error.message
          : 'An unknown error occurred'
        dispatch(checkInFailure(errorMessage))
      }
    }

    traceSpan(spanName, createCheckin, attrs)
  }

  return {
    createCheckin,
  }
}

export default useCheckin
