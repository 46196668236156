import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import { CheckInState } from '.'

export const initialState: CheckInState = {
  isLoading: false,
  isCheckedIn: false,
  error: '',
  percentRequiredForCheckin: 75,
}

export const checkinSlice = createSlice({
  name: 'checkIn',
  initialState,
  reducers: {
    checkInPending: (state: CheckInState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    checkInSuccess: (state: CheckInState) => {
      state.isCheckedIn = true
      state.error = initialState.error
      state.isLoading = false
    },
    checkInFailure: (
      state: CheckInState,
      action: PayloadAction<CheckInState['error']>,
    ) => {
      state.error = action.payload
      state.isCheckedIn = false
      state.isLoading = false
    },
    checkInReset: () => initialState,
    clearError: (state: CheckInState) => {
      state.error = initialState.error
    },
  },
  extraReducers: (builder) => {
    builder.addCase('auth/logoutSuccess', () => initialState)
  },
})

export const {
  checkInPending,
  checkInSuccess,
  checkInFailure,
  checkInReset,
  clearError,
} = checkinSlice.actions
export const checkInSelector = (state: RootState) => state.checkIn
export default checkinSlice.reducer
