const analyticsEventLabel = {
  COMMON: {
    FAVORITE_WORKOUT: 'Favorite Workout',
    REMOVE_FAVORITE_WORKOUT: 'Remove Favorite Workout',
    FAVORITES: 'Favorites',
  },
  TRAINER_BIO: {
    BIRTHDAY: {
      GET_THIS_DEAL: 'Get This Deal!',
      LEARN_MORE: 'Learn More (Flash Deal)',
    },
    CG_NUTRITION_TERMS: 'CG Nutrition Terms',
    CLOSE_POPOVER: 'Log In or Sign Up to Message Trainer Popover',
    CONTACT_INFO: 'View Contact Info',
    LOGIN: 'Log In to Message Trainer Popover',
    LOGIN_OR_SIGN_UP: 'Log In or Sign Up to Message Trainer',
    OUTDOOR_LOCATIONS: 'Outdoor Locations',
    OUTDOOR_LOCATION_CARD: 'Outdoor Location Card',
    SIGN_UP: 'Sign Up to Message Trainer Popover',
    VIRTUAL_WORKOUTS: 'Virtual Workouts',
    VIRTUAL_WORKOUT_CARD: 'Virtual Workout Card',
    TRY_NOW: 'Try Now!',
    PROGRAMS: {
      SIGN_UP_NUTRITION: 'Sign Up Today (Nutrition)',
      LEARN_MORE_NUTRITION: 'Learn More (Nutrition)',
      SIGN_UP_ALL_ACCESS: 'Sign Up Today (All-Access)',
      LEARN_MORE_ALL_ACCESS: 'Learn More (All-Access)',
      SIGN_UP_ONLINE: 'Sign Up Today (Online)',
      LEARN_MORE_ONLINE: 'Learn More (Online)',
    },
  },
  WORKOUTS: {
    CLEAR: 'Clear',
    FILTERS: 'Filters',
    HIDE_COMPLETED_WORKOUTS: 'Hide Completed Workouts',
    ON_DEMAND_FILTERS: 'On-Demand Filters',
    SHOW_WORKOUTS: 'Show Workouts',
    UNSELECT_ALL: 'Unselect All',
    TRAINER_SELECTED: 'Trainer selected',
  },
  WORKOUT_DETAILS: {
    ALL_ON_DEMAND_WORKOUTS: 'All On-Demand Workouts',
    AUTOMATED_CHECK_IN_FAILED: 'Automated Check-In Failed',
    AUTOMATED_CHECK_IN: 'Automated Checkin',
    CHECK_IN_INSIDE_MODAL: 'Check-In Inside Modal',
    CHECK_IN: 'Check In',
    CHECKIN_BADGE: 'Check-In Badge',
    JOIN_LIVE: 'Join Live',
    FULL_SCREEN: 'Full screen',
    LOGIN_OR_SIGN_UP_TO_WORK_OUT: 'Login or Sign Up to work out',
    PLAY: 'Play',
    TRAINER_INFORMATION: 'Trainer Information',
    UPGRADE_TO_JOIN: 'Upgrade to Join',
    VOLUME_CHANGE: 'Volume Change',
  },
}

export default analyticsEventLabel
