import classNames from 'classnames'
import styles from './Divider.module.scss'

type DividerProps = {
  className?: string
}

const Divider = ({ className }: DividerProps) => (
  <div className={classNames(styles.divider, className)} />
)

export default Divider
