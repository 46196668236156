import { useCallback, useEffect, useState } from 'react'
import { DayOfWeekItem } from '..'
import { useDeviceWidth } from '../../../hooks'
import { breakpoints } from '../../../utility/breakpoints'
import { getDateBasedOnDayOfWeek } from '../../../utility/dates'
import { AbbreviatedDayOfWeek, DayOfWeek } from '../../../utility/datetimes'

type DayOfWeekDays = typeof DayOfWeek | typeof AbbreviatedDayOfWeek

const todayDayOfWeek = new Date().getDay()

const initialDayOfWeekItemsState = Object.keys(DayOfWeek)
  .filter((day) => isNaN(Number(day)))
  .map((key, index) => {
    return {
      id: index,
      description: key,
      active: todayDayOfWeek === index,
      date: getDateBasedOnDayOfWeek(index).getDate(),
    }
  }) as DayOfWeekItem[]

const orderedInitialDayOfWeekItemsState = initialDayOfWeekItemsState
  .slice(todayDayOfWeek)
  .concat(initialDayOfWeekItemsState.slice(0, todayDayOfWeek))

const { tabletSmall } = breakpoints

type UseDayOfWeekProps = {
  onClearFilterByTimeClick: () => void
}

const useDayOfWeek = ({ onClearFilterByTimeClick }: UseDayOfWeekProps) => {
  const deviceWidth = useDeviceWidth()
  const isDesktop = deviceWidth >= tabletSmall
  const [dayOfWeekItems, setDayOfWeekItems] = useState<DayOfWeekItem[]>(
    orderedInitialDayOfWeekItemsState,
  )
  const activeDayOfWeek = dayOfWeekItems.find((item) => item.active)?.id

  const setDayOfWeekBasedOnDeviceWidth = useCallback(() => {
    if (isDesktop) {
      const updatedDayOfWeekItems = getDayOfWeekBasedOnDeviceWidth(
        dayOfWeekItems,
        DayOfWeek,
      )
      setDayOfWeekItems(updatedDayOfWeekItems)
    } else {
      const updatedDayOfWeekItems = getDayOfWeekBasedOnDeviceWidth(
        dayOfWeekItems,
        AbbreviatedDayOfWeek,
      )
      setDayOfWeekItems(updatedDayOfWeekItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop])

  const getDayOfWeekBasedOnDeviceWidth = (
    items: DayOfWeekItem[],
    itemType: DayOfWeekDays,
  ) => {
    const updatedDayOfWeekItems = items.map((item) => {
      return {
        ...item,
        description: itemType[item.id],
      }
    })
    return updatedDayOfWeekItems
  }

  const handleDayOfWeekClick = (daySelected: number) => {
    const updatedDayOfWeekItems = dayOfWeekItems.map((item) => {
      return {
        ...item,
        active: item.id === daySelected,
      }
    })
    setDayOfWeekItems(updatedDayOfWeekItems)
    if (daySelected === activeDayOfWeek) {
      onClearFilterByTimeClick()
    }
  }

  useEffect(() => {
    setDayOfWeekBasedOnDeviceWidth()
  }, [setDayOfWeekBasedOnDeviceWidth])

  return {
    dayOfWeekItems,
    onDayOfWeekClick: handleDayOfWeekClick,
  }
}

export default useDayOfWeek
