import classnames from 'classnames'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import styles from './DayOfWeek.module.scss'

export type DayOfWeekItem = {
  active?: boolean
  date: number
  description: string
  id: number
}

export type DayOfWeekItemProps = {
  dayOfWeekItems: DayOfWeekItem[]
  onDayOfWeekClick: (daySelected: number) => void
}

const DayOfWeek = ({
  dayOfWeekItems,
  onDayOfWeekClick,
}: DayOfWeekItemProps) => (
  <div className={styles.nav}>
    <ul className={styles.list}>
      {dayOfWeekItems.map((nav, index) => (
        <li
          key={index}
          className={classnames(styles.item, {
            [styles.active]: nav.active,
          })}
          onClick={() => onDayOfWeekClick(nav.id)}
        >
          <div
            className={classnames(styles.link, {
              [styles.active]: nav.active,
            })}
          >
            <Paragraph size="small" weight={nav.active ? 'normal' : 'book'}>
              {nav.description}
            </Paragraph>
            <Paragraph
              className={styles.date}
              size="small"
              weight={nav.active ? 'normal' : 'book'}
            >
              {nav.date}
            </Paragraph>
          </div>
        </li>
      ))}
    </ul>
  </div>
)

export default DayOfWeek
