import { memo } from 'react'
import classNames from 'classnames'
import { Alert, AlertProps } from '@campgladiator/cgui-core.molecules.alert'
import styles from './Growl.module.scss'

type GrowlProps = {
  onClose?: () => void
} & AlertProps

const Growl = ({
  className = '',
  onClose = () => {},
  children,
  ...props
}: GrowlProps) => (
  <Alert
    className={classNames(className, styles.growl)}
    onDismiss={onClose}
    {...props}
  >
    {children}
  </Alert>
)

export default memo(Growl)
