import moment, { Moment } from 'moment'
import { formatDateTimeByDateAndMinutes } from './datetimes'

export enum DayOfWeek {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export const formatMeridianWithLocalTZ = (date: Date) =>
  moment.utc(date).local().format('hh:mm A')

export const getNextDateByDayOfWeekWithLocalTZ = (weekdayNumber: number) => {
  const now = moment.utc().local()
  const momentWeekdayNumber = ((weekdayNumber + 6) % 7) + 1
  if (now.isoWeekday() === momentWeekdayNumber) return now
  let daysUntil = (momentWeekdayNumber + 7 - now.isoWeekday()) % 7
  if (daysUntil === 0) daysUntil = 7
  const nextDate = moment.utc().local().add(daysUntil, 'days')
  return nextDate
}
export const getDayOfWeek = (date: Date) => DayOfWeek[date.getDay()]

export const getDateBasedOnDayOfWeek = (activeDayOfWeek: number) => {
  const todayDayOfWeek = new Date().getDay()

  const currentWorkoutDate = () => {
    if (activeDayOfWeek === todayDayOfWeek) {
      return moment().utc()
    } else if (activeDayOfWeek < todayDayOfWeek) {
      return moment()
        .utc()
        .add(activeDayOfWeek - todayDayOfWeek + 7, 'days')
    }
    return moment()
      .utc()
      .add(activeDayOfWeek - todayDayOfWeek, 'days')
  }
  return currentWorkoutDate().toDate()
}

export const convertFilteredTimeToMinutes = (
  day: Moment,
  filteredTime: number,
  intervalTime: number,
) => {
  const calculatedTime = filteredTime * intervalTime
  const time = formatDateTimeByDateAndMinutes(day, calculatedTime)
  return time
}
