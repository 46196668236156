import configuration from '../../../configuration'
import { AuthToken } from '../../../redux/features/Auth/auth'
import {
  FetchWorkoutType,
  Workout,
} from '../../../redux/features/OnDemand/onDemandWorkouts'
import { WorkoutsJsonSuccessResponse } from '../../../redux/features/OnDemand/Workouts'
import { TagsDTO } from '../../types/workout'
import { removeAuthToken } from '../../utility/authTokenStorage'
import { request } from '../../utility/fetch'
import { FetchRequest } from '../../utility/types/fetch'
import { parseParameters } from './logic/parseParameters'

export type WorkoutApiParams = {
  authToken?: AuthToken
  dayOfWeekFilter?: number
  endTimeFilter?: string
  favoriteFilter: boolean
  hideCompletedFilter: boolean
  isOnDemandFilter?: boolean
  page?: number
  size?: number
  startTimeFilter?: string
  tagFilters: number[]
  timeFilter: boolean
  trainerId?: string
}

const { apiGatewayUrl } = configuration

const workoutsApi = () => {
  const fetchWorkouts = async (
    workoutApiParams: WorkoutApiParams,
  ): Promise<WorkoutsJsonSuccessResponse> => {
    const requestOptions: FetchRequest = {
      baseUrl: apiGatewayUrl,
      endpoint: '/library/v1/workouts',
      method: 'GET',
      params: parseParameters(workoutApiParams),
      authToken: workoutApiParams.authToken,
      invalidateUserAccessCallback: removeAuthToken,
    }

    const response = await request(requestOptions)
    const { content, number, totalPages, totalElements, error } =
      await response.json()

    const filteredContent = content.filter(
      (workout: Workout) => workout.archived === null,
    )

    return response.ok
      ? ({
          content: filteredContent,
          number,
          totalElements,
          totalPages,
        } as WorkoutsJsonSuccessResponse)
      : Promise.reject(new Error(error))
  }

  const fetchWorkout = async (args: FetchWorkoutType): Promise<Workout> => {
    const baseUrl = configuration.apiGatewayUrl
    const endpoint = `/library/v1/workouts/${args.workoutId}`
    const method = 'GET'
    const { authToken } = args
    const response = await request({
      baseUrl,
      endpoint,
      method,
      authToken,
    })
    const data = await response.json()

    return response.ok
      ? (data as Workout)
      : Promise.reject(new Error(data.error))
  }

  const fetchWorkoutTags = async (): Promise<TagsDTO[]> => {
    const baseUrl = configuration.apiGatewayUrl
    const endpoint = '/library/v1/tags'
    const method = 'GET'
    const response = await request({
      baseUrl,
      endpoint,
      method,
    })
    const data = await response.json()

    return response.ok
      ? (data.content as TagsDTO[])
      : Promise.reject(new Error(data.error))
  }

  return {
    fetchWorkouts,
    fetchWorkout,
    fetchWorkoutTags,
  }
}

export default workoutsApi
