import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { CardPartProps } from '../../../../../../../pages/OnDemandWorkouts/OnDemandWorkouts.d'
import { Tags } from '../../../Tags'
import baseStyles from '../../BottomPartCard.module.scss'
import { visibleTags } from '../../logic'
import useVirtual from './useVirtual'
import styles from './Virtual.module.scss'
import { WorkoutTime } from './WorkoutTime'

const Virtual = ({
  workout,
  isLoggedIn,
  hasJoinedWorkout = false,
  onJoinLiveClick = () => {},
  onLoginClick,
  token = {
    refreshToken: '',
    token: '',
    type: '',
  },
  user = {},
  onCheckInClick,
  isCheckinLoading,
  checkedInWorkout,
}: CardPartProps) => {
  const {
    workoutLinkIfIsLive,
    onWorkoutClick,
    targetBasedOnIsWorkoutLive,
    workoutStartHour,
    isLive,
    userLoggedInWithVirtualAccess,
    handleCheckinClick,
    hasCheckedIn,
    checkInButtonText,
    joinLiveButtonText,
    userLoggedInWithNoVirtualAccess,
    handleRegisterClick,
    workoutStartDate,
  } = useVirtual({
    user,
    hasJoinedWorkout,
    isCheckinLoading,
    checkedInWorkout,
    isLoggedIn,
    token,
    workout,
    onCheckInClick,
    onJoinLiveClick,
  })

  return (
    <div className={baseStyles.bottomPart}>
      <Paragraph className={baseStyles.workoutTitle}>
        <a
          className={baseStyles.textLink}
          href={workoutLinkIfIsLive}
          onClick={onWorkoutClick}
          target={targetBasedOnIsWorkoutLive}
          rel="noreferrer"
        >
          {workoutStartHour}
        </a>
      </Paragraph>
      {isLive && (
        <div className={baseStyles.actions}>
          {userLoggedInWithVirtualAccess ? (
            <>
              <Button
                emphasis="primary"
                variation="outline"
                size="small"
                onClick={handleCheckinClick}
                disabled={isCheckinLoading || hasCheckedIn}
              >
                {checkInButtonText}
              </Button>
              <Button
                emphasis="primary"
                size="small"
                onClick={() =>
                  onJoinLiveClick({
                    target: targetBasedOnIsWorkoutLive,
                    workout,
                  })
                }
              >
                {joinLiveButtonText}
              </Button>
            </>
          ) : userLoggedInWithNoVirtualAccess ? (
            <Button
              emphasis="secondary"
              size="small"
              onClick={handleRegisterClick}
            >
              Register to Join
            </Button>
          ) : (
            <Button emphasis="secondary" size="small" onClick={onLoginClick}>
              Login to Join
            </Button>
          )}
        </div>
      )}
      {visibleTags.length > 0 && <Tags items={visibleTags(workout)} />}
      {!isLive && (
        <Paragraph
          size="xsmall"
          weight="book"
          className={styles.workoutAvailiability}
        >
          <WorkoutTime workoutStartDate={workoutStartDate} />
        </Paragraph>
      )}
    </div>
  )
}

export default Virtual
