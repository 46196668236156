import { memo } from 'react'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { CheckInBadgeProps } from '../../OnDemandWorkoutsDetail.d'
import styles from './CheckInBadge.module.scss'

const CheckInBadge = ({
  checkins = 0,
  size = 'default',
}: CheckInBadgeProps) => (
  <div className={styles.badge} data-size={size}>
    <Icon.Line name="icon-check" className={styles.icon} />
    {size === 'default' ? (
      <Heading type="h4" className={styles.checkins}>
        {checkins}
      </Heading>
    ) : (
      <Paragraph className={styles.checkins}>{checkins}</Paragraph>
    )}
    <Paragraph size="tiny" className={styles.subText}>
      Check-ins
    </Paragraph>
  </div>
)

export default memo(CheckInBadge)
