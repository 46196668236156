import { memo } from 'react'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import { analytics, analyticsEvent } from '../../../../common/utility/analytics'
import { FilterGroupProps } from '../../OnDemandWorkouts.d'
import styles from './FilterGroup.module.scss'

const FilterGroup = ({
  name,
  filters,
  btnSize,
  onFilterBtnClick,
  selectedFilters,
}: FilterGroupProps) => {
  const { trackCustomEvent } = analytics
  const { SET_SELECTOR } = analyticsEvent

  const handleBtnClick = (tagId: number, tagType: string, tagTitle: string) => {
    onFilterBtnClick(tagId, tagType)
    trackCustomEvent(SET_SELECTOR(tagTitle))
  }

  return (
    <div className={styles.filterGroup}>
      <Heading className={styles.heading} type="h5">
        {name}
      </Heading>
      <div className={styles.filterBtnsWrapper}>
        {filters.map((tag) => (
          <Button
            className={selectedFilters.has(tag.id) ? styles.activeBtn : ''}
            emphasis={selectedFilters.has(tag.id) ? 'primary' : 'secondary-alt'}
            variation="outline"
            size={btnSize}
            key={tag.id}
            onClick={() => handleBtnClick(tag.id, tag.tagType, tag.title)}
          >
            {tag.title}
          </Button>
        ))}
      </div>
    </div>
  )
}

export default memo(FilterGroup)
