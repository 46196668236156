import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { Trainer } from '../../../../networking/trainers'
import { DayOfWeekItem } from '../../../DayOfWeek'
import { AlternativeResultsBanner } from './components/AlternativeResultsBanner'
import styles from './NoResultsBanner.module.scss'
import useNoResultsBanner from './useNoResultsBanner'

export type NoResultsBannerProps = {
  activeDay: number
  dayOfWeekItems: DayOfWeekItem[]
  hasWorkoutResults: boolean
  onDayOfWeekClick?: (dayOfWeek: number) => void
  showNoFavoriteMsg: boolean
  trainerId?: Trainer['id']
}

const NoResultsBanner = ({
  activeDay,
  dayOfWeekItems,
  hasWorkoutResults,
  showNoFavoriteMsg,
  trainerId,
  onDayOfWeekClick,
}: NoResultsBannerProps) => {
  const { alternativeDays, hasNoFavoritesText, hasNoResultsText } =
    useNoResultsBanner({
      activeDay,
      hasWorkoutResults,
      dayOfWeekItems,
      trainerId,
    })

  const alternativeDaysText = () => {
    if (showNoFavoriteMsg) {
      return (
        <Paragraph size="large" weight="book" className={styles.text}>
          {hasNoFavoritesText}
        </Paragraph>
      )
    } else {
      return (
        <div>
          <Paragraph size="large" weight="book" className={styles.text}>
            {hasNoResultsText}
          </Paragraph>
          <AlternativeResultsBanner
            alternativeDays={alternativeDays}
            onDayOfWeekClick={onDayOfWeekClick}
          />
        </div>
      )
    }
  }

  return <div className={styles.noWorkoutsFound}>{alternativeDaysText()}</div>
}

export default NoResultsBanner
