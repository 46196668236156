import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import styles from './PlayIconOverlay.module.scss'

const PlayIconOverlay = () => (
  <div className={styles.playIconOverlay}>
    <div className={styles.circle}>
      <Icon.Line className={styles.icon} name="icon-play" />
    </div>
  </div>
)

export default PlayIconOverlay
