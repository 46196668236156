import classnames from 'classnames'
import { Button, ButtonProps } from '@campgladiator/cgui-core.atoms.button'
import styles from './Favorite.module.scss'

type FavoriteProps = {
  condensed?: boolean
  isFavorite?: boolean
  isLoadingFavorite: boolean
  workoutId?: number
  onClick: (workoutId?: number, isFavorite?: boolean) => void
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const Favorite = ({
  className = '',
  isLoadingFavorite,
  condensed = false,
  isFavorite = false,
  onClick,
  workoutId,
  style,
}: FavoriteProps) => {
  const iconProps: ButtonProps['icon'] = isFavorite
    ? { type: 'solid', name: 'icon-heart' }
    : { type: 'line', name: 'icon-heart' }

  return (
    <Button
      className={classnames(
        styles.favoriteButton,
        {
          [styles.favoriteLoading]: isLoadingFavorite,
          [styles.favorited]: isFavorite,
        },
        className,
      )}
      onClick={() => onClick(workoutId)}
      disabled={isLoadingFavorite}
      icon={iconProps}
      variation="text"
      emphasis={condensed ? 'secondary-alt' : 'secondary'}
      size={condensed ? 'xlarge' : 'large'}
    >
      {!condensed && (isFavorite ? 'Favorited' : 'Favorite')}
    </Button>
  )
}

export default Favorite
