import { memo } from 'react'
import { Link } from 'react-router-dom'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import {
  analytics,
  analyticsEvent,
  analyticsEventLabel,
} from '../../../../common/utility/analytics'
import styles from './WorkoutPageControls.module.scss'

const { trackCustomEvent } = analytics
const { LINK } = analyticsEvent
const {
  WORKOUT_DETAILS: { ALL_ON_DEMAND_WORKOUTS },
} = analyticsEventLabel

const WorkoutPageControls = () => (
  <section className={styles.workoutPageControls}>
    <Link
      to="/"
      className={styles.back}
      onClick={() => trackCustomEvent(LINK(ALL_ON_DEMAND_WORKOUTS))}
    >
      <Paragraph weight="book">{'<'} All On-Demand Workouts</Paragraph>
    </Link>
  </section>
)

export default memo(WorkoutPageControls)
