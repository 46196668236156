import { RefObject, useEffect, useState } from 'react'

const useScrolledToRefBottom = (
  elementRef: RefObject<HTMLElement>,
  bottomOffset?: number,
) => {
  const [bottomReached, setBottomReached] = useState(false)

  useEffect(() => {
    bottomReached && setBottomReached(false)
  }, [bottomReached])

  useEffect(() => {
    const checkIfBottomVisible = () => {
      elementRef?.current &&
        setBottomReached(
          window.scrollY + window.innerHeight >=
            window.pageYOffset +
              elementRef.current.getBoundingClientRect().bottom +
              (bottomOffset || 0),
        )
    }

    window.addEventListener('scroll', checkIfBottomVisible)

    return () => {
      window.removeEventListener('scroll', checkIfBottomVisible)
    }
  }, [elementRef, bottomOffset])

  return bottomReached
}

export default useScrolledToRefBottom
