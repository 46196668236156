import { forwardRef } from 'react'
import classNames from 'classnames'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Card } from '@campgladiator/cgui-core.atoms.card'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Slider } from '@campgladiator/cgui-core.atoms.slider'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import * as grid from '@campgladiator/cgui-core.styles.helpers.grid'
import { TagsDTO } from '../../../types/workout'
import { Divider } from '../../Divider'
import styles from './FilterCard.module.scss'
import TagFilter from './TagFilter'

type FilterCardProps = {
  filterButtonName: string
  filtersApplied: boolean
  handleClearBtnClick: () => void
  handleCloseBtnClick: () => void
  handleFilterClick: () => void
  handleStyleFilterChange: (tagId?: number) => void
  handleOnSliderChangeValue: (minValue: number, maxValue: number) => void
  handleSliderInputOnClick?: (event: React.MouseEvent) => void
  initialMaxValue: number
  initialMinValue: number
  isSliderFilterLoading: boolean
  startTimeLabelWhenApplied: string
  TIME_MAX: number
  styleFilterValue: number | null | undefined
  styleFilterOptions: TagsDTO[]
}

const FilterCard = forwardRef<HTMLElement, FilterCardProps>(
  (
    {
      filterButtonName,
      filtersApplied,
      initialMaxValue,
      initialMinValue,
      isSliderFilterLoading,
      startTimeLabelWhenApplied,
      TIME_MAX,
      handleClearBtnClick,
      handleCloseBtnClick,
      handleFilterClick,
      handleOnSliderChangeValue,
      handleSliderInputOnClick,
      handleStyleFilterChange,
      styleFilterValue,
      styleFilterOptions,
    }: FilterCardProps,
    ref,
  ) => {
    return (
      <div className={styles.filtersCardContainer}>
        <Card raised className={styles.filtersCard} ref={ref}>
          <div className={styles.upperPart}>
            <div className={styles.header}>
              <Button
                disabled={!filtersApplied}
                variation="text"
                className={styles.clearBtn}
                onClick={handleClearBtnClick}
              >
                Clear
              </Button>
              <button className={styles.closeBtn} onClick={handleCloseBtnClick}>
                <Icon.Line name="icon-times" />
              </button>
            </div>
            <div style={{ marginBottom: '20px' }}>
              <Paragraph size="xlarge">Filter Workouts</Paragraph>
              <Paragraph size="xsmall">
                You can select multiple filter options
              </Paragraph>
            </div>
            <div className={classNames(grid.row, styles.startTimeRow)}>
              <Paragraph weight="book" size="small">
                Start time
              </Paragraph>
              <Paragraph weight="book" size="xsmall">
                {startTimeLabelWhenApplied}
              </Paragraph>
            </div>
            <div className={styles.slider}>
              <Slider
                onChange={({ minValue, maxValue }) =>
                  handleOnSliderChangeValue(minValue, maxValue)
                }
                maxRangeValue={Math.round(TIME_MAX)}
                initialMinValue={initialMinValue}
                initialMaxValue={initialMaxValue}
                onClick={handleSliderInputOnClick}
              />
            </div>
            <Divider />
            <Paragraph size="xlarge">Style</Paragraph>
            <div style={{ marginBottom: '10px' }}></div>
            <TagFilter
              onFilterChange={handleStyleFilterChange}
              selectedTagId={styleFilterValue}
              styleFilterOptions={styleFilterOptions}
            />
            <Divider />
            <div className={styles.btnWorkouts}>
              <Button
                className={styles.btnWorkouts}
                onClick={handleFilterClick}
              >
                {isSliderFilterLoading ? 'Filtering...' : filterButtonName}
              </Button>
            </div>
          </div>
        </Card>
      </div>
    )
  },
)

export default FilterCard
