import { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { parseResponseByFilterType } from '../../../../../redux/features/OnDemand/Workouts'
import { useSessionStorage } from '../../../../hooks'
import { Trainer } from '../../../../networking/trainers'
import { workoutsApi } from '../../../../networking/workouts'
import {
  convertFilteredTimeToMinutes,
  getDateBasedOnDayOfWeek,
} from '../../../../utility/dates'
import { DayOfWeek } from '../../../../utility/datetimes'
import { NoResultsBannerProps } from './NoResultsBanner'

type AlternativeDaysType = {
  dayOfWeek: string
  totalElements: number
}

type UseNoResultsBannerProps = Pick<
  NoResultsBannerProps,
  'hasWorkoutResults' | 'activeDay' | 'dayOfWeekItems' | 'trainerId'
>

type FilterType = {
  initialMaxValue: number
  initialMinValue: number
  styleFilterValue: number
}

const hasNoFavoritesText = '"You haven\'t favorited any workouts yet."'

const getTotalElementsForDayNotActive = async (
  day: number,
  filterValues: FilterType,
  trainerId?: Trainer['id'],
) => {
  try {
    const dayToUTC = moment.utc(getDateBasedOnDayOfWeek(day)).local()
    const startTime = convertFilteredTimeToMinutes(
      dayToUTC,
      filterValues?.initialMinValue ? filterValues?.initialMinValue : 0,
      15,
    )
    const endTime = convertFilteredTimeToMinutes(
      dayToUTC,
      filterValues?.initialMaxValue ? filterValues?.initialMaxValue : 95.999,
      15,
    )
    const tags = filterValues?.styleFilterValue
      ? [filterValues?.styleFilterValue]
      : []
    const { fetchWorkouts } = workoutsApi()
    const response = await fetchWorkouts({
      startTimeFilter: startTime,
      endTimeFilter: endTime,
      favoriteFilter: false,
      hideCompletedFilter: true,
      tagFilters: tags,
      timeFilter: true,
      trainerId,
      size: 1,
    })

    const finalResponse = parseResponseByFilterType({
      response,
      isOnDemandFilter: false,
    })

    const totalElements =
      finalResponse.content.length === 0 ? 0 : finalResponse.totalElements

    return totalElements
  } catch (error) {
    console.error(error)
  }
}

const useNoResultsBanner = ({
  activeDay,
  hasWorkoutResults,
  dayOfWeekItems,
  trainerId,
}: UseNoResultsBannerProps) => {
  const [alternativeDays, setAlternativeDays] = useState<AlternativeDaysType[]>(
    [],
  )
  const hasNoResultsText = alternativeDays.length
    ? 'No workouts found for this day.'
    : 'No workouts found.'

  const [filterValues] = useSessionStorage('filterValues')

  const getWorkoutsForDaysThatAreNotActiveDayOfWeek = useCallback(
    async (
      activeDayOfWeek: number,
      filters: FilterType,
      trainer: Trainer['id'],
    ) => {
      try {
        const workoutDayArray: number[] = []
        const days = dayOfWeekItems
          .map((day) => day.id)
          .filter((day) => day !== activeDayOfWeek)

        const totalElementsForDaysNotActive = await Promise.allSettled(
          days.map((day) =>
            getTotalElementsForDayNotActive(day, filters, trainer),
          ),
        )

        for (const element of totalElementsForDaysNotActive) {
          if (element.status === 'fulfilled') {
            workoutDayArray.push(element.value!)
          }
        }

        const totalElementsStructureWithDayOfWeekObject = workoutDayArray.map(
          (totalElements, index) => ({
            dayOfWeek: DayOfWeek[days[index]],
            totalElements,
          }),
        )
        const removeZeroTotalElements =
          totalElementsStructureWithDayOfWeekObject.filter(
            (day) => day.totalElements !== 0,
          )

        setAlternativeDays(removeZeroTotalElements)
        return totalElementsStructureWithDayOfWeekObject
      } catch (error) {
        console.error(error)
      }
    },
    [dayOfWeekItems],
  )

  useEffect(() => {
    if (hasWorkoutResults) {
      getWorkoutsForDaysThatAreNotActiveDayOfWeek(
        activeDay,
        filterValues,
        trainerId!,
      )
    }
  }, [
    hasWorkoutResults,
    activeDay,
    filterValues,
    getWorkoutsForDaysThatAreNotActiveDayOfWeek,
    trainerId,
  ])

  return { alternativeDays, hasNoFavoritesText, hasNoResultsText }
}

export default useNoResultsBanner
