import {
  Select,
  SelectedRecord,
} from '@campgladiator/cgui-core.molecules.select'
import styles from './SearchField.module.scss'
import { useSearchField } from '.'

export type SearchFieldProps = {
  onItemSelect: (id: string) => void
  onClear: () => void
  placeholder: string
  required?: boolean
}

const SearchField = ({
  placeholder,
  onClear = () => {},
  onItemSelect,
}: SearchFieldProps) => {
  const {
    filteredPredictions,
    searchFieldRef,
    formatClickableMenuItem,
    handleOnCancelIconClick,
    handleOnChange,
  } = useSearchField({ onClear, onItemSelect })

  const onChangeSelectedRecords = (selectedRecords: SelectedRecord[]) => {
    if (selectedRecords[0]) {
      formatClickableMenuItem(selectedRecords[0])
    } else {
      handleOnCancelIconClick()
    }
  }

  return (
    <div ref={searchFieldRef} className={styles.searchField}>
      <Select
        className={styles.select}
        placeholder={placeholder}
        name="basicSelect"
        onInputChange={handleOnChange}
        searchRecords={filteredPredictions}
        onChangeSelectedRecords={onChangeSelectedRecords}
        maxChips={1}
      />
    </div>
  )
}

export default SearchField
