import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import { CheckInState } from '.'

export const initialState: CheckInState = {
  checkedInWorkout: [],
  isLoading: false,
  isCheckedIn: false,
  error: '',
}

export const virtualCheckInSlice = createSlice({
  name: 'checkIn',
  initialState,
  reducers: {
    checkInPending: (state: CheckInState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    checkInSuccess: (state: CheckInState, action: PayloadAction<number>) => {
      state.isCheckedIn = true
      state.error = initialState.error
      state.isLoading = false
      state.checkedInWorkout = [...state.checkedInWorkout, action.payload]
    },
    checkInFailure: (
      state: CheckInState,
      action: PayloadAction<CheckInState['error']>,
    ) => {
      state.error = action.payload
      state.isCheckedIn = false
      state.isLoading = false
      state.checkedInWorkout = initialState.checkedInWorkout
    },
    checkInReset: () => initialState,
    clearError: (state: CheckInState) => {
      state.error = initialState.error
    },
  },
  extraReducers: (builder) => {
    builder.addCase('auth/logoutSuccess', () => initialState)
  },
})

export const {
  checkInPending,
  checkInSuccess,
  checkInFailure,
  checkInReset,
  clearError,
} = virtualCheckInSlice.actions
export const virtualCheckInSelector = (state: RootState) => state.virtualCheckIn
export default virtualCheckInSlice.reducer
