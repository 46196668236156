import logger from './common/utility/logger'

declare global {
  interface Window {
    pendo: any
  }
}

export function setUserId(userId?: string) {
  if (!window.pendo) {
    logger.log('Pendo instance not found!')

    return
  }

  logger.log(`Setting userId on Pendo instance: ${userId}`)

  window.pendo.initialize({
    visitor: {
      id: userId,
    },
  })
}
