import { memo } from 'react'
import classNames from 'classnames'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Card } from '@campgladiator/cgui-core.atoms.card'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { LoyaltyBadge } from '@campgladiator/cgui-core.atoms.loyalty-badge'
import { ProgressBar } from '@campgladiator/cgui-core.atoms.progress-bar'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { addCommasToNumber } from '../../../../common/utility/strings'
import { CheckinConfirmationCardProps } from '../../OnDemandWorkoutsDetail.d'
import { CheckInBadge } from '../CheckInBadge'
import styles from './CheckinConfirmationCard.module.scss'

const progressInPctCappedAt100 = (current: number, goal: number): number =>
  Math.min((current / goal) * 100, 100)

const progressStatusAsFraction = (current: number, goal: number): string =>
  `${current}/${goal}`

const CheckinConfirmationCard = ({
  className,
  userName,
  stats,
  onCloseClick = () => {},
  ...props
}: CheckinConfirmationCardProps) => {
  const highFive = 'High Five'
  const heading = userName ? `${highFive}, ${userName}!` : `${highFive}!`

  const shouldCheckinProgressRender =
    typeof stats.checkins_count === 'number' &&
    !!stats.next_level_checkins_required
  const shouldReferralProgressRender =
    typeof stats.referrals_count === 'number' &&
    !!stats.next_level_referrals_required
  const isEliteLevel = !stats.next_level && stats.level === 'ELITE'
  const hasPoints = !!stats.points
  const hasLevel = !!stats.level
  const hasNextLevel = !!stats.next_level

  return (
    <Card className={classNames(styles.card, className)} raised {...props}>
      <div className={styles.upperPart}>
        <Icon.Line
          name="icon-times"
          className={styles.dismiss}
          onClick={onCloseClick}
        />
        <CheckInBadge checkins={stats.checkins_count} size="large" />
        <div className={styles.infoText}>
          <Paragraph size="xlarge" weight="bold">
            {heading}
          </Paragraph>
          <Paragraph className={styles.helperText} size="small" weight="book">
            You’re checked in
          </Paragraph>
        </div>
        <div
          className={classNames(styles.pointsAndBadge, {
            [styles.oneElementOnly]: !hasLevel,
          })}
        >
          <div className={styles.content}>
            <img
              className={styles.image}
              src={'https://cgcdn.s3.amazonaws.com/images/ondemand/coins.svg'}
              alt="CG Coins"
            />
            <Paragraph size="xlarge" weight="bold">
              {hasPoints ? addCommasToNumber(stats.points!) : 0}
            </Paragraph>
            <Paragraph className={styles.helperText} size="small" weight="book">
              Points
            </Paragraph>
          </div>

          {hasLevel && (
            <div className={styles.content}>
              <LoyaltyBadge
                className={classNames(styles.badge, styles.image)}
                type="badge-with-plate"
                variation={
                  stats.level!.toLowerCase() as
                    | 'bold'
                    | 'bronze'
                    | 'silver'
                    | 'gold'
                    | 'elite'
                }
              />
              <Paragraph
                className={styles.helperText}
                size="small"
                weight="book"
              >
                {stats.level!.toUpperCase()}
              </Paragraph>
            </div>
          )}
        </div>
        <div className={styles.progressWrapper}>
          {hasNextLevel && (
            <div className={styles.progress}>
              {shouldCheckinProgressRender && (
                <>
                  <Paragraph size="small" weight="bold">
                    To {stats.next_level!.toUpperCase()} Level
                  </Paragraph>
                  <ProgressBar
                    className={styles.checkInsProgress}
                    barFillPct={progressInPctCappedAt100(
                      stats.checkins_count!,
                      stats.next_level_checkins_required!,
                    )}
                    titleText={'Check-ins'}
                    progressText={progressStatusAsFraction(
                      stats.checkins_count!,
                      stats.next_level_checkins_required!,
                    )}
                    shape="rounded"
                  />
                </>
              )}
              {shouldReferralProgressRender && (
                <ProgressBar
                  className={styles.referralsProgress}
                  barFillPct={progressInPctCappedAt100(
                    stats.referrals_count!,
                    stats.next_level_referrals_required!,
                  )}
                  titleText={'Referrals'}
                  progressText={progressStatusAsFraction(
                    stats.referrals_count!,
                    stats.next_level_referrals_required!,
                  )}
                  progressColor="gladiator-light"
                  textPosition="bottom"
                  shape="rounded"
                />
              )}
            </div>
          )}
          {isEliteLevel && (
            <Paragraph
              className={styles.noNextLevelText}
              size="small"
              weight="book"
            >
              Round of applause for being the best of the best, CG Elite!
            </Paragraph>
          )}
        </div>
      </div>
      <footer className={styles.bottomPart}>
        <Paragraph className={styles.cta} size="xsmall">
          Go ahead, brag a little! You should be proud
        </Paragraph>
        <Button
          icon={{ type: 'line', name: 'icon-share' }}
          size="small"
          emphasis="negative"
          variation="text"
        >
          Share
        </Button>
      </footer>
    </Card>
  )
}

export default memo(CheckinConfirmationCard)
