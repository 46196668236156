import classNames from 'classnames'
import { GrowlMessage } from '@campgladiator/cgui-core.molecules.growl-message'
import * as grid from '@campgladiator/cgui-core.styles.helpers.grid'
import { DayOfWeek } from '../../common/components/DayOfWeek'
import { Filters } from '../../common/components/Filters'
import { PageTitle } from '../../common/components/PageTitle'
import { SearchField } from '../../common/components/SearchField'
import { WorkoutsGrid } from '../../common/components/WorkoutsGrid'
import { btnSizeBreakpoint } from '../../common/utility/resolutionVariables'
import { authSelector } from '../../redux/features/Auth'
import { Workout } from '../../redux/features/OnDemand/onDemandWorkouts'
import { useAppSelector } from '../../redux/store'
import { FavoritesFilter } from '../OnDemandWorkouts/components/FavoritesFilter'
import { Skeleton } from '../OnDemandWorkouts/components/Skeleton'
import { RejoinVirtualCard } from './components/RejoinVirtualCard'
import useVirtualWorkouts from './useVirtualWorkouts'
import styles from './VirtualWorkouts.module.scss'

const VirtualWorkouts = () => {
  const {
    isLoggedIn,
    token,
    user,
    isLoading: isLoadingFilters,
  } = useAppSelector(authSelector)
  const {
    allFavorites,
    checkedInWorkout,
    checkinError,
    currentDayOfWeekSelected,
    deviceWidth,
    filteredNumberOfWorkouts,
    filtersApplied,
    growlMessage,
    hasJoinedWorkout,
    isCheckinLoading,
    isFavoriteFilterApplied,
    isGrowlVisible,
    isHideCompletedFilterApplied,
    isLoadingWorkouts,
    isOverlayOn,
    isPageInitialized,
    isSliderFilterLoading,
    overlayTestId,
    pageTitle,
    selectedVirtualWorkout,
    tagFiltersApplied,
    totalNumberOfWorkouts,
    trainerId,
    useDayOfWeekProps,
    virtualWorkoutsPath,
    workouts,
    workoutsGridRef,
    handleOnFavoriteClick,
    handleOnGetFavoritesWorkouts,
    handleOnJoinLiveClick,
    handleOnLoginClick,
    isLoadingFavorite,
    onApplyFilterByTimeClick,
    onCheckInClick,
    onClearFilterByTimeClick,
    onClearTrainerSearch,
    onFilterByTimeSlide,
    onGrowlClose,
    setIsOverlayOn,
    setTrainerId,
  } = useVirtualWorkouts()

  const shouldShowRejoinComponent =
    isLoggedIn && user.hasOnlineAccess && hasJoinedWorkout
  const isFiltered =
    tagFiltersApplied.length > 0 ||
    isHideCompletedFilterApplied ||
    filtersApplied

  const { dayOfWeekItems, onDayOfWeekClick } = useDayOfWeekProps

  return (
    <>
      {!isPageInitialized ? (
        <Skeleton />
      ) : (
        <>
          <div className={classNames(grid.container, styles.content)}>
            <PageTitle title={pageTitle}>
              Access thousands of live workouts led by Certified Personal
              Trainers.
            </PageTitle>
            {shouldShowRejoinComponent && (
              <div className={grid.row}>
                <div
                  className={classNames(
                    styles.rejoinColumn,
                    grid.col12,
                    grid.colMd6,
                    grid.colLg4,
                  )}
                >
                  <RejoinVirtualCard
                    hasJoinedWorkout={hasJoinedWorkout}
                    isLoadingFavorite={isLoadingFavorite(
                      selectedVirtualWorkout.id!,
                    )}
                    isLoggedIn={isLoggedIn}
                    onFavoriteClick={(workoutId: number, isFavorite: boolean) =>
                      handleOnFavoriteClick(workoutId, isFavorite)
                    }
                    onJoinLiveClick={handleOnJoinLiveClick}
                    selectedVirtualWorkout={selectedVirtualWorkout as Workout}
                    user={user}
                    onCheckInClick={onCheckInClick}
                    isCheckinLoading={isCheckinLoading}
                    checkedInWorkout={checkedInWorkout!}
                    token={token}
                  />
                </div>
              </div>
            )}
            <div className={classNames(grid.row, styles.filters)}>
              <div className={classNames(grid.col12, grid.colMd5, grid.colLg5)}>
                <SearchField
                  placeholder="Search trainer"
                  onItemSelect={setTrainerId}
                  onClear={onClearTrainerSearch}
                />
              </div>
              <div
                className={classNames(grid.col12, grid.colMd7, styles.controls)}
              >
                {isLoggedIn && (
                  <FavoritesFilter
                    deviceWidth={deviceWidth}
                    isLoadingWorkouts={isLoadingWorkouts}
                    hasFavoriteFilter={isFavoriteFilterApplied}
                    onGetFavoriteWorkouts={handleOnGetFavoritesWorkouts}
                    className={styles.favoriteFilter}
                  />
                )}
                <Filters
                  isFavoriteFilterApplied={isFavoriteFilterApplied}
                  btnSizeBreakpoint={btnSizeBreakpoint}
                  deviceWidth={deviceWidth}
                  isLoadingFilters={isLoadingFilters}
                  isLoadingWorkouts={isLoadingWorkouts}
                  onOverlayIsSet={setIsOverlayOn}
                  filtersApplied={filtersApplied}
                  onApplyFilterByTimeClick={onApplyFilterByTimeClick}
                  onClearFilterByTimeClick={onClearFilterByTimeClick}
                  onFilterByTimeSlide={onFilterByTimeSlide}
                  filteredNumberOfWorkouts={filteredNumberOfWorkouts}
                  isSliderFilterLoading={isSliderFilterLoading}
                  activeDayOfWeek={currentDayOfWeekSelected}
                  virtualWorkoutsPath={virtualWorkoutsPath}
                />
              </div>
            </div>

            <DayOfWeek {...useDayOfWeekProps} />
            <WorkoutsGrid
              activeDayOfWeek={currentDayOfWeekSelected}
              ref={workoutsGridRef}
              isFiltered={isFiltered}
              isLoading={isLoadingWorkouts}
              allFavorites={allFavorites}
              isLoggedIn={isLoggedIn}
              onFavoriteClick={(workoutId, isFavorite) =>
                handleOnFavoriteClick(workoutId, isFavorite)
              }
              onJoinLiveClick={handleOnJoinLiveClick}
              onLoginClick={handleOnLoginClick}
              isFavoriteFilterApplied={isFavoriteFilterApplied}
              totalNumberOfWorkouts={totalNumberOfWorkouts}
              type="ONLINE"
              workouts={workouts}
              token={token}
              user={user}
              onCheckInClick={onCheckInClick}
              isCheckinLoading={isCheckinLoading}
              checkedInWorkout={checkedInWorkout!}
              onDayOfWeekClick={onDayOfWeekClick}
              dayOfWeekItems={dayOfWeekItems}
              trainerId={trainerId}
            />
          </div>
          {isOverlayOn && (
            <div className={styles.overlay} {...overlayTestId}></div>
          )}
          {checkinError && (
            <GrowlMessage
              autoClose={true}
              isVisible={isGrowlVisible}
              onClick={onGrowlClose}
            >
              {growlMessage}
            </GrowlMessage>
          )}
        </>
      )}
    </>
  )
}

export default VirtualWorkouts
