import { memo } from 'react'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import { Favorite } from '../../../../common/components/Favorite'
import { WorkoutHeaderProps } from '../../OnDemandWorkoutsDetail.d'
import styles from './WorkoutHeader.module.scss'

const WorkoutHeader = ({
  isLoadingFavorite,
  isLoggedIn,
  title,
  isFavorite,
  onFavoriteClick,
}: WorkoutHeaderProps) => (
  <div className={styles.workoutHeader}>
    <Heading className={styles.title} type="h4">
      {title}
    </Heading>
    {isLoggedIn && (
      <Favorite
        className={styles.btn}
        isFavorite={isFavorite}
        isLoadingFavorite={isLoadingFavorite}
        onClick={onFavoriteClick}
      />
    )}
  </div>
)

export default memo(WorkoutHeader)
