import { Button } from '@campgladiator/cgui-core.atoms.button'
import { btnSizeBreakpoint } from '../../../../common/utility/resolutionVariables'
import { FilterProps } from '../../OnDemandWorkouts.d'
import { FavoritesFilter } from '../FavoritesFilter'
import { FilterList } from '../FilterList'
import styles from './Filters.module.scss'

const Filters = ({ ...props }: FilterProps) => {
  return (
    <div className={styles.content}>
      {props.isLoggedIn && (
        <FavoritesFilter
          className={styles.favoriteBtn}
          onOverlayIsSet={props.onOverlayIsSet}
          hasActiveFilters={props.filtersApplied}
          deviceWidth={props.deviceWidth}
          isLoadingWorkouts={props.isLoadingWorkouts}
          hasFavoriteFilter={props.isFavoriteFilterApplied}
          onGetFavoriteWorkouts={props.onGetFavoriteWorkouts}
        />
      )}
      <Button
        className={props.filtersApplied ? styles.activeBtn : ''}
        emphasis={props.filtersApplied ? 'primary' : 'secondary-alt'}
        variation="outline"
        size={props.deviceWidth >= btnSizeBreakpoint ? 'default' : 'small'}
        icon={{ type: 'line', name: 'icon-sliders-v-alt' }}
        onClick={(e) => props.handleFiltersBtnClick(e)}
        disabled={props.isLoadingFilters || props.isLoadingWorkouts}
      >
        Filters {props.filtersApplied && ` • ${props.countAllFiltersApplied()}`}
      </Button>
      {props.filtersApplied && (
        <Button
          className={styles.clearBtn}
          size={props.deviceWidth >= btnSizeBreakpoint ? 'default' : 'small'}
          emphasis="secondary"
          variation="text"
          tabIndex={props.filtersVisible ? -1 : 0}
          onClick={props.handleClearBtnClick}
          disabled={props.isLoadingFilters || props.isLoadingWorkouts}
        >
          Clear
        </Button>
      )}
      {props.filtersVisible && (
        <FilterList
          ref={props.filterListRef}
          className={styles.filters}
          tagFiltersApplied={props.tagFiltersApplied}
          filters={props.filters}
          setVisible={props.showFiltersListAndOverlay}
          deviceWidth={props.deviceWidth}
          onGetFilteredWorkouts={props.onGetFilteredWorkouts}
          isHideCompletedFilterApplied={props.isHideCompletedFilterApplied}
          isLoggedIn={props.isLoggedIn}
        />
      )}
    </div>
  )
}

export default Filters
