import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { RootState } from '../../../store'
import { Tag } from '../onDemandWorkouts'
import { FiltersState } from './filters'

const initialStateStartTimeFilter = moment.utc()
initialStateStartTimeFilter.set({
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0,
})

const initialStateEndTimeFilter = moment.utc()
initialStateEndTimeFilter.set({
  hour: 23,
  minute: 59,
  second: 59,
  millisecond: 59,
})

export const initialState: FiltersState = {
  error: '',
  isLoading: false,
  tagFilters: [],
  favoriteFilter: false,
  hideCompletedFilter: false,
  filters: [] as Tag[],
  timeFilter: false,
  timesFilter: {
    startTimeFilter: initialStateStartTimeFilter.format(),
    endTimeFilter: initialStateEndTimeFilter.format(),
  },
}

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    getFiltersPending: (
      state: FiltersState,
      action: PayloadAction<boolean>,
    ) => {
      state.isLoading = action.payload
      state.filters = initialState.filters
    },
    getFiltersSuccess: (state: FiltersState, action: PayloadAction<Tag[]>) => {
      state.filters = action.payload
      state.error = initialState.error
      state.isLoading = initialState.isLoading
    },
    getFiltersFailure: (
      state: FiltersState,
      action: PayloadAction<FiltersState['error']>,
    ) => {
      state.error = action.payload
      state.filters = initialState.filters
    },
    setActiveFilters: (
      state: FiltersState,
      action: PayloadAction<{ tags: number[]; hideCompleted: boolean }>,
    ) => {
      state.tagFilters = action.payload.tags
      state.hideCompletedFilter = action.payload.hideCompleted
    },
    setTimeFilter: (
      state: FiltersState,
      action: PayloadAction<{ startTime: string; endTime: string }>,
    ) => {
      state.timesFilter.startTimeFilter = action.payload.startTime
      state.timesFilter.endTimeFilter = action.payload.endTime
      state.timeFilter = true
    },
    setFavoriteFilter: (
      state: FiltersState,
      action: PayloadAction<boolean>,
    ) => {
      state.favoriteFilter = action.payload
    },
    filtersReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase('auth/logoutSuccess', (state: FiltersState) => {
      state.favoriteFilter = false
      state.hideCompletedFilter = false
    })
  },
})

export const {
  getFiltersPending,
  getFiltersSuccess,
  getFiltersFailure,
  setActiveFilters,
  setFavoriteFilter,
  setTimeFilter,
  filtersReset,
} = filtersSlice.actions

export const filtersSelector = (state: RootState) =>
  state.filters as FiltersState

export default filtersSlice.reducer
