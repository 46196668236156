import { forwardRef, useRef, useState } from 'react'
import classNames from 'classnames'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Card } from '@campgladiator/cgui-core.atoms.card'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Toggle } from '@campgladiator/cgui-core.atoms.toggle'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import {
  analytics,
  analyticsEvent,
  analyticsEventLabel,
} from '../../../../common/utility/analytics'
import { testIdAttribute, testIds } from '../../../../common/utility/labels'
import { Tag } from '../../../../redux/features/OnDemand/onDemandWorkouts'
import { ClearButtonProps, FilterListProps } from '../../OnDemandWorkouts.d'
import { FilterGroup } from '../FilterGroup'
import styles from './FilterList.module.scss'

const mobileBreakpoint = 576
const ClearButton = ({
  size = 'default',
  disabled,
  handleClick,
}: ClearButtonProps) => (
  <Button
    size={size}
    disabled={disabled}
    variation="text"
    style={{ padding: '0' }}
    onClick={handleClick}
  >
    unselect all
  </Button>
)

const FilterList = forwardRef<HTMLElement, FilterListProps>(
  (
    {
      className = '',
      tagFiltersApplied,
      filters,
      setVisible,
      deviceWidth,
      onGetFilteredWorkouts,
      isLoggedIn,
      isHideCompletedFilterApplied,
    }: FilterListProps,
    ref,
  ) => {
    const isMobile = deviceWidth < mobileBreakpoint
    const groupedFilters: { [key: string]: Tag[] } = filters.reduce(
      (obj, tag) => {
        obj[tag.tagType] = obj[tag.tagType] || []
        obj[tag.tagType].push(tag)
        return obj
      },
      Object.create(null),
    )

    const [selectedFilters, setSelectedFilters] = useState<Set<number>>(
      new Set(tagFiltersApplied),
    )

    const [hideCompletedFilter, setHideCompletedFilter] = useState<boolean>(
      isHideCompletedFilterApplied,
    )

    const { trackCustomEvent } = analytics
    const { BUTTON_CLICK, CLOSE, TOGGLE } = analyticsEvent
    const {
      WORKOUTS: {
        SHOW_WORKOUTS,
        UNSELECT_ALL,
        ON_DEMAND_FILTERS,
        HIDE_COMPLETED_WORKOUTS,
      },
    } = analyticsEventLabel

    const handleOnFilterBtnClick = (id: number, tagType: string) => {
      const newState = new Set(selectedFilters)
      const deselectSameTagTypeFilter =
        selectedFilters.size > 0 && groupedFilters[tagType].length > 1

      if (selectedFilters.has(id)) {
        newState.delete(id)
      } else {
        deselectSameTagTypeFilter &&
          groupedFilters[tagType].some(
            ({ id }) => selectedFilters.has(id) && newState.delete(id),
          )
        newState.add(id)
      }
      setSelectedFilters(newState)
    }

    const handleCloseBtnClick = () => {
      setVisible(false)
      trackCustomEvent(CLOSE(ON_DEMAND_FILTERS))
    }

    const toggleRef = useRef<HTMLInputElement>(null)

    const handleClearBtnClick = () => {
      setSelectedFilters(new Set())
      hideCompletedFilter && toggleRef?.current?.click()
      trackCustomEvent(BUTTON_CLICK(UNSELECT_ALL))
    }

    const handleShowWorkoutsBtnClick = () => {
      onGetFilteredWorkouts(Array.from(selectedFilters), hideCompletedFilter)
      setVisible(false)
      trackCustomEvent(BUTTON_CLICK(SHOW_WORKOUTS))
    }

    const handleToggleClick = () => {
      setHideCompletedFilter(!hideCompletedFilter)
      trackCustomEvent(TOGGLE(HIDE_COMPLETED_WORKOUTS))
    }

    const shouldDisableClearBtn = !selectedFilters.size && !hideCompletedFilter

    const clearButton = (
      <ClearButton
        disabled={shouldDisableClearBtn}
        handleClick={handleClearBtnClick}
        size={isMobile ? 'small' : 'default'}
      />
    )

    const toggleTestId = testIdAttribute(testIds.WORKOUTS.HIDE_COMPLETED_TOGGLE)

    return (
      <Card
        raised
        className={classNames(styles.filtersCard, className)}
        ref={ref}
      >
        <div className={styles.upperPart}>
          <div className={styles.header}>
            {isMobile && clearButton}
            <button className={styles.closeBtn} onClick={handleCloseBtnClick}>
              <Icon.Line name="icon-times" />
            </button>
          </div>
          <Heading type="h2">Filter workouts</Heading>
          <Paragraph size={isMobile ? 'xsmall' : 'small'} weight="book">
            You can select multiple filter options
          </Paragraph>
          {Object.keys(groupedFilters).map((type) => (
            <FilterGroup
              key={type}
              btnSize={isMobile ? 'xsmall' : 'small'}
              name={type}
              filters={groupedFilters[type]}
              selectedFilters={selectedFilters}
              onFilterBtnClick={handleOnFilterBtnClick}
            />
          ))}
          {isLoggedIn && (
            <div className={styles.hideCompletedWorkouts}>
              <Paragraph>Hide Completed Workouts</Paragraph>
              <Toggle
                ref={toggleRef}
                className={styles.toggle}
                {...toggleTestId}
                toggleSize={isMobile ? 'xsmall' : 'small'}
                onClick={handleToggleClick}
                defaultChecked={isHideCompletedFilterApplied}
              />
            </div>
          )}
        </div>
        <hr className={styles.divider} />
        <div className={styles.bottomPart}>
          {!isMobile && clearButton}
          <Button
            className={styles.showWorkoutsBtn}
            onClick={handleShowWorkoutsBtnClick}
          >
            Show Workouts
          </Button>
        </div>
      </Card>
    )
  },
)

export default FilterList
