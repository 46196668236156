import { authApi } from './auth'

const useAppAccess = () => {
  const {
    fetchAuthorizationByToken,
    fetchKeanuUserById,
    loginUser,
    logoutExpireToken,
    verifySession,
    fetchUserSubscriptionsSummaries,
  } = authApi()

  return {
    fetchAuthorizationByToken,
    fetchKeanuUserById,
    loginUser,
    logoutExpireToken,
    verifySession,
    fetchUserSubscriptionsSummaries,
  }
}

export default useAppAccess
