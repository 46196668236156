import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { HeaderProps as HeaderPropsCore } from '@campgladiator/cgui-core.organisms.header'
import configuration from '../../../configuration'
import { login, logout, refreshToken } from '../../../redux/features/Auth'
import { AuthToken, KeanuUser } from '../../../redux/features/Auth/auth'
import { selectedVirtualWorkoutReset } from '../../../redux/features/Virtual/SelectedWorkout'
import { useAppDispatch } from '../../../redux/store'
import useAppAccess from '../../networking/useAppAccess'
import {
  addAuthCookieListener,
  addAuthCookieRefreshTokenListener,
  getAuthToken,
} from '../../utility/authTokenStorage'

type HeaderProps = {
  user?: KeanuUser
}

type HeaderPaths = {
  readonly [key: string]: { path: string; isActive: boolean }
}

const { cgDomain, cgWebsiteUrl } = configuration

const useHeader = ({ user }: HeaderProps) => {
  const [authToken, setAuthToken] = useState<AuthToken>()

  const userImage = user?.imageURL || undefined
  const userName =
    user?.firstName && user?.lastName
      ? `${user?.firstName} ${user?.lastName}`
      : undefined
  const loginUrl = `${configuration.cgWebsiteLoginUrl}?redirectUrl=${window.location.href}`
  const { logoutExpireToken } = useAppAccess()
  const dispatch = useAppDispatch()

  const { pathname } = useLocation()
  const currentPagePath = pathname

  const onDemandWorkoutsPath: string = '/'
  const virtualWorkoutsPath: string = '/virtual-workouts'
  const onDemandWorkoutsDetailPattern: RegExp = /\/workout\/\d+$/

  const paths: HeaderPaths = {
    onDemandWorkouts: {
      path: onDemandWorkoutsPath,
      isActive: currentPagePath === onDemandWorkoutsPath,
    },
    virtualWorkouts: {
      path: virtualWorkoutsPath,
      isActive: currentPagePath === virtualWorkoutsPath,
    },
  }

  const headerLinks = [
    {
      link: `${configuration.cgWebsiteUrl}/pricing/`,
      label: 'Pricing',
      isActiveRoute: false,
    },
    {
      link: paths.onDemandWorkouts.path,
      label: 'On-Demand Workouts',
      isActiveRoute: paths.onDemandWorkouts.isActive,
    },
    {
      link: paths.virtualWorkouts.path,
      label: 'Live Workouts',
      isActiveRoute: paths.virtualWorkouts.isActive,
    },
    {
      link: `https://gear.${cgDomain}`,
      label: 'Shop Gear',
      isActiveRoute: false,
    },
  ]

  const userButtonPropsWithAvatar: HeaderPropsCore['userButton'] = {
    items: [
      {
        type: 'item',
        text: 'Billing',
        itemId: '1',
        link: { url: `${cgWebsiteUrl}/profile/billing`, newTab: false },
      },
    ],
    avatar: {
      alt: userName || '',
      src: userImage,
    },
    userName,
  }

  const handleLogout = async () => {
    try {
      if (authToken) await logoutExpireToken(authToken)
    } catch (error: any) {
      throw Error(error)
    } finally {
      dispatch(logout())
      dispatch(selectedVirtualWorkoutReset())
    }
  }

  useEffect(() => {
    const dispatchLoginWithToken = (token: AuthToken) =>
      dispatch(login({ token }))

    const dispatchLoginRefreshToken = (token?: AuthToken) =>
      dispatch(refreshToken(token))

    const token = getAuthToken()
    setAuthToken(token)

    if (token) dispatchLoginWithToken(token)

    addAuthCookieListener(
      (thisToken: AuthToken) => {
        if (onDemandWorkoutsDetailPattern.test(window.location.href)) {
          window.addEventListener('beforeunload', () => {
            dispatchLoginWithToken(thisToken)
          })
        } else {
          dispatchLoginWithToken(thisToken)
        }
      },
      () => {
        if (onDemandWorkoutsDetailPattern.test(window.location.href)) {
          window.addEventListener('beforeunload', () => {
            dispatch(logout())
            dispatch(selectedVirtualWorkoutReset())
          })
        } else {
          dispatch(logout())
          dispatch(selectedVirtualWorkoutReset())
        }
      },
    )

    addAuthCookieRefreshTokenListener(dispatchLoginRefreshToken)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return {
    headerLinks,
    loginUrl,
    userButtonPropsWithAvatar,
    handleLogout,
  }
}

export { useHeader }
