import { forwardRef, memo } from 'react'
import classNames from 'classnames'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import * as grid from '@campgladiator/cgui-core.styles.helpers.grid'
import { SkeletonWorkouts } from '../../../pages/OnDemandWorkouts/components/Skeleton'
import { WorkoutsGridProps } from '../../../pages/OnDemandWorkouts/OnDemandWorkouts.d'
import { WorkoutCard } from '../WorkoutCard'
import { NoResultsBanner } from './components/NoResultsBanner'
import styles from './WorkoutsGrid.module.scss'

const WorkoutsGrid = forwardRef<HTMLDivElement, WorkoutsGridProps>(
  (
    {
      activeDayOfWeek,
      allFavorites,
      checkedInWorkout,
      dayOfWeekItems,
      isCheckinLoading,
      isFavoriteFilterApplied,
      isFiltered,
      isLoading,
      isLoggedIn,
      token,
      totalNumberOfWorkouts,
      trainerId,
      type,
      user,
      workouts,
      onCheckInClick,
      onDayOfWeekClick,
      onFavoriteClick,
      onJoinLiveClick = () => {},
      onLoginClick,
    }: WorkoutsGridProps,
    ref,
  ) => {
    const isLoadedAndFiltered =
      !isLoading && (isFiltered || isFavoriteFilterApplied || trainerId)
    const isLoadedAndWorkoutsEmpty = !isLoading && workouts.length === 0
    const noWorkoutsMatchFilters = isLoadedAndFiltered && workouts.length === 0
    const isResultsTextVisible = isLoadedAndFiltered && workouts.length > 0
    const hasWorkoutResults = noWorkoutsMatchFilters
    const hasWorkouts = hasWorkoutResults || isLoadedAndWorkoutsEmpty

    const headingText = isFavoriteFilterApplied
      ? workouts.length >= 1
        ? `${totalNumberOfWorkouts} Favorited Workouts`
        : 'Favorited Workouts'
      : 'Showing results based on your filters'

    const isLoadingFavorite = (workoutId: number) =>
      (allFavorites &&
        allFavorites[workoutId] &&
        allFavorites[workoutId].isLoading) ||
      false

    return (
      <div ref={ref}>
        {isResultsTextVisible && (
          <Heading type="h5" className={styles.heading}>
            {headingText}
          </Heading>
        )}
        {hasWorkouts ? (
          <NoResultsBanner
            showNoFavoriteMsg={isFavoriteFilterApplied}
            activeDay={activeDayOfWeek!}
            hasWorkoutResults={!!hasWorkoutResults}
            onDayOfWeekClick={onDayOfWeekClick}
            dayOfWeekItems={dayOfWeekItems!}
            trainerId={trainerId}
          />
        ) : (
          <div
            className={classNames(grid.row, styles.workouts, {
              [styles.filtered]: isFiltered,
            })}
          >
            {workouts.map((workout) => (
              <div
                className={classNames(grid.col12, grid.colMd6, grid.colLg4)}
                key={workout.id}
              >
                {type === 'ONDEMAND' ? (
                  <WorkoutCard.Ondemand
                    workout={workout}
                    isLoggedIn={isLoggedIn}
                    isLoadingFavorite={isLoadingFavorite(workout.id)}
                    onFavoriteClick={(workoutId, isFavorite) =>
                      onFavoriteClick(workoutId, isFavorite)
                    }
                  />
                ) : type === 'ONLINE' ? (
                  <WorkoutCard.Virtual
                    workout={workout}
                    isLoggedIn={isLoggedIn}
                    isLoadingFavorite={isLoadingFavorite(workout.id)}
                    onFavoriteClick={(workoutId, isFavorite) =>
                      onFavoriteClick(workoutId, isFavorite)
                    }
                    onJoinLiveClick={onJoinLiveClick}
                    onLoginClick={onLoginClick}
                    token={token}
                    type={type}
                    user={user}
                    onCheckInClick={onCheckInClick}
                    isCheckinLoading={isCheckinLoading}
                    checkedInWorkout={checkedInWorkout}
                  />
                ) : null}
              </div>
            ))}
            {isLoading && <SkeletonWorkouts />}
          </div>
        )}
      </div>
    )
  },
)

export default memo(WorkoutsGrid)
