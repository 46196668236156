import moment, { Moment } from 'moment'

export enum DayOfWeek {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export enum AbbreviatedDayOfWeek {
  Sun,
  Mon,
  Tue,
  Wed,
  Thu,
  Fri,
  Sat,
}

export type Time = {
  hour: number
  minute: number
}

export type TimeUnits = 'day' | 'hour' | 'minute'

export const formatMeridianByDate = (date: Date) => {
  return moment.utc(date).format('hh:mm A')
}
export const formatMeridianWithLocalTZ = (date: Date) =>
  moment.utc(date).local().format('hh:mm A')

const formatMeridianTime = (hours: number) => (hours >= 12 ? 'PM' : 'AM')

export const getDayOfWeek = (date: Date) => DayOfWeek[date.getDay()]

export const formatMeridianByMinutes = (num: number): string => {
  const hours = getHoursFromMinutes(num)
  const minutes = Math.floor(num % 60)
  const meridian = formatMeridianTime(hours)
  const displayHours = hours % 12 || 12
  const displayMinutes = minutes < 10 ? `0${minutes}` : minutes
  return `${displayHours}:${displayMinutes} ${meridian}`
}

export const getNextDateByDayOfWeekWithLocalTZ = (weekdayNumber: number) => {
  const now = moment.utc().local()
  if (now.isoWeekday() === weekdayNumber) return now
  let daysUntil = (weekdayNumber + 7 - now.isoWeekday()) % 7
  if (daysUntil === 0) daysUntil = 7
  const nextDate = moment.utc().local().add(daysUntil, 'days')
  return nextDate
}

const getHoursFromMinutes = (minutes: number) => Math.floor(minutes / 60)

export const formatTimeByMinutes = (num: number): string => {
  const hours = Math.floor(getHoursFromMinutes(num))
  const minutes = Math.floor(num % 60)
  const displayHours = hours < 10 ? `0${hours}` : hours
  const displayMinutes = minutes < 10 ? `0${minutes}` : minutes
  return `${displayHours}:${displayMinutes}`
}

export const getHourAndMinuteFromTimeString = (time: string) => {
  const timeSplit = time.split(':')
  const hour = parseInt(timeSplit[0] ?? 0)
  const minutes = parseInt(timeSplit[1] ?? 0)
  return { hour: hour, minute: minutes } as Time
}

export const formatDateTimeByDateAndMinutes = (
  date: Moment,
  min: number,
): string => {
  const time = formatTimeByMinutes(min)
  const timeSplit = getHourAndMinuteFromTimeString(time)
  const hour = timeSplit.hour
  const minutes = timeSplit.minute
  return moment({
    year: date.year(),
    month: date.month(),
    day: date.date(),
    hour: hour,
    minute: minutes,
  }).format()
}

export const timeTextPluralOrSingular = ({
  timeUntilWorkout,
  unit,
}: {
  timeUntilWorkout: number
  unit: TimeUnits
}) => (timeUntilWorkout === 1 ? unit : `${unit}s`)

const getDaysUntilWorkout = ({
  now,
  startTime,
  hours,
}: {
  now: moment.Moment
  startTime: moment.Moment
  hours: number
}) => {
  const currentDayOfWeek = now.day()
  const workoutDayOfWeek = startTime.day()
  const daysDifference = workoutDayOfWeek - currentDayOfWeek
  const parsedDaysDifference =
    daysDifference === 1 && hours < 24 ? daysDifference - 1 : daysDifference
  const daysUntilWorkout =
    workoutDayOfWeek >= currentDayOfWeek
      ? parsedDaysDifference
      : parsedDaysDifference + 7

  return daysUntilWorkout
}

export const getTimeBreakdowns = (workoutStartDate: Date) => {
  const now = moment(new Date())
  const startTime = moment(workoutStartDate)
  const minutes = startTime.diff(now, 'minutes')
  const hours = startTime.diff(now, 'hours')
  const days = getDaysUntilWorkout({ now, startTime, hours })

  return { days, hours, minutes }
}

export const getHhMmFromUtcDate = (dateTime: string) => {
  const date = new Date(dateTime)
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const hoursString = hours < 10 ? `0${hours}` : `${hours}`
  const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`
  return `${hoursString}:${minutesString}`
}
