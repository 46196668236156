import classNames from 'classnames'
import * as grid from '@campgladiator/cgui-core.styles.helpers.grid'
import { PageTitle } from '../../common/components/PageTitle'
import { SearchField } from '../../common/components/SearchField'
import { WorkoutsGrid } from '../../common/components/WorkoutsGrid'
import { Filters } from './components/Filters'
import { Skeleton } from './components/Skeleton'
import styles from './OnDemandWorkouts.module.scss'
import useOnDemandWorkouts from './useOnDemandWorkouts'

const OnDemandWorkouts = () => {
  const { ...props } = useOnDemandWorkouts()

  return (
    <>
      {!props.isPageInitialized ? (
        <Skeleton />
      ) : (
        <>
          <div className={classNames(grid.container, styles.content)}>
            <PageTitle title="On-Demand Workouts">
              Workouts at your convenience led by our top certified personal
              trainers.
            </PageTitle>
            <div className={classNames(grid.row, styles.filters)}>
              <div className={classNames(grid.col12, grid.colMd5, grid.colLg5)}>
                <SearchField
                  placeholder="Search trainer"
                  onItemSelect={props.setTrainerId}
                  onClear={props.onClearTrainerSearch}
                />
              </div>
              <div
                className={classNames(grid.col12, grid.colMd7, styles.controls)}
              >
                <Filters {...props.filterProps} />
              </div>
            </div>
            <WorkoutsGrid
              ref={props.workoutsGridRef}
              isFiltered={
                props.tagFiltersApplied.length > 0 ||
                props.isHideCompletedFilterApplied
              }
              isLoading={props.isLoadingWorkouts}
              allFavorites={props.allFavorites}
              isLoggedIn={props.isLoggedIn}
              onFavoriteClick={(workoutId, isFavorite) =>
                props.handleOnFavoriteClick(workoutId, isFavorite)
              }
              isFavoriteFilterApplied={props.isFavoriteFilterApplied}
              totalNumberOfWorkouts={props.totalNumberOfWorkouts}
              type="ONDEMAND"
              workouts={props.workouts}
            />
          </div>
          {props.isOverlayOn && (
            <div className={styles.overlay} {...props.overlayTestId}></div>
          )}
        </>
      )}
    </>
  )
}

export default OnDemandWorkouts
