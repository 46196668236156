import { memo } from 'react'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import { WorkoutVideoOverlayProps } from '../../OnDemandWorkoutsDetail.d'
import { CheckinConfirmationCard } from '../CheckinConfirmationCard'
import styles from './WorkoutVideoOverlay.module.scss'

const WorkoutVideoOverlay = ({
  userName,
  stats,
  showConfirmationCard,
  onConfirmationCardClose = () => {},
}: WorkoutVideoOverlayProps) => {
  return (
    <>
      <div className={styles.overlay}>
        <Heading type="h3">Great Job!</Heading>
        <Heading type="h4">We hope you enjoyed this workout!</Heading>
      </div>
      {showConfirmationCard && (
        <CheckinConfirmationCard
          className={styles.confirmationCard}
          userName={userName}
          stats={stats!}
          onCloseClick={onConfirmationCardClose}
        />
      )}
    </>
  )
}

export default memo(WorkoutVideoOverlay)
