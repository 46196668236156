import moment from 'moment'
import {
  WorkoutApiParams,
  workoutsApi,
} from '../../../../common/networking/workouts'
import configuration from '../../../../configuration'
import { AppThunk, RootState } from '../../../store'
import { Workout } from '../onDemandWorkouts'
import {
  WorkoutsJsonSuccessResponse,
  getWorkoutsFailure,
  getWorkoutsPending,
  getWorkoutsSuccess,
} from '.'

const { fetchWorkouts } = workoutsApi()

const { workoutAnticipationMinutes } = configuration

export const getWorkouts =
  ({
    isOnDemandFilter = true,
    dayOfWeekFilter,
    trainerId,
  }: {
    isOnDemandFilter?: boolean
    dayOfWeekFilter?: number
    trainerId?: string
  }): AppThunk =>
  async (dispatch, getState) => {
    const { auth, workouts, filters } = getState() as RootState
    const authToken = auth?.token
    const page =
      typeof workouts.lastPageFetched === 'undefined'
        ? 0
        : workouts.lastPageFetched + 1
    dispatch(getWorkoutsPending(true))
    try {
      const {
        tagFilters,
        favoriteFilter,
        hideCompletedFilter,
        timesFilter: { startTimeFilter, endTimeFilter },
        timeFilter,
      } = filters

      const response = await fetchWorkouts({
        tagFilters,
        favoriteFilter,
        hideCompletedFilter,
        startTimeFilter,
        endTimeFilter,
        authToken,
        page,
        isOnDemandFilter,
        dayOfWeekFilter,
        timeFilter,
        trainerId,
      } as WorkoutApiParams)

      const finalResponse = parseResponseByFilterType({
        response,
        isOnDemandFilter,
      })
      dispatch(getWorkoutsSuccess(finalResponse))
      const filteredWorkoutsHaveExpired =
        finalResponse.allWorkoutsAreOver &&
        moment.utc(filters.timesFilter.endTimeFilter).isAfter(moment.utc())
      if (filteredWorkoutsHaveExpired && finalResponse.totalPages > page) {
        dispatch(getWorkouts({ isOnDemandFilter, dayOfWeekFilter, trainerId }))
      }
    } catch (error: any) {
      dispatch(getWorkoutsFailure(error.toString()))
    }
  }

const updateStartEndTimes = (workouts: Workout[]): Workout[] =>
  workouts.map((workout) => {
    const dateStartLive = moment
      .utc(workout.startTime)
      .subtract(workoutAnticipationMinutes, 'minutes')
    const dateEndLive = moment
      .utc(workout.startTime)
      .add(workout.duration, 'minutes')
    const usersCurrentTime = moment.utc()
    workout.isLive = usersCurrentTime.isBetween(dateStartLive, dateEndLive)
    workout.isOver = usersCurrentTime.isAfter(dateEndLive)

    return workout
  })

export const parseResponseByFilterType = ({
  response,
  isOnDemandFilter,
}: {
  response: WorkoutsJsonSuccessResponse
  isOnDemandFilter: boolean
}) => {
  if (isOnDemandFilter) {
    return response
  } else {
    const availableWorkouts =
      response.content && updateStartEndTimes(response.content)
    const workoutsWithLiveTimes = availableWorkouts.filter(
      (workout) => !workout.isOver,
    )

    const modifiedResponse: WorkoutsJsonSuccessResponse = {
      ...response,
      content: workoutsWithLiveTimes || [],
    }

    if (!workoutsWithLiveTimes.length)
      modifiedResponse.allWorkoutsAreOver = true

    return modifiedResponse
  }
}
