import { useEffect, useState } from 'react'
const useDeviceWidth = () => {
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth)
  useEffect(() => {
    const handleViewportResize = () => setDeviceWidth(window.innerWidth)

    window.addEventListener('resize', handleViewportResize)
    return (_) => {
      window.removeEventListener('resize', handleViewportResize)
    }
  })
  return deviceWidth
}

export default useDeviceWidth
