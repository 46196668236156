import { memo } from 'react'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { StyleTagHighlightsProps } from '../../OnDemandWorkoutsDetail.d'
import styles from './StyleTagHighlights.module.scss'

const StyleTagHighlights = ({
  styleTag,
  textSizes,
}: StyleTagHighlightsProps) => (
  <>
    <div className={styles.iconAndText}>
      <Icon.Line className={styles.icon} name="icon-calendar" />
      <Paragraph size={textSizes.title} className={styles.text}>
        {styleTag.title}
      </Paragraph>
    </div>
    {styleTag.descriptions && (
      <ul className={styles.workoutHighlightsList}>
        <Paragraph size={textSizes.text} weight="book">
          {styleTag.descriptions.map((description, index) => (
            <li key={index} className={styles.highlight}>
              {description}
            </li>
          ))}
        </Paragraph>
      </ul>
    )}
  </>
)

export default memo(StyleTagHighlights)
