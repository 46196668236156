import { ReactNode, memo } from 'react'
import classNames from 'classnames'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { testIdAttribute, testIds } from '../../../common/utility/labels'
import { useDeviceWidth } from '../../hooks'
import styles from './PageTitle.module.scss'

const {
  HOME: { PAGE_SUBTITLE, PAGE_TITLE },
} = testIds

const subtitleTestId = testIdAttribute(PAGE_SUBTITLE)
const titleTestId = testIdAttribute(PAGE_TITLE)

type PageTitleProps = {
  className?: string
  title: string
  children: ReactNode
}

const setDescriptionTextSizeByWindowWidth = (width: number) => {
  if (width >= 1200) {
    return 'large'
  } else if (width >= 576) {
    return 'default'
  }
  return 'small'
}

const PageTitle = ({
  className = '',
  title,
  children,
  ...props
}: PageTitleProps) => {
  const deviceWidth = useDeviceWidth()

  return (
    <div className={classNames(styles.pageHeader, className)} {...props}>
      <Heading {...titleTestId} type="h1" className={styles.title}>
        {title}
      </Heading>
      <Paragraph
        {...subtitleTestId}
        size={setDescriptionTextSizeByWindowWidth(deviceWidth)}
        weight="book"
      >
        {children}
      </Paragraph>
    </div>
  )
}

export default memo(PageTitle)
