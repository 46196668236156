import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import useDayOfWeek from '../../common/components/DayOfWeek/hooks/useDayOfWeek'
import {
  useCheckin,
  useDeviceWidth,
  useScrolledToRefBottom,
  useSessionStorage,
} from '../../common/hooks'
import useThrottle from '../../common/hooks/useThrottle'
import { parseCheckInError } from '../../common/networking/checkins/logic/checkinError'
import { workoutsApi } from '../../common/networking/workouts'
import {
  analytics,
  analyticsEvent,
  analyticsEventLabel,
} from '../../common/utility/analytics'
import { trackFavoriteEvents } from '../../common/utility/commonAnalytics'
import { getHhMmFromUtcDate } from '../../common/utility/datetimes'
import { testIdAttribute, testIds } from '../../common/utility/labels'
import configuration from '../../configuration'
import { authSelector, redirectToPath } from '../../redux/features/Auth'
import { AuthToken } from '../../redux/features/Auth/auth'
import {
  favorite,
  favoriteSelector,
} from '../../redux/features/OnDemand/Favorite'
import {
  filtersReset,
  filtersSelector,
  getFilters,
  setFavorite,
  setFilters,
  setTime,
} from '../../redux/features/OnDemand/Filters'
import {
  getWorkouts,
  parseResponseByFilterType,
  workoutsReset,
  workoutsSelector,
} from '../../redux/features/OnDemand/Workouts'
import {
  resetError,
  virtualCheckInSelector,
} from '../../redux/features/Virtual/CheckIn'
import {
  selectedVirtualWorkoutReset,
  selectedVirtualWorkoutSelector,
  setVirtualWorkoutSuccess,
} from '../../redux/features/Virtual/SelectedWorkout'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { JoinLiveClickArgs } from '../OnDemandWorkouts/OnDemandWorkouts.d'

const { trackCustomEvent } = analytics
const { BUTTON_CLICK, SCREEN_CHANGE } = analyticsEvent
const {
  COMMON: { FAVORITES },
  WORKOUT_DETAILS: { JOIN_LIVE },
} = analyticsEventLabel

const useVirtualWorkouts = () => {
  const [isPageInitialized, setIsPageInitialized] = useState<boolean>(false)
  const [hasJoinedWorkout, setHasJoinedWorkout] = useState<boolean>(false)
  const [isOverlayOn, setIsOverlayOn] = useState(false)
  const deviceWidth = useDeviceWidth()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const history = useHistory()
  const workoutsGridRef = useRef<HTMLDivElement>(null)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const [isGrowlVisible, setIsGrowlVisible] = useState<boolean>(false)
  const [filteredNumberOfWorkouts, setFilteredNumberOfWorkouts] =
    useState<number>(0)
  const [trainerId, setTrainerId] = useState<string>('')
  const [isSliderFilterLoading, setIsSliderFilterLoading] =
    useState<boolean>(false)
  const virtualWorkoutsPath = window.location.pathname === '/virtual-workouts'

  const { createCheckin } = useCheckin()
  const throttleFunction = useThrottle()

  const pageTitle = 'Live Workouts'

  const workoutsGridLoadNewPageOffsetInPx = -100

  const { workoutAnticipationMinutes } = configuration

  const scrolledToWorkoutsGridBottom = useScrolledToRefBottom(
    workoutsGridRef,
    workoutsGridLoadNewPageOffsetInPx,
  )
  const { token } = useAppSelector(authSelector)
  const {
    timesFilter: { startTime, endTime },
    tagFilters: tagFiltersApplied,
    favoriteFilter: isFavoriteFilterApplied,
    hideCompletedFilter: isHideCompletedFilterApplied,
  } = useAppSelector(filtersSelector)

  const [
    isFavoriteFilterAppliedFromStorage,
    setIsFavoriteFilterAppliedFromStorage,
  ] = useSessionStorage('isFavoriteFilterApplied', false)
  const [, , removeFilterValues] = useSessionStorage('filterValues')

  const {
    isLoading: isLoadingWorkouts,
    lastPageFetched,
    totalNumberOfPages,
    totalNumberOfWorkouts,
    workouts,
  } = useAppSelector(workoutsSelector)

  const { selectedVirtualWorkout } = useAppSelector(
    selectedVirtualWorkoutSelector,
  )
  const allFavorites = useAppSelector(favoriteSelector.selectEntities)
  const {
    isLoading: isCheckinLoading,
    error: checkinError,
    checkedInWorkout,
  } = useAppSelector(virtualCheckInSelector)

  const hasSessionExpired =
    parseCheckInError(checkinError) === 'Session Expired'
  const growlMessage = 'Something went wrong. Please try again.'

  const isMorePagesToFetch =
    (typeof lastPageFetched === 'undefined' ? -1 : lastPageFetched) <
    totalNumberOfPages - 1

  const onClearFilterByTimeClick = () => {
    setFiltersApplied(false)
    removeFilterValues()
    dispatch(workoutsReset())
    dispatch(filtersReset())
  }

  const onApplyFilterByTimeClick = (
    startTime: string,
    endTime: string,
    tagId: number | null,
  ) => {
    const startValue = getHhMmFromUtcDate(startTime)
    const endValue = getHhMmFromUtcDate(endTime)

    if (startValue === '00:00' && endValue === '23:59' && tagId === null) {
      onClearFilterByTimeClick()
    } else {
      dispatch(workoutsReset())
      dispatch(setTime(startTime, endTime))
      const tagFilter = tagId ? [tagId] : []
      dispatch(setFilters(tagFilter, false))
      setFiltersApplied(true)
    }
  }

  const { fetchWorkouts } = workoutsApi()

  const redirectToLogin = useCallback(
    () =>
      history.push(
        `/login?redirectUrl=${encodeURIComponent(window.location.href)}`,
      ),
    [history],
  )

  const onFilterByTimeSlide = async (
    startTime: string,
    endTime: string,
    areFiltersVisible: boolean,
    tags: number[],
  ) => {
    setIsSliderFilterLoading(true)
    const authToken = token
    const response = await fetchWorkouts({
      startTimeFilter: startTime,
      endTimeFilter: endTime,
      favoriteFilter: isFavoriteFilterApplied,
      hideCompletedFilter: false,
      tagFilters: tags,
      timeFilter: areFiltersVisible,
      trainerId,
      authToken,
    })
    const finalResponse = parseResponseByFilterType({
      response,
      isOnDemandFilter: false,
    })
    const total = isFavoriteFilterApplied
      ? finalResponse.content.length
      : finalResponse.totalElements
    setFilteredNumberOfWorkouts(total)
    setIsSliderFilterLoading(false)
  }

  const handleOnFavoriteClick = (workoutId: number, isFavorite: boolean) => {
    const favoriteParams = { workoutId, isFavorite }
    trackFavoriteEvents(isFavorite)
    dispatch(favorite(favoriteParams))
  }

  const handleOnGetFavoritesWorkouts = () => {
    dispatch(workoutsReset())
    dispatch(setFavorite(!isFavoriteFilterApplied))
    setIsFavoriteFilterAppliedFromStorage(!isFavoriteFilterAppliedFromStorage)
    trackCustomEvent(BUTTON_CLICK(FAVORITES))
  }

  const handleOnJoinLiveClick = async ({
    target,
    workout,
  }: JoinLiveClickArgs) => {
    trackCustomEvent(BUTTON_CLICK(JOIN_LIVE))
    window.open(workout?.link, target)
    await dispatch(setVirtualWorkoutSuccess(workout))
  }

  const handleOnLoginClick = () => {
    dispatch(redirectToPath(location.pathname))
    redirectToLogin()
  }

  const onCheckInClick = async (
    userId: string,
    workoutId: number,
    token: AuthToken,
  ) => {
    createCheckin({
      type: 'ONLINE',
      userId,
      workoutId,
      token,
    })
  }

  const onGrowlClose = () => {
    dispatch(resetError())
    setIsGrowlVisible(false)
  }

  const useDayOfWeekProps = useDayOfWeek({
    onClearFilterByTimeClick,
  })

  const { dayOfWeekItems } = useDayOfWeekProps

  const overlayTestId = testIdAttribute(testIds.WORKOUTS.OVERLAY)
  const currentDayOfWeekSelected = dayOfWeekItems.filter((day) => day.active)[0]
    .id

  const loadMoreWorkouts = useCallback(() => {
    !isLoadingWorkouts &&
      isMorePagesToFetch &&
      dispatch(
        getWorkouts({
          isOnDemandFilter: false,
          dayOfWeekFilter: currentDayOfWeekSelected,
          trainerId,
        }),
      )
  }, [
    dispatch,
    isLoadingWorkouts,
    isMorePagesToFetch,
    currentDayOfWeekSelected,
    trainerId,
  ])

  const isSelectedWorkoutLive = useCallback(() => {
    if (selectedVirtualWorkout?.startTime) {
      const dateStartLive = moment
        .utc(selectedVirtualWorkout.startTime)
        .subtract(workoutAnticipationMinutes, 'minutes')
      const dateEndLive = moment
        .utc(selectedVirtualWorkout.startTime)
        .add(selectedVirtualWorkout.duration, 'minutes')
      const usersCurrentTime = moment.utc()
      const isLive = usersCurrentTime.isBetween(dateStartLive, dateEndLive)
      return isLive
    } else {
      return false
    }
  }, [
    selectedVirtualWorkout.duration,
    selectedVirtualWorkout.startTime,
    workoutAnticipationMinutes,
  ])

  const isLoadingFavorite = (workoutId: number) =>
    (allFavorites &&
      allFavorites[workoutId] &&
      allFavorites[workoutId].isLoading) ||
    false

  const onClearTrainerSearch = () => {
    setTrainerId('')
  }

  const loadWorkouts = useCallback(() => {
    dispatch(workoutsReset())
    if (isFavoriteFilterAppliedFromStorage) {
      dispatch(setFavorite(true))
    }
    dispatch(
      getWorkouts({
        isOnDemandFilter: false,
        dayOfWeekFilter: currentDayOfWeekSelected,
        trainerId,
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    isHideCompletedFilterApplied,
    currentDayOfWeekSelected,
    filtersApplied,
    trainerId,
    isFavoriteFilterAppliedFromStorage,
    startTime,
    endTime,
    tagFiltersApplied,
  ])

  useEffect(() => {
    throttleFunction(loadWorkouts, 500)
  }, [loadWorkouts, throttleFunction])

  useEffect(() => {
    dispatch(getFilters())
    setIsPageInitialized(true)
  }, [dispatch])

  useEffect(() => {
    scrolledToWorkoutsGridBottom && loadMoreWorkouts()
  }, [scrolledToWorkoutsGridBottom, loadMoreWorkouts])

  useEffect(() => {
    if (selectedVirtualWorkout && selectedVirtualWorkout?.startTime) {
      setHasJoinedWorkout(true)
    }
  }, [selectedVirtualWorkout])

  useEffect(() => {
    if (hasJoinedWorkout) {
      if (!isSelectedWorkoutLive()) {
        setHasJoinedWorkout(false)
        dispatch(selectedVirtualWorkoutReset())
      }
    }
  }, [dispatch, hasJoinedWorkout, isSelectedWorkoutLive])

  useEffect(() => {
    setIsGrowlVisible(!!checkinError)
  }, [checkinError])

  useEffect(() => {
    if (hasSessionExpired) {
      dispatch(resetError())
      dispatch(redirectToPath(location.pathname))
      redirectToLogin()
    }
  }, [hasSessionExpired, dispatch, history, location.pathname, redirectToLogin])

  useEffect(() => {
    trackCustomEvent(SCREEN_CHANGE())
  }, [])

  useEffect(() => {
    if (isFavoriteFilterApplied) {
      setIsFavoriteFilterAppliedFromStorage(true)
    }
  }, [isFavoriteFilterApplied, setIsFavoriteFilterAppliedFromStorage])

  useEffect(() => {
    if (virtualWorkoutsPath) {
      setIsFavoriteFilterAppliedFromStorage(false)
      dispatch(setFavorite(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [virtualWorkoutsPath])

  return {
    allFavorites,
    checkedInWorkout,
    checkinError,
    currentDayOfWeekSelected,
    deviceWidth,
    filteredNumberOfWorkouts,
    filtersApplied,
    growlMessage,
    hasJoinedWorkout,
    isCheckinLoading,
    isFavoriteFilterApplied,
    isGrowlVisible,
    isHideCompletedFilterApplied,
    isLoadingWorkouts,
    isOverlayOn,
    isPageInitialized,
    isSliderFilterLoading,
    overlayTestId,
    pageTitle,
    selectedVirtualWorkout,
    tagFiltersApplied,
    trainerId,
    totalNumberOfWorkouts,
    useDayOfWeekProps,
    virtualWorkoutsPath,
    workouts,
    workoutsGridRef,
    handleOnFavoriteClick,
    handleOnGetFavoritesWorkouts,
    handleOnJoinLiveClick,
    handleOnLoginClick,
    isLoadingFavorite,
    onApplyFilterByTimeClick,
    onCheckInClick,
    onClearFilterByTimeClick,
    onClearTrainerSearch,
    onFilterByTimeSlide,
    onGrowlClose,
    setIsOverlayOn,
    setTrainerId,
  }
}

export default useVirtualWorkouts
