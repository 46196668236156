import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import { WorkoutsJsonSuccessResponse, WorkoutsState } from '.'

export const initialState: WorkoutsState = {
  error: '',
  isLoading: false,
  lastPageFetched: undefined,
  totalNumberOfPages: 0,
  totalNumberOfWorkouts: 0,
  workouts: [],
}

export const workoutsSlice = createSlice({
  name: 'workouts',
  initialState,
  reducers: {
    getWorkoutsPending: (
      state: WorkoutsState,
      action: PayloadAction<boolean>,
    ) => {
      state.isLoading = action.payload
    },
    getWorkoutsSuccess: (
      state: WorkoutsState,
      action: PayloadAction<WorkoutsJsonSuccessResponse>,
    ) => {
      state.error = initialState.error
      state.isLoading = initialState.isLoading
      state.lastPageFetched = action.payload.number
      state.totalNumberOfPages = action.payload.totalPages
      state.totalNumberOfWorkouts = action.payload.totalElements
      state.workouts = [...state.workouts, ...action.payload.content]
    },
    getWorkoutsFailure: (
      state: WorkoutsState,
      action: PayloadAction<WorkoutsState['error']>,
    ) => {
      state.error = action.payload
      state.isLoading = initialState.isLoading
    },
    workoutsReset: () => initialState,
    workoutsFavoriteUpdate: (
      state: WorkoutsState,
      action: PayloadAction<number>,
    ) => {
      state.workouts = state.workouts.map((workout) =>
        workout.id === action.payload
          ? { ...workout, favorite: !workout.favorite }
          : workout,
      )
    },
  },
})

export const {
  getWorkoutsPending,
  getWorkoutsSuccess,
  getWorkoutsFailure,
  workoutsFavoriteUpdate,
  workoutsReset,
} = workoutsSlice.actions

export const workoutsSelector = (state: RootState) =>
  state.workouts as WorkoutsState

export default workoutsSlice.reducer
