import { memo } from 'react'
import { Header as HeaderCore } from '@campgladiator/cgui-core.organisms.header'
import { KeanuUser } from '../../../redux/features/Auth/auth'
import { useHeader } from './useHeader'

type HeaderProps = {
  isLoggedIn: boolean
  user?: KeanuUser
}

const Header = ({ isLoggedIn, user }: HeaderProps) => {
  const { headerLinks, loginUrl, userButtonPropsWithAvatar, handleLogout } =
    useHeader({ user })

  return (
    <HeaderCore
      loginUrl={loginUrl}
      headerLinks={headerLinks}
      isLoggedIn={isLoggedIn}
      userButton={userButtonPropsWithAvatar}
      onLogoutClick={handleLogout}
    />
  )
}

export default memo(Header)
