import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { AuthState } from '.'

export const initialState: AuthState = {
  isLoading: false,
  isLoggedIn: false,
  error: '',
  user: {},
  token: { token: '', refreshToken: '', type: '' },
  redirectPath: '/',
  statistic: {},
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginPending: (state: AuthState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    loginSuccess: (
      state: AuthState,
      action: PayloadAction<{
        user?: AuthState['user']
        token: AuthState['token']
      }>,
    ) => {
      state.isLoading = initialState.isLoading
      state.user = action.payload.user || initialState.user
      state.token = action.payload.token
      state.isLoggedIn = true
      state.error = initialState.error
    },
    loginFailure: (
      state: AuthState,
      action: PayloadAction<AuthState['error']>,
    ) => {
      state.isLoading = initialState.isLoading
      state.isLoggedIn = initialState.isLoggedIn
      state.error = action.payload
      state.user = initialState.user
      state.token = initialState.token
    },
    logoutSuccess: () => initialState,
    clearError: (state: AuthState) => {
      state.error = initialState.error
    },
    setRedirectPath: (
      state: AuthState,
      action: PayloadAction<AuthState['redirectPath']>,
    ) => {
      state.redirectPath = action.payload
    },
  },
})

export const {
  loginPending,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  clearError,
  setRedirectPath,
} = authSlice.actions
export const authSelector = (state: RootState) => state.auth
export default authSlice.reducer
