const { REACT_APP_BUILD_ENVIRONMENT } = process.env

type ConfigEnv = {
  amplitudeApiKey: string
  apiGatewayUrl: string
  cgDomain: string
  cgWebsiteUrl: string
  cgWebsiteLoginUrl: string
  cgWebsiteRegisterUrl: string
  onlinePlatformApi: string
  workoutAnticipationMinutes: number
  workoutsPageSize: number
  publicCookieDomain: string
  publicCookieName: string
  publicCookieExpirationInSeconds: number
  publicCookieIntervalCheckInMilliseconds: number
  publicCookieRefreshTokenIntervalCheckInMilliseconds: number
  isOtelActive: boolean
  otelUrl: string
}

type Config = {
  development: ConfigEnv
  staging: ConfigEnv
  production: ConfigEnv
}

const config: Config = {
  development: {
    amplitudeApiKey: '065b279f7e8e4a97e95cf1c17a372d52',
    apiGatewayUrl: 'https://staging.api-gateway.campgladiator.com',
    cgDomain: 'campgladiator.com',
    cgWebsiteUrl: 'http://localhost:3000',
    cgWebsiteLoginUrl: 'http://localhost:3000/login',
    cgWebsiteRegisterUrl:
      'http://localhost:3000/register/login?productType=BOOTCAMP&productSubType=ONLINE',
    onlinePlatformApi: 'https://staging.workout-library.api.campgladiator.com',
    workoutAnticipationMinutes: 10,
    workoutsPageSize: 30,
    publicCookieDomain: 'localhost',
    publicCookieName: 'cgAuthTokenDev',
    publicCookieExpirationInSeconds: 86400,
    publicCookieIntervalCheckInMilliseconds: 3000,
    publicCookieRefreshTokenIntervalCheckInMilliseconds: 300000,
    isOtelActive: false,
    otelUrl: 'https://staging-otel.campgladiator.com',
  },
  staging: {
    amplitudeApiKey: '065b279f7e8e4a97e95cf1c17a372d52',
    apiGatewayUrl: 'https://staging.api-gateway.campgladiator.com',
    cgDomain: 'campgladiator.com',
    cgWebsiteUrl: 'https://staging-web.campgladiator.com',
    cgWebsiteLoginUrl: 'https://staging-web.campgladiator.com/login',
    cgWebsiteRegisterUrl:
      'https://staging-web.campgladiator.com/register/login?productType=BOOTCAMP&productSubType=ONLINE',
    onlinePlatformApi: 'https://staging.workout-library.api.campgladiator.com',
    workoutAnticipationMinutes: 10,
    workoutsPageSize: 30,
    publicCookieDomain: 'campgladiator.com',
    publicCookieName: 'cgAuthTokenStaging',
    publicCookieExpirationInSeconds: 86400,
    publicCookieIntervalCheckInMilliseconds: 3000,
    publicCookieRefreshTokenIntervalCheckInMilliseconds: 300000,
    isOtelActive: false,
    otelUrl: 'https://staging-otel.campgladiator.com',
  },
  production: {
    amplitudeApiKey: '6d4ccdaed1b64ed4cdfab8a5f91bdde9',
    apiGatewayUrl: 'https://gateway.campgladiator.com',
    cgDomain: 'campgladiator.com',
    cgWebsiteUrl: 'https://campgladiator.com',
    cgWebsiteLoginUrl: 'https://campgladiator.com/login',
    cgWebsiteRegisterUrl:
      'https://campgladiator.com/register/login?productType=BOOTCAMP&productSubType=ONLINE',
    onlinePlatformApi: 'https://workout-library.api.campgladiator.com',
    workoutAnticipationMinutes: 10,
    workoutsPageSize: 30,
    publicCookieDomain: 'campgladiator.com',
    publicCookieName: 'cgAuthToken',
    publicCookieExpirationInSeconds: 604800,
    publicCookieIntervalCheckInMilliseconds: 3000,
    publicCookieRefreshTokenIntervalCheckInMilliseconds: 3600000,
    isOtelActive: true,
    otelUrl: 'https://otel.campgladiator.com',
  },
}

const configuration = {
  ...config[REACT_APP_BUILD_ENVIRONMENT],
}

export default configuration
