import classNames from 'classnames'
import * as grid from '@campgladiator/cgui-core.styles.helpers.grid'
import styles from './Skeleton.module.scss'

const workoutBoxAmount = 9

export const SkeletonWorkouts = () => (
  <>
    {[...Array(workoutBoxAmount)].map((_, i) => (
      <div className={classNames(grid.col12, grid.colMd6, grid.colLg4)} key={i}>
        <div className={styles.workoutBox} />
      </div>
    ))}
  </>
)

export const OnDemandSkeleton = () => (
  <div className={classNames(grid.container, styles.skeleton)}>
    <div className={styles.title} />
    <div className={styles.subtitle} />
    <div className={styles.filter} />
    <div className={classNames(grid.row, styles.workoutsGrid)}>
      <SkeletonWorkouts />
    </div>
  </div>
)
