import { Action, ThunkDispatch } from '@reduxjs/toolkit'
import { favoritesApi } from '../../../../common/networking/favorites'
import { AppThunk, RootState } from '../../../store'
import { AuthToken } from '../../Auth/auth'
import { setVirtualWorkoutSuccess } from '../../Virtual/SelectedWorkout'
import { VirtualWorkout } from '../../Virtual/virtualWorkout'
import { selectedWorkoutFavoriteUpdate } from '../SelectedWorkout'
import { workoutsFavoriteUpdate } from '../Workouts'
import {
  FavoriteParams,
  favoriteAdded,
  favoriteFailure,
  favoritePending,
  favoriteRemoved,
} from '.'

const { postFavorite, deleteFavorite } = favoritesApi()

const handleAddFavorite = async ({
  dispatch,
  args,
  authToken,
}: {
  dispatch: ThunkDispatch<{}, null, Action<string>>
  args: FavoriteParams
  authToken: AuthToken
}) => {
  if (args.isFavorite) {
    await deleteFavorite(args, authToken)
    dispatch(favoriteRemoved(args.workoutId))
  } else {
    await postFavorite(args, authToken)
    dispatch(
      favoriteAdded({
        workoutId: args.workoutId,
        isLoading: false,
        error: '',
      }),
    )
  }
}

const handleAddFavoriteFailure = ({
  dispatch,
  error,
  workoutId,
}: {
  dispatch: ThunkDispatch<{}, null, Action<string>>
  error: any
  workoutId: number
}) => {
  const response = error as Response
  if (response.status === 409) {
    dispatch(
      favoriteAdded({
        workoutId: workoutId,
        isLoading: false,
        error: '',
      }),
    )
  } else {
    dispatch(
      favoriteFailure({
        workoutId: workoutId,
        isLoading: false,
        error: 'An unknown error occured',
      }),
    )
  }
}

const handleFavoriteComplete = ({
  dispatch,
  args,
  selectedVirtualWorkout,
}: {
  dispatch: ThunkDispatch<{}, null, Action<string>>
  args: FavoriteParams
  selectedVirtualWorkout: VirtualWorkout
}) => {
  dispatch(workoutsFavoriteUpdate(args.workoutId))
  if (selectedVirtualWorkout.id === args.workoutId) {
    const updatedSelected = { ...selectedVirtualWorkout }
    updatedSelected.favorite = !args.isFavorite
    dispatch(setVirtualWorkoutSuccess(updatedSelected))
  }
  dispatch(selectedWorkoutFavoriteUpdate())
}

export const favorite =
  (args: FavoriteParams): AppThunk =>
  async (dispatch, getState) => {
    const { workoutId } = args
    dispatch(
      favoritePending({
        workoutId,
        isLoading: true,
        error: '',
      }),
    )
    const {
      auth: { token: authToken },
      selectedVirtualWorkout: { selectedVirtualWorkout },
    } = getState() as RootState

    try {
      handleAddFavorite({ dispatch, args, authToken })
    } catch (error: any) {
      handleAddFavoriteFailure({ dispatch, error, workoutId })
    } finally {
      handleFavoriteComplete({ dispatch, args, selectedVirtualWorkout })
    }
  }
