import { Workout } from '../../../../../../redux/features/OnDemand/onDemandWorkouts.d'

const hiddenTagTypes = ['equipment', 'constraint']

export const visibleTags = (workout: Workout) =>
  workout.tags.filter((tag) =>
    workout.type === 'ONDEMAND'
      ? !hiddenTagTypes.includes(tag.tagType)
      : tag.tagType === 'style',
  )
