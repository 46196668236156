import { SubscriptionSummary } from '../../redux/features/Auth/auth'

export const validUserSubscriptionStatusForWorkouts: Array<
  SubscriptionSummary['status']
> = ['ACTIVE', 'PENDING', 'TRIAL']

const validUserSubscriptionTypeForWorkouts: Array<SubscriptionSummary['type']> =
  ['BOOTCAMP']

export const checkUserHasValidOnlineSubscription = (
  subscriptions: SubscriptionSummary[],
) =>
  subscriptions.some(
    ({ status, type }) =>
      validUserSubscriptionTypeForWorkouts.includes(type) &&
      validUserSubscriptionStatusForWorkouts.includes(status),
  )
