import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { Action, combineReducers, configureStore } from '@reduxjs/toolkit'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { ThunkAction } from 'redux-thunk'
import { authReducer } from './features/Auth/'
import { checkInReducer } from './features/OnDemand/CheckIn'
import { favoriteReducer } from './features/OnDemand/Favorite'
import { filtersReducer } from './features/OnDemand/Filters'
import { selectedWorkoutReducer } from './features/OnDemand/SelectedWorkout'
import { workoutsReducer } from './features/OnDemand/Workouts'
import { virtualCheckInReducer } from './features/Virtual/CheckIn'
import { selectedVirtualWorkoutReducer } from './features/Virtual/SelectedWorkout'

export const history = createBrowserHistory()

let middleware = [routerMiddleware(history)]

if (
  process.env.NODE_ENV !== 'production' &&
  !process.env.REACT_APP_SHOW_REDUX_LOGS
) {
  const { logger } = require('redux-logger')
  middleware = [...middleware, logger]
}

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['user', 'isLoggedIn', 'token'],
}

const selectedVirtualWorkoutPersistConfig = {
  key: 'selectedVirtualWorkout',
  storage,
  whitelist: ['selectedVirtualWorkout'],
}

const virtualCheckInWorkoutPersistConfig = {
  key: 'checkedInWorkout',
  storage,
  whitelist: ['checkedInWorkout'],
}

const combinedReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  checkIn: checkInReducer,
  favorite: favoriteReducer,
  filters: filtersReducer,
  router: connectRouter(history),
  selectedWorkout: selectedWorkoutReducer,
  selectedVirtualWorkout: persistReducer(
    selectedVirtualWorkoutPersistConfig,
    selectedVirtualWorkoutReducer,
  ),
  virtualCheckIn: persistReducer(
    virtualCheckInWorkoutPersistConfig,
    virtualCheckInReducer,
  ),
  workouts: workoutsReducer,
})

const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  {},
  null,
  Action<string>
>
export const useAppDispatch = () => useDispatch<any>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
