import { Link } from 'react-router-dom'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { CardPartProps } from '../../../../../../../pages/OnDemandWorkouts/OnDemandWorkouts.d'
import { Tags } from '../../../Tags'
import baseStyles from '../../BottomPartCard.module.scss'
import { visibleTags } from '../../logic'

const Ondemand = ({ workout, redirectRoute }: CardPartProps) => (
  <div className={baseStyles.bottomPart}>
    <Paragraph className={baseStyles.workoutTitle}>
      <Link className={baseStyles.textLink} to={redirectRoute!(workout)}>
        {workout.title}
      </Link>
    </Paragraph>
    {visibleTags.length > 0 && (
      <div className={baseStyles.actions}>
        <Tags items={visibleTags(workout)} />
      </div>
    )}
  </div>
)

export default Ondemand
