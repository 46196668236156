import { workoutsApi } from '../../../../common/networking/workouts'
import { AppThunk, RootState } from '../../../store'
import { getWorkoutFailure, getWorkoutPending, getWorkoutSuccess } from '.'

export const getWorkout =
  (workoutId: number): AppThunk =>
  async (dispatch, getState) => {
    const state = getState() as RootState
    const authToken = state.auth.token
    dispatch(getWorkoutPending(true))
    try {
      const { fetchWorkout } = workoutsApi()
      const response = await fetchWorkout({ workoutId, authToken })
      dispatch(getWorkoutSuccess(response))
    } catch (error: any) {
      dispatch(getWorkoutFailure(error.toString()))
    }
  }
