import classNames from 'classnames'
import * as grid from '@campgladiator/cgui-core.styles.helpers.grid'
import styles from './Skeleton.module.scss'

const OnDemandDetailsSkeleton = ({ isMobile }: { isMobile: boolean }) => (
  <div className={classNames(grid.container, styles.skeleton)}>
    <div className={styles.title} />
    {isMobile && <div className={styles.subtitle} />}
    <div className={styles.videoBox} />
    <div className={styles.trainerInfoContainer}>
      <div className={styles.trainerData}>
        <div className={styles.trainerImage} />
        <div>
          <div className={styles.trainer} />
          <div className={styles.trainerName} />
        </div>
      </div>
      <div className={styles.automatedCheckInBox} />
    </div>
    <div className={classNames(grid.row)}>
      <div className={classNames(grid.col12, grid.colMd6)}>
        <div className={styles.highlightsPlaceholder} />
        <div className={styles.workoutIntervalPlaceholder} />

        <div className={styles.workoutTimePlaceholder} />
        <div className={styles.equipmentPlaceholder} />
        <div />
      </div>
      <div className={classNames(grid.col12, grid.colMd6)}>
        <div className={styles.overviewPlaceholder} />
      </div>
    </div>
  </div>
)

export default OnDemandDetailsSkeleton
