const getScreenPageName = () => {
  const { pathname = '' } = window.location
  const id = window.location.pathname.split('/')[2]

  switch (pathname) {
    case '/':
      return 'On-Demand'
    case `/workout/${id}`:
      return `On-Demand Details / ${id}`
    case `/trainer/${id}`:
      return 'Trainer Bios'
    case '/virtual-workouts':
      return 'Live Online Workouts'
    default:
      return ''
  }
}

const analyticsEventData = (eventName: string, label: string = '') => {
  const basicEvents = {
    Event: eventName,
    Screen: getScreenPageName(),
  }

  if (!label) {
    return basicEvents
  } else {
    const allEvents = { ...basicEvents, Label: label }
    return allEvents
  }
}

const analyticsEvent = {
  AUTOMATED_CHECKIN: (label: string) =>
    analyticsEventData('Automated Checkin', label),
  BUTTON_CLICK: (label: string) => analyticsEventData('Button Click', label),
  CLOSE: (label?: string) => analyticsEventData('Close', label),
  INPUT: (label: string) => analyticsEventData('Input', label),
  LINK: (label: string) => analyticsEventData('Link', label),
  LIST_ITEM: (label: string) => analyticsEventData('List Item', label),
  MODAL: (label: string) => analyticsEventData('Modal', label),
  POPOVER: (label: string) => analyticsEventData('Popover', label),
  PAGINATE: (label: string) => analyticsEventData('Paginate', label),
  SCREEN_CHANGE: () => analyticsEventData('Screen Change'),
  SET_SELECTOR: (label: string) => analyticsEventData('Set Selector', label),
  TOGGLE: (label: string) => analyticsEventData('Toggle', label),
  VIDEO: (label: string) => analyticsEventData('Video', label),
}

export default analyticsEvent
