import { push } from 'connected-react-router'
import { checkUserHasValidOnlineSubscription } from '../../../common/businessLogic/subscriptions'
import useAppAccess from '../../../common/networking/useAppAccess'
import {
  removeAuthToken,
  setAuthToken,
} from '../../../common/utility/authTokenStorage'
import { AppThunk, RootState } from '../../store'
import { AuthToken, KeanuUserRole } from './auth'
import {
  LoginParams,
  clearError,
  loginFailure,
  loginPending,
  loginSuccess,
  logoutSuccess,
  setRedirectPath,
} from '.'

const invalidCredentialsErrorMessages = [
  '[401 unauthorized] during [POST]',
  'Unable to find User with email',
  'email address must follow RFC 5322 standard',
  'the salt parameter must not be empty',
]

const authorizedClaims: KeanuUserRole[] = ['HQ', 'ADMIN', 'TRAINER', 'CAMPER']

export const parseError = (errorMessage: any) => {
  if (typeof errorMessage !== 'string') return 'unknownError'

  const hasInvalidCredentials = invalidCredentialsErrorMessages.some(
    (message) => errorMessage.toLowerCase().includes(message.toLowerCase()),
  )

  return hasInvalidCredentials ? 'Invalid credentials' : errorMessage
}

export const refreshToken =
  (token?: AuthToken): AppThunk =>
  async () => {
    const { verifySession } = useAppAccess()
    if (token) {
      verifySession(token)
        .then((newAuthToken) => {
          if (token.token !== newAuthToken.token) setAuthToken(newAuthToken)
        })
        .catch(() => {
          removeAuthToken()
        })
    }
  }

export const login =
  (params: LoginParams): AppThunk =>
  async (dispatch, getState) => {
    dispatch(loginPending(true))
    const state = getState() as RootState
    const {
      loginUser,
      fetchAuthorizationByToken,
      fetchKeanuUserById,
      fetchUserSubscriptionsSummaries,
    } = useAppAccess()
    try {
      let authResponse: AuthToken
      if (params.token) {
        authResponse = params.token
      } else {
        authResponse = await loginUser(params)
      }
      const { id } = await fetchAuthorizationByToken(
        authResponse.token,
        authorizedClaims,
      )
      const keanuUser = await fetchKeanuUserById(id)
      const loginData = {
        user: keanuUser,
        token: authResponse,
      }
      const userSubscriptionsSummary = await fetchUserSubscriptionsSummaries({
        userId: keanuUser.id!,
      })
      const doesUserHaveOnlineWorkoutAccess =
        checkUserHasValidOnlineSubscription(userSubscriptionsSummary)
      loginData.user.hasOnlineAccess = doesUserHaveOnlineWorkoutAccess
      setAuthToken(authResponse)
      dispatch(loginSuccess(loginData))
      if (!params.token) dispatch(push(state.auth.redirectPath))
    } catch (error: any) {
      const errorMessage = error.message
        ? error.message
        : 'An unknown error occurred'
      dispatch(loginFailure(errorMessage))
      logout()
    } finally {
      if (state.auth.isLoggedIn) {
        dispatch(setRedirectPath(state.auth.redirectPath))
      }
    }
  }

export const logout = () => (dispatch: any) => {
  removeAuthToken()
  dispatch(logoutSuccess())
}

export const resetError = () => (dispatch: any) => dispatch(clearError())

export const redirectToPath = (path: string) => (dispatch: any) =>
  dispatch(setRedirectPath(path))
