import configuration from '../../../configuration'
import { AuthToken } from '../../../redux/features/Auth/auth'
import {
  FavoriteDTO,
  FavoriteParams,
} from '../../../redux/features/OnDemand/Favorite'
import { request } from '../../utility/fetch'

const baseUrl = configuration.apiGatewayUrl

const favoritesApi = () => {
  const postFavorite = async (
    args: FavoriteParams,
    authToken: AuthToken,
  ): Promise<FavoriteDTO> => {
    const endpoint = '/engagement/v1/favorites'
    const params = `?resourceId=${args.workoutId}&favoriteType=WORKOUT`
    const method = 'POST'
    const body = JSON.stringify({
      resourceId: args.workoutId,
      favoriteType: 'WORKOUT',
    })
    const response = await request({
      authToken,
      baseUrl,
      body,
      endpoint,
      method,
      params,
    })

    const result = await response.json()

    return response.ok ? (result as FavoriteDTO) : Promise.reject(response)
  }

  const deleteFavorite = async (args: FavoriteParams, authToken: AuthToken) => {
    const endpoint = '/engagement/v1/favorites'
    const params = `?resourceId=${args.workoutId}&favoriteType=WORKOUT`
    const method = 'DELETE'
    const body = JSON.stringify({
      resourceId: args.workoutId,
      favoriteType: 'WORKOUT',
    })
    const response = await request({
      authToken,
      baseUrl,
      body,
      endpoint,
      method,
      params,
    })

    return response.ok ? Promise.resolve() : Promise.reject(response)
  }

  return {
    postFavorite,
    deleteFavorite,
  }
}

export default favoritesApi
