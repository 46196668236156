import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import { VirtualWorkout } from '../virtualWorkout'
import { VirtualWorkoutState } from '.'

export const initialState: VirtualWorkoutState = {
  error: '',
  isLoading: false,
  selectedVirtualWorkout: {} as VirtualWorkout,
}

export const selectedVirtualWorkoutSlice = createSlice({
  name: 'selectedVirtualWorkout',
  initialState,
  reducers: {
    setVirtualWorkoutPending: (
      state: VirtualWorkoutState,
      action: PayloadAction<boolean>,
    ) => {
      state.isLoading = action.payload
      state.selectedVirtualWorkout = initialState.selectedVirtualWorkout
    },
    setVirtualWorkoutSuccess: (
      state: VirtualWorkoutState,
      action: PayloadAction<VirtualWorkout>,
    ) => {
      state.selectedVirtualWorkout = action.payload
      state.error = initialState.error
      state.isLoading = initialState.isLoading
    },
    setVirtualWorkoutFailure: (
      state: VirtualWorkoutState,
      action: PayloadAction<VirtualWorkoutState['error']>,
    ) => {
      state.error = action.payload
      state.selectedVirtualWorkout = initialState.selectedVirtualWorkout
    },
    selectedVirtualWorkoutReset: () => initialState,
    selectedVirtualWorkoutFavoriteUpdate: (state: VirtualWorkoutState) => {
      state.selectedVirtualWorkout.favorite =
        !state.selectedVirtualWorkout.favorite
    },
  },
})

export const {
  setVirtualWorkoutPending,
  setVirtualWorkoutSuccess,
  setVirtualWorkoutFailure,
  selectedVirtualWorkoutFavoriteUpdate,
  selectedVirtualWorkoutReset,
} = selectedVirtualWorkoutSlice.actions

export const selectedVirtualWorkoutSelector = (state: RootState) =>
  state.selectedVirtualWorkout

export default selectedVirtualWorkoutSlice.reducer
