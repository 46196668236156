import configuration from '../../configuration'
import { AuthToken } from '../../redux/features/Auth/auth'

const authTokenKey = configuration.publicCookieName
const cookieDomain = configuration.publicCookieDomain
const cookieExpiration = configuration.publicCookieExpirationInSeconds
const cookieIntervalCheck =
  configuration.publicCookieIntervalCheckInMilliseconds
const cookieRefreshTokenIntervalCheck =
  configuration.publicCookieRefreshTokenIntervalCheckInMilliseconds

export const removeAuthToken = () =>
  (document.cookie = `${authTokenKey}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=${cookieDomain}`)

export const setAuthToken = ({ token, type, refreshToken }: AuthToken) => {
  const expirationTime = getExpireTime(cookieExpiration)
  const cookieValue = `${JSON.stringify({
    token,
    type,
    refreshToken,
  })}`
  const cookie = `${authTokenKey}=${cookieValue};expires=${expirationTime};path=/;domain=${cookieDomain}`

  document.cookie = cookie
}

const getExpireTime = (secondsFromNow: number) => {
  const now = new Date()
  const expiredDateTime = new Date(now.getTime() + 1000 * secondsFromNow)
  return expiredDateTime.toUTCString()
}

export const getAuthToken = () => {
  const cookie = getCookieByName(authTokenKey)
  try {
    return cookie ? (JSON.parse(cookie) as AuthToken) : undefined
  } catch (error) {
    return undefined
  }
}

const getCookieByName = (name: string) => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
  if (match) return match[2]
}

const getAuthTokenHash = () => {
  const authTokenCookie = getAuthToken()
  let hash = ''
  if (authTokenCookie) {
    hash = (authTokenCookie as AuthToken).token
  }
  return { hash, authTokenCookie }
}

export const addAuthCookieListener = (
  callBackLogin: (token: AuthToken) => void,
  callBackLogout: () => void,
) => {
  let lastCookie = getAuthTokenHash()

  const checkCookieChanges = () => {
    const currentCookie = getAuthTokenHash()
    if (currentCookie.hash !== lastCookie.hash) {
      lastCookie = currentCookie
      if (currentCookie.authTokenCookie)
        callBackLogin(currentCookie.authTokenCookie)
      else callBackLogout()
    }
  }

  window.setInterval(() => {
    checkCookieChanges()
  }, cookieIntervalCheck)
}

export const addAuthCookieRefreshTokenListener = (
  callBackRefreshToken: (token?: AuthToken) => Promise<AuthToken>,
) => {
  const refreshTokenCheck = async () => {
    const token = getAuthTokenHash()
    if (!!token.hash) await callBackRefreshToken(token.authTokenCookie)
  }
  window.setInterval(() => {
    refreshTokenCheck()
  }, cookieRefreshTokenIntervalCheck)
}
