import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { Footer } from '@campgladiator/cgui-core.organisms.footer'
import * as pendo from '../src/pendo'
import { ExternalRedirect } from './common/components/ExternalRedirect'
import { Header } from './common/components/Header'
import { Main } from './common/components/Main'
import useTracing from './common/hooks/useTracing'
import { analytics, analyticsEvent } from './common/utility/analytics'
import configuration from './configuration'
import { OnDemandWorkouts } from './pages/OnDemandWorkouts'
import { OnDemandWorkoutsDetail } from './pages/OnDemandWorkoutsDetail'
import { VirtualWorkouts } from './pages/VirtualWorkouts'
import { authSelector } from './redux/features/Auth/'
import { useAppSelector } from './redux/store'
import { LayoutWithHeaderAndFooter } from './templates/LayoutWithHeaderAndFooter'

const TRAINER_ID = 'trainerId'
const LOGIN_PATH = '/login'
const { trackCustomEvent } = analytics
const { SCREEN_CHANGE } = analyticsEvent
const { cgWebsiteUrl, cgWebsiteLoginUrl } = configuration

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const RoutesWithHeaderAndFooter = () => {
  const { isLoggedIn, user } = useAppSelector(authSelector)
  const { setAnalyticsUserId } = analytics

  useEffect(() => {
    pendo.setUserId(user?.id || 'guest')
  }, [user?.id])

  useEffect(() => {
    setAnalyticsUserId(user?.id || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id])

  return (
    <LayoutWithHeaderAndFooter>
      <Header isLoggedIn={isLoggedIn} user={user} />
      <Main>
        <Switch>
          <Route path="/" exact component={OnDemandWorkouts} />
          <Route
            path="/workout/:workoutId"
            exact
            component={OnDemandWorkoutsDetail}
          />
          <Route path="/virtual-workouts" exact component={VirtualWorkouts} />
          <Route
            path={`/trainer/:${TRAINER_ID}`}
            exact
            component={redirectToNewTrainerPage}
          />
        </Switch>
      </Main>
      <Footer />
    </LayoutWithHeaderAndFooter>
  )
}

const redirectToNewTrainerPage = ({ ...props }) => {
  const trainerId = props.match.params[TRAINER_ID]
  const url = `${cgWebsiteUrl}/trainer/${trainerId}`
  window.location.href = url
  return null
}

const App = () => {
  const location = useLocation()
  const { initTracing } = useTracing()
  initTracing()

  useEffect(() => {
    trackCustomEvent(SCREEN_CHANGE())
  }, [location])

  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route
          path={LOGIN_PATH}
          exact
          render={() => {
            const isLoginCurrentPage = location.pathname === LOGIN_PATH
            const redirectUrl =
              location.search ||
              `?redirectUrl=${
                isLoginCurrentPage
                  ? window.location.origin
                  : window.location.href
              }`
            const url = `${cgWebsiteLoginUrl}${redirectUrl}`
            return <ExternalRedirect to={url} />
          }}
        />
        <Route component={RoutesWithHeaderAndFooter} />
      </Switch>
    </>
  )
}

export default App
