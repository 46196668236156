import { analytics, analyticsEvent, analyticsEventLabel } from './analytics'

const { trackCustomEvent } = analytics
const { BUTTON_CLICK } = analyticsEvent
const {
  COMMON: { FAVORITE_WORKOUT, REMOVE_FAVORITE_WORKOUT },
} = analyticsEventLabel

export const trackFavoriteEvents = (isFavorite: boolean) =>
  trackCustomEvent(
    BUTTON_CLICK(isFavorite ? REMOVE_FAVORITE_WORKOUT : FAVORITE_WORKOUT),
  )
