import {
  TimeUnits,
  getTimeBreakdowns,
  timeTextPluralOrSingular,
} from '../../../../../../../utility/datetimes'

const TimeRemaining = ({ time, unit }: { time: number; unit: TimeUnits }) => {
  return (
    <>
      Workout starts in{' '}
      <strong>
        {time} {timeTextPluralOrSingular({ timeUntilWorkout: time, unit })}
      </strong>
    </>
  )
}

const WorkoutTime = ({ workoutStartDate }: { workoutStartDate: Date }) => {
  const { days, hours, minutes } = getTimeBreakdowns(workoutStartDate)

  return (
    <div>
      {days > 0 ? (
        <TimeRemaining time={days} unit="day" />
      ) : hours >= 1 ? (
        <TimeRemaining time={hours} unit="hour" />
      ) : minutes >= 1 ? (
        <TimeRemaining time={minutes} unit="minute" />
      ) : (
        <>Workout is about to start!</>
      )}
    </div>
  )
}

export default WorkoutTime
