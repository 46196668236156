import classNames from 'classnames'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Workout } from '../../../redux/features/OnDemand/onDemandWorkouts'
import { FilterCard } from './components'
import styles from './Filters.module.scss'
import useFilters from './useFilters'

export type TimeFilterProps = {
  activeDayOfWeek: number
  btnSizeBreakpoint: number
  deviceWidth: number
  filtersApplied: boolean
  filteredNumberOfWorkouts: number
  isLoadingFilters: boolean
  isLoadingWorkouts: boolean
  isFavoriteFilterApplied: boolean
  isSliderFilterLoading: boolean
  onApplyFilterByTimeClick: (
    startTime: string,
    endTime: string,
    tagId: number | null,
  ) => void
  onClearFilterByTimeClick: () => void
  onFilterByTimeSlide: (
    startTime: string,
    endTime: string,
    isFilterVisible: boolean,
    tag: number[],
  ) => void
  onOverlayIsSet: (toBeVisible: boolean) => void
  virtualWorkoutsPath: boolean
  workouts?: Workout[]
}

const Filters = ({
  activeDayOfWeek,
  filtersApplied,
  deviceWidth,
  btnSizeBreakpoint,
  isLoadingFilters,
  isLoadingWorkouts,
  isSliderFilterLoading,
  filteredNumberOfWorkouts,
  virtualWorkoutsPath,
  onOverlayIsSet,
  onApplyFilterByTimeClick,
  onClearFilterByTimeClick,
  onFilterByTimeSlide,
}: TimeFilterProps) => {
  const {
    areFiltersVisible,
    TIME_MAX,
    initialMinValue,
    initialMaxValue,
    filterButtonName,
    startTimeLabelWhenApplied,
    timeFilterRef,
    styleFilterValue,
    handleFiltersBtnClick,
    handleOnSliderChangeValue,
    handleFilterClick,
    handleClearBtnClick,
    handleCloseBtnClick,
    handleSliderInputOnClick,
    handleStyleFilterChange,
    filterStylesIds,
  } = useFilters({
    activeDayOfWeek,
    filteredNumberOfWorkouts,
    virtualWorkoutsPath,
    onApplyFilterByTimeClick,
    onClearFilterByTimeClick,
    onFilterByTimeSlide,
    onOverlayIsSet,
    filtersApplied,
  })

  return (
    <>
      <Button
        className={classNames({
          [styles.activeBtn]: filtersApplied,
        })}
        icon={{ type: 'line', name: 'icon-sliders-v-alt' }}
        emphasis={filtersApplied ? 'primary' : 'secondary-alt'}
        variation="outline"
        size={deviceWidth >= btnSizeBreakpoint ? 'default' : 'small'}
        disabled={isLoadingFilters || isLoadingWorkouts}
        onClick={handleFiltersBtnClick}
      >
        Filters
      </Button>
      {areFiltersVisible && (
        <FilterCard
          ref={timeFilterRef}
          filtersApplied={filtersApplied}
          handleClearBtnClick={handleClearBtnClick}
          handleCloseBtnClick={handleCloseBtnClick}
          startTimeLabelWhenApplied={startTimeLabelWhenApplied}
          handleOnSliderChangeValue={handleOnSliderChangeValue}
          handleStyleFilterChange={handleStyleFilterChange}
          initialMaxValue={initialMaxValue}
          initialMinValue={initialMinValue}
          TIME_MAX={TIME_MAX}
          handleFilterClick={handleFilterClick}
          filterButtonName={filterButtonName}
          isSliderFilterLoading={isSliderFilterLoading}
          handleSliderInputOnClick={handleSliderInputOnClick}
          styleFilterValue={styleFilterValue}
          styleFilterOptions={filterStylesIds}
        />
      )}
    </>
  )
}

export default Filters
