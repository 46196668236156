import { useRef } from 'react'

const useThrottle = () => {
  const throttleSeed = useRef<null | ReturnType<typeof setTimeout>>(null)

  const throttleFunction = useRef((callback: () => void, delay = 200) => {
    if (!throttleSeed.current) {
      callback()
      throttleSeed.current = setTimeout(() => {
        throttleSeed.current = null
      }, delay)
    }
  })

  return throttleFunction.current
}

export default useThrottle
