import { CheckInAlertContentType } from '..'

export const checkInAlertContent: CheckInAlertContentType = {
  checkedIn: {
    icon: { type: 'line', name: 'icon-check' },
    title: 'You have earned a Check-in for this workout!',
    text: 'No need to do anything else. Enjoy your workout!',
    type: 'info',
  },
  duplicate: {
    icon: { type: 'line', name: 'icon-check' },
    title: 'You have earned a Check-in for this workout!',
    text: 'No need to do anything else. Enjoy your workout!',
    type: 'info',
  },
  limit: {
    icon: { type: 'line', name: 'icon-check' },
    title: 'No need to do anything else. Enjoy your workout!',
    text: 'You have reached the check-in limit for today. Way to go!',
    type: 'info',
  },
  notEligible: {
    icon: { type: 'solid', name: 'icon-star' },
    title: 'Not Check-in Eligible',
    text: 'Though your muscles appreciate the movement, workouts less than 30 minutes are not eligible for Camper check-ins. Keep up the great work!',
    type: 'info',
  },
  none: {
    icon: { type: 'solid', name: 'icon-star' },
    title: 'Introducing A New Way to Check-in:',
    text: 'You\'ll receive an automatic check-in for completing majority of the workout. No need to click the "Check-In" button again.',
    type: 'info',
  },
  tokenExpired: {
    icon: { type: 'solid', name: 'icon-star' },
    title: 'Session Expired',
    text: 'Please log in again to get your check-in',
    type: 'info',
  },
}
