import configuration from '../../../configuration'
import { FiltersJsonResponse } from '../../../redux/features/OnDemand/Filters/filters'
import { Tag } from '../../../redux/features/OnDemand/onDemandWorkouts'
import { request } from '../../utility/fetch'

const { onlinePlatformApi } = configuration

const filtersApi = () => {
  const fetchFilters = async (): Promise<Tag[]> => {
    const baseUrl = onlinePlatformApi
    const endpoint = '/workout-library/tags'
    const method = 'GET'
    const response = await request({ baseUrl, endpoint, method })
    const { content, error }: FiltersJsonResponse = await response.json()
    return response.ok ? (content as Tag[]) : Promise.reject(new Error(error))
  }

  return {
    fetchFilters,
  }
}

export default filtersApi
