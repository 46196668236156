import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import styles from './LiveBadge.module.scss'

const LiveBadge = () => (
  <div className={styles.live}>
    <div className={styles.indicator}></div>
    <Paragraph className={styles.text}>Live</Paragraph>
  </div>
)

export default LiveBadge
