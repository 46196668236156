import { handleGatewayAuthErrors } from './interceptor'
import { FetchData, FetchRequest } from './types/fetch'

export const createApiRequestHeaders = (authToken?: string) => {
  const basicHeader = { 'Content-Type': 'application/json' }
  if (!authToken) return basicHeader
  const authHeader = { ...basicHeader, Authorization: `Bearer ${authToken}` }
  return authHeader
}

export const url = (args: FetchRequest): string =>
  `${args.baseUrl + args.endpoint + (args.params || '')}`

export const buildData = ({ method, body, authToken, type }: FetchData) => {
  const safeBody = !!body ? { body } : {}
  const contentType = type ? { 'Content-Type': type } : undefined
  return {
    method,
    headers: {
      ...contentType,
      ...authHeader(authToken),
    },
    ...safeBody,
  }
}
const authHeader = (authToken?: string) =>
  !!authToken ? { Authorization: `Bearer ${authToken}` } : undefined

export const request = async ({
  authToken,
  baseUrl,
  endpoint,
  method,
  params,
  body,
  invalidateUserAccessCallback,
  updateAuthTokenCallback,
  type = 'json',
}: FetchRequest): Promise<Response> => {
  const formFetchData = {
    method,
    body,
    authToken: authToken?.token,
  }

  const jsonFetchData = {
    type: 'application/json',
    ...formFetchData,
  }

  const fetchData = type === 'form' ? formFetchData : jsonFetchData
  const urlRequest = url({ baseUrl, endpoint, params, method })
  const response = await fetch(urlRequest, buildData(fetchData))
  return await handleGatewayAuthErrors({
    response,
    fetchData,
    authToken,
    invalidateUserAccessCallback,
    updateAuthTokenCallback,
  })
}
