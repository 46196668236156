import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { DayOfWeek } from '../../../../../../utility/datetimes'
import styles from './AlternativeResultsBanner.module.scss'

type AlternativeResultsBannerProps = {
  alternativeDays: {
    dayOfWeek: string
    totalElements: number
  }[]
  onDayOfWeekClick?: (dayOfWeek: number) => void
}

const AlternativeResultsBanner = ({
  alternativeDays,
  onDayOfWeekClick,
}: AlternativeResultsBannerProps) => (
  <Paragraph size="large" weight="book" className={styles.alternativeDays}>
    {alternativeDays.length
      ? Object.keys(alternativeDays).map((key, index) => {
          const isLast =
            key ===
            Object.keys(alternativeDays)[
              Object.keys(alternativeDays).length - 1
            ]
          const hasOnlyOne = Object.keys(alternativeDays).length === 1
          const isAnd = isLast && !hasOnlyOne
          const isComma = !isLast && !hasOnlyOne
          const numberOfWorkouts = alternativeDays[index].totalElements
          const dayOfWeek = alternativeDays[index].dayOfWeek
          const dayOfWeekNumber = Object.keys(DayOfWeek).find(
            (key: string) => DayOfWeek[parseInt(key)] === dayOfWeek,
          )
          const numberOfWorkoutsText =
            numberOfWorkouts === 1 ? 'workout' : 'workouts'
          const andText = isAnd ? 'and ' : ''
          const firstText = index === 0 && 'We have'
          return (
            <span key={index}>
              {firstText} {andText}{' '}
              <span className={styles.numberOfWorkouts}>
                {numberOfWorkouts}
              </span>{' '}
              <span className={styles.numberOfWorkouts}>
                {numberOfWorkoutsText}
              </span>{' '}
              on{' '}
              <span
                className={styles.dayOfWeek}
                onClick={() => onDayOfWeekClick?.(parseInt(dayOfWeekNumber!))}
              >
                {dayOfWeek}
              </span>
              {isComma && ', '}
            </span>
          )
        })
      : 'Adjust your filters to find a better option.'}
  </Paragraph>
)

export default AlternativeResultsBanner
