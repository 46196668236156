import { memo, useCallback, useEffect, useRef, useState } from 'react'
import {
  SharedAttributeKey,
  SpanAttribute,
} from '@campgladiator/cg-common.utility.hooks.tracing'
import { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js'
import useTracing from '../../../../common/hooks/useTracing'
import {
  analytics,
  analyticsEvent,
  analyticsEventLabel,
} from '../../../../common/utility/analytics'
import { authSelector } from '../../../../redux/features/Auth'
import { useAppSelector } from '../../../../redux/store'
import { WorkoutVideoPlayerProps } from '../../OnDemandWorkoutsDetail.d'
import { VideoJS } from '../VideoJS'
import { WorkoutVideoPlaceholder } from '../WorkoutVideoPlaceholder'

type WorkoutVideoProps = {
  isLoggedIn: boolean
  hasOnlineAccess?: boolean
  onLoginClick: () => void
} & WorkoutVideoPlayerProps

const { trackCustomEvent } = analytics
const { VIDEO } = analyticsEvent
const {
  WORKOUT_DETAILS: { PLAY, VOLUME_CHANGE, FULL_SCREEN },
} = analyticsEventLabel

const WorkoutVideoWhenLinkProvided = ({
  src,
  onPercentPlayedChange = () => {},
  onVideoEnded = () => {},
  ...props
}: WorkoutVideoPlayerProps) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [hasData, setHasData] = useState(false)
  const [totalVideoDuration, setTotalVideoDuration] = useState(0)
  const [timeElapsed, setTimeElapsed] = useState(0)
  const startTimeRef = useRef(0)
  const pauseTimeRef = useRef(0)
  const playerRef = useRef<VideoJsPlayer | null>(null)
  const spanName = 'workout-video-player'

  const { traceSpan } = useTracing()
  const { user } = useAppSelector(authSelector)

  const videoJsOptions: VideoJsPlayerOptions = {
    controls: true,
    fill: true,
    aspectRatio: '16:9',
    sources: [
      {
        src,
      },
    ],
  }

  const onTrackCustomEvent = (eventType: string) =>
    trackCustomEvent(VIDEO(eventType))

  useEffect(() => {
    if (isPlaying && hasData) {
      const percentPlayed = (timeElapsed / totalVideoDuration) * 100 || 0
      onPercentPlayedChange(percentPlayed)
    }
  }, [
    isPlaying,
    hasData,
    totalVideoDuration,
    onPercentPlayedChange,
    timeElapsed,
  ])

  const getWorkoutVideoSpanAttrs = () => {
    const attrs = Array<SpanAttribute>()
    attrs.push({
      key: 'Workout' as SharedAttributeKey,
      value: props.workoutId.toString(),
    })
    attrs.push({
      key: 'User' as SharedAttributeKey,
      value: user.id!,
    })
    return attrs
  }

  const handleOnPlay = () => {
    traceSpan(
      spanName,
      () => {
        if (pauseTimeRef.current) {
          const pausedDuration = Date.now() - pauseTimeRef.current
          startTimeRef.current += pausedDuration
          pauseTimeRef.current = 0
        }

        onTrackCustomEvent(PLAY)
        setIsPlaying(true)
        onVideoEnded(false)
      },
      getWorkoutVideoSpanAttrs(),
    )
  }

  const handlePause = () => {
    setIsPlaying(false)
    pauseTimeRef.current = Date.now()
  }

  const handleTimeUpdate = useCallback(() => {
    if (startTimeRef.current) {
      const currentTime = Date.now()
      const elapsed = Math.floor((currentTime - startTimeRef.current) / 1000)
      setTimeElapsed(elapsed)
    } else {
      startTimeRef.current = Date.now()
    }
  }, [startTimeRef])

  const handlePlayerReady = (player: VideoJsPlayer) => {
    playerRef.current = player

    player.on('play', handleOnPlay)
    player.on('pause', handlePause)
    player.on('timeupdate', () => handleTimeUpdate())
    player.on('ended', () => onVideoEnded(true))
    player.on('canplay', () => setHasData(true))
    player.on('waiting', () => setHasData(false))
    player.on('loadedmetadata', () => setTotalVideoDuration(player.duration()))
    player.on('volumechange', () => onTrackCustomEvent(VOLUME_CHANGE))
    player.on('fullscreenchange', () => onTrackCustomEvent(FULL_SCREEN))
    player.on('error', (error) => {
      traceSpan(spanName, () => console.error(error), [
        ...getWorkoutVideoSpanAttrs(),
        { key: 'Error' as SharedAttributeKey, value: 'Invalid video source' },
      ])
    })
  }

  return (
    <VideoJS options={videoJsOptions} onReady={handlePlayerReady} {...props} />
  )
}

const WorkoutVideo = ({
  src,
  hasOnlineAccess,
  isLoggedIn,
  onLoginClick,
  onPercentPlayedChange = () => {},
  onVideoEnded = () => {},
  ...props
}: WorkoutVideoProps) => {
  const handleOnPercentPlayedChange = (percentChanged: number) =>
    onPercentPlayedChange(percentChanged)

  return hasOnlineAccess ? (
    <WorkoutVideoWhenLinkProvided
      src={src}
      onPercentPlayedChange={handleOnPercentPlayedChange}
      onVideoEnded={onVideoEnded}
      {...props}
    />
  ) : (
    <WorkoutVideoPlaceholder
      onLoginClick={onLoginClick}
      isLoggedIn={isLoggedIn}
      className={props.className}
      style={props.style}
    />
  )
}

export default memo(WorkoutVideo)
