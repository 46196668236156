export const testIds = {
  HEADER: {
    LOGIN_OR_SIGN_UP_BUTTON: 'login_or_sign_up_button',
    PROFILE_PICTURE_ICON_BUTTON: 'open_profile_menu',
  },
  HOME: {
    PAGE_SUBTITLE: 'home_page_subtitle',
    PAGE_TITLE: 'home_page_title',
  },
  LOGIN: {
    NEXT_BUTTON: 'login_next_button',
    SUBMIT_BUTTON: 'login_submit_button',
  },
  WORKOUT_DETAILS: {
    TRAINER_IMAGE: 'trainer_img',
  },
  WORKOUTS: {
    HIDE_COMPLETED_TOGGLE: 'hide_completed_workouts_toggle',
    OVERLAY: 'overlay',
  },
}

export const testIdAttribute = (label: string) => ({
  'data-test-id': label,
})
