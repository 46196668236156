import { filtersApi } from '../../../../common/networking/filters'
import { AppThunk } from '../../../store'
import {
  getFiltersFailure,
  getFiltersPending,
  getFiltersSuccess,
  setActiveFilters,
  setFavoriteFilter,
  setTimeFilter,
} from '.'

const { fetchFilters } = filtersApi()

export const getFilters = (): AppThunk => async (dispatch) => {
  dispatch(getFiltersPending(true))
  try {
    const response = await fetchFilters()
    dispatch(getFiltersSuccess(response))
  } catch (error: any) {
    dispatch(getFiltersFailure(error.toString()))
  }
}

export const setFilters =
  (tagFilters: number[], hideCompleted: boolean): AppThunk =>
  (dispatch) => {
    dispatch(setActiveFilters({ tags: tagFilters, hideCompleted }))
  }

export const setFavorite =
  (filterState: boolean): AppThunk =>
  (dispatch) => {
    dispatch(setFavoriteFilter(filterState))
  }

export const setTime =
  (startTime: string, endTime: string): AppThunk =>
  (dispatch) => {
    dispatch(setTimeFilter({ startTime: startTime, endTime: endTime }))
  }
