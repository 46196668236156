import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import { WorkoutCard } from '../../../../common/components/WorkoutCard'
import { AuthToken, KeanuUser } from '../../../../redux/features/Auth/auth'
import { Workout } from '../../../../redux/features/OnDemand/onDemandWorkouts'
import { JoinLiveClickArgs } from '../../../OnDemandWorkouts/OnDemandWorkouts.d'
import styles from './RejoinVirtualCard.module.scss'

type RejoinVirtualCardProps = {
  checkedInWorkout?: number[]
  hasJoinedWorkout: boolean
  isCheckinLoading: boolean
  isLoadingFavorite: boolean
  isLoggedIn: boolean
  onCheckInClick?: (userId: string, workoutId: number, token: AuthToken) => void
  onFavoriteClick: (workoutId: number, isFavorite: boolean) => void
  onJoinLiveClick: ({ workout, target }: JoinLiveClickArgs) => void
  selectedVirtualWorkout: Workout
  token?: AuthToken
  user: KeanuUser
}

const RejoinVirtualCard = ({
  onFavoriteClick,
  onJoinLiveClick = () => {},
  hasJoinedWorkout = false,
  isLoadingFavorite,
  isLoggedIn,
  selectedVirtualWorkout,
  user = {},
  isCheckinLoading,
  onCheckInClick,
  checkedInWorkout = [],
  token,
}: RejoinVirtualCardProps) => (
  <div className={styles.rejoinRow}>
    <div className={styles.rejoinColumn}>
      <Heading type="h6">Need to re-join?</Heading>
      <WorkoutCard.Virtual
        isLoadingFavorite={isLoadingFavorite}
        isLoggedIn={isLoggedIn}
        onFavoriteClick={onFavoriteClick}
        onJoinLiveClick={onJoinLiveClick}
        hasJoinedWorkout={hasJoinedWorkout}
        workout={selectedVirtualWorkout as Workout}
        user={user}
        isCheckinLoading={isCheckinLoading}
        onCheckInClick={onCheckInClick}
        checkedInWorkout={checkedInWorkout}
        token={token}
      />
    </div>
  </div>
)

export default RejoinVirtualCard
