import classNames from 'classnames'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { btnSizeBreakpoint } from '../../../../common/utility/resolutionVariables'
import { FavoritesFilterProps } from '../../OnDemandWorkouts.d'
import styles from './FavoritesFilter.module.scss'

const FavoritesFilter = ({
  className = '',
  hasFavoriteFilter = false,
  isLoadingWorkouts,
  onGetFavoriteWorkouts,
  deviceWidth,
}: FavoritesFilterProps) => (
  <Button
    className={classNames(className, {
      [styles.activeBtn]: hasFavoriteFilter,
    })}
    emphasis={hasFavoriteFilter ? 'primary' : 'secondary-alt'}
    variation="outline"
    size={deviceWidth >= btnSizeBreakpoint ? 'default' : 'small'}
    icon={{ type: 'line', name: 'icon-heart' }}
    onClick={(e) => {
      e.stopPropagation()
      onGetFavoriteWorkouts()
    }}
    disabled={isLoadingWorkouts}
  >
    Favorites
  </Button>
)

export default FavoritesFilter
