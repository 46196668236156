import { useState } from 'react'
import moment from 'moment'
import configuration from '../../../../../../../configuration'
import { CardPartProps } from '../../../../../../../pages/OnDemandWorkouts/OnDemandWorkouts.d'
import { formatMeridianWithLocalTZ } from '../../../../../../utility/dates'

type UseVirtual = Pick<
  CardPartProps,
  | 'user'
  | 'hasJoinedWorkout'
  | 'isCheckinLoading'
  | 'checkedInWorkout'
  | 'isLoggedIn'
  | 'token'
  | 'onCheckInClick'
  | 'onJoinLiveClick'
  | 'workout'
>

const { cgWebsiteRegisterUrl } = configuration

const useVirtual = ({
  user,
  hasJoinedWorkout,
  isCheckinLoading,
  checkedInWorkout,
  isLoggedIn,
  token,
  workout,
  onCheckInClick,
  onJoinLiveClick = () => {},
}: UseVirtual) => {
  const [checkingInWorkoutId, setCheckingInWorkoutId] = useState<number>(0)
  const { isLive, link } = workout
  const workoutStartDate = moment(workout.startTime).local().toDate()
  const workoutStartHour = formatMeridianWithLocalTZ(workoutStartDate)
  const joinLiveButtonText = hasJoinedWorkout ? 're-join live' : 'join live'
  const isCheckingIn = isCheckinLoading && checkingInWorkoutId === workout.id
  const hasCheckedIn = checkedInWorkout && checkedInWorkout.includes(workout.id)
  const checkInButtonText = isCheckingIn
    ? 'checking in ...'
    : hasCheckedIn
    ? 'checked in'
    : 'check in'
  const userLoggedInWithVirtualAccess = isLoggedIn && user?.hasOnlineAccess
  const userLoggedInWithNoVirtualAccess = isLoggedIn && !user?.hasOnlineAccess
  const shouldShowWorkoutLink = isLive && userLoggedInWithVirtualAccess
  const targetBasedOnIsWorkoutLive = shouldShowWorkoutLink ? '_blank' : '_self'
  const workoutLinkIfIsLive = shouldShowWorkoutLink ? link : ''

  const onWorkoutClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    shouldShowWorkoutLink &&
      onJoinLiveClick({ target: targetBasedOnIsWorkoutLive, workout })
  }

  const handleCheckinClick = async () => {
    setCheckingInWorkoutId(workout.id)
    await onCheckInClick?.(user?.id!, workout.id, token!)
    setCheckingInWorkoutId(0)
  }

  const handleRegisterClick = () => {
    window.open(cgWebsiteRegisterUrl, '_blank')
  }

  return {
    workoutLinkIfIsLive,
    onWorkoutClick,
    targetBasedOnIsWorkoutLive,
    workoutStartHour,
    isLive,
    userLoggedInWithVirtualAccess,
    handleCheckinClick,
    hasCheckedIn,
    checkInButtonText,
    joinLiveButtonText,
    userLoggedInWithNoVirtualAccess,
    handleRegisterClick,
    workoutStartDate,
  }
}

export default useVirtual
