import { useCallback, useEffect, useRef, useState } from 'react'
import { useDeviceWidth, useScrolledToRefBottom } from '../../common/hooks'
import {
  analytics,
  analyticsEvent,
  analyticsEventLabel,
} from '../../common/utility/analytics'
import { trackFavoriteEvents } from '../../common/utility/commonAnalytics'
import { testIdAttribute, testIds } from '../../common/utility/labels'
import { authSelector } from '../../redux/features/Auth'
import {
  favorite,
  favoriteSelector,
} from '../../redux/features/OnDemand/Favorite'
import {
  filtersSelector,
  getFilters,
  setFavorite,
  setFilters,
} from '../../redux/features/OnDemand/Filters'
import {
  getWorkouts,
  workoutsReset,
  workoutsSelector,
} from '../../redux/features/OnDemand/Workouts'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { useFilters } from './components/Filters'

const workoutsGridLoadNewPageOffsetInPx = -100
const overlayTestId = testIdAttribute(testIds.WORKOUTS.OVERLAY)

const { trackCustomEvent } = analytics
const { BUTTON_CLICK } = analyticsEvent
const {
  COMMON: { FAVORITES },
} = analyticsEventLabel

const useOnDemandWorkouts = () => {
  const [isPageInitialized, setIsPageInitialized] = useState(false)
  const [isOverlayOn, setIsOverlayOn] = useState(false)
  const [trainerId, setTrainerId] = useState<string>('')

  const deviceWidth = useDeviceWidth()

  const workoutsGridRef = useRef<HTMLDivElement>(null)

  const dispatch = useAppDispatch()
  const allFavorites = useAppSelector(favoriteSelector.selectEntities)
  const { isLoggedIn } = useAppSelector(authSelector)
  const {
    filters,
    tagFilters: tagFiltersApplied,
    favoriteFilter: isFavoriteFilterApplied,
    hideCompletedFilter: isHideCompletedFilterApplied,
    isLoading: isLoadingFilters,
  } = useAppSelector(filtersSelector)
  const {
    isLoading: isLoadingWorkouts,
    lastPageFetched,
    totalNumberOfPages,
    totalNumberOfWorkouts,
    workouts,
  } = useAppSelector(workoutsSelector)

  const scrolledToWorkoutsGridBottom = useScrolledToRefBottom(
    workoutsGridRef,
    workoutsGridLoadNewPageOffsetInPx,
  )

  const isMorePagesToFetch =
    (typeof lastPageFetched === 'undefined' ? -1 : lastPageFetched) <
    totalNumberOfPages - 1

  const handleOnGetFilteredWorkouts = (
    tagFilterIds: number[],
    hideCompleted: boolean,
  ) => {
    dispatch(workoutsReset())
    dispatch(setFilters(tagFilterIds, hideCompleted))
  }

  const handleOnFavoriteClick = (workoutId: number, isFavorite: boolean) => {
    const favoriteParams = { workoutId, isFavorite }
    trackFavoriteEvents(isFavorite)
    dispatch(favorite(favoriteParams))
  }

  const handleOnGetFavoritesWorkouts = () => {
    dispatch(workoutsReset())
    dispatch(setFavorite(!isFavoriteFilterApplied))
    trackCustomEvent(BUTTON_CLICK(FAVORITES))
  }

  const loadMoreWorkouts = useCallback(() => {
    !isLoadingWorkouts &&
      isMorePagesToFetch &&
      dispatch(getWorkouts({ trainerId }))
  }, [dispatch, isLoadingWorkouts, isMorePagesToFetch, trainerId])

  const onClearTrainerSearch = () => {
    setTrainerId('')
  }

  const filterProps = useFilters({
    deviceWidth,
    filters,
    isFavoriteFilterApplied,
    isHideCompletedFilterApplied,
    isLoadingFilters,
    isLoadingWorkouts,
    isLoggedIn,
    tagFiltersApplied,
    onGetFilteredWorkouts: handleOnGetFilteredWorkouts,
    onGetFavoriteWorkouts: handleOnGetFavoritesWorkouts,
    setIsOverlayOn,
  })

  useEffect(() => {
    dispatch(workoutsReset())
    dispatch(getWorkouts({ trainerId }))
  }, [
    dispatch,
    tagFiltersApplied,
    isFavoriteFilterApplied,
    isHideCompletedFilterApplied,
    trainerId,
  ])

  useEffect(() => {
    dispatch(getFilters())
    setIsPageInitialized(true)
  }, [dispatch])

  useEffect(() => {
    scrolledToWorkoutsGridBottom && loadMoreWorkouts()
  }, [scrolledToWorkoutsGridBottom, loadMoreWorkouts])

  const onDemandWorkoutProps = {
    isLoggedIn,
    isPageInitialized,
    filters,
    isLoadingFilters,
    totalNumberOfWorkouts,
    workouts,
    allFavorites,
    deviceWidth,
    isOverlayOn,
    filterProps,
    tagFiltersApplied,
    isLoadingWorkouts,
    isFavoriteFilterApplied,
    isHideCompletedFilterApplied,
    workoutsGridRef,
    overlayTestId,
    onClearTrainerSearch,
    setIsOverlayOn,
    setTrainerId,
    handleOnGetFilteredWorkouts,
    handleOnFavoriteClick,
    handleOnGetFavoritesWorkouts,
  }

  return onDemandWorkoutProps
}

export default useOnDemandWorkouts
