import { Button } from '@campgladiator/cgui-core.atoms.button'
import { TagsProps } from '../../../../../pages/OnDemandWorkouts/OnDemandWorkouts.d'
import styles from './Tags.module.scss'

const Tags = ({ items }: TagsProps) => (
  <div className={styles.tags}>
    {items.map((tag, index) => (
      <Button
        key={index}
        className={tag.tagType === 'ONLINE' ? styles.onlineTag : styles.tag}
        size="xsmall"
        emphasis="secondary-alt"
        variation="outline"
        disabled
      >
        {tag.title}
      </Button>
    ))}
  </div>
)

export default Tags
