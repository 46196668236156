import configuration from '../../../configuration'
import { request } from '../../utility/fetch'

const { apiGatewayUrl } = configuration

export type Trainer = {
  archived: boolean | null
  approvedPrograms: {
    onlineApproved: boolean
  }
  firstName: string
  id: string
  lastName: string
  type: 'SERVICING' | 'SELLING'
}

type TrainerJsonResponse = {
  content?: Trainer[]
  error?: any
}

const trainersApi = () => {
  const fetchTrainerIdByName = async ({
    trainerName,
    size = 100,
  }: {
    trainerName: string
    size?: number
  }): Promise<Trainer[]> => {
    const baseUrl = apiGatewayUrl
    const endpoint = `/trainer/v1/trainers?includeDeleted=false&name=${trainerName}&page=0&size=${size}`
    const method = 'GET'
    const response = await request({ baseUrl, endpoint, method })
    const { content, error }: TrainerJsonResponse = await response.json()
    return response.ok
      ? (content as Trainer[])
      : Promise.reject(new Error(error))
  }
  return { fetchTrainerIdByName }
}

export default trainersApi
