import { memo } from 'react'
import classNames from 'classnames'
import { Button } from '@campgladiator/cgui-core.atoms.button'
import { Heading } from '@campgladiator/cgui-core.atoms.typography'
import { useDeviceWidth } from '../../../../common/hooks'
import {
  analytics,
  analyticsEvent,
  analyticsEventLabel,
} from '../../../../common/utility/analytics'
import configuration from '../../../../configuration'
import { WorkoutVideoPlaceholderProps } from '../../OnDemandWorkoutsDetail.d'
import styles from './WorkoutVideoPlaceholder.module.scss'

const { cgWebsiteRegisterUrl } = configuration
const { trackCustomEvent } = analytics
const { BUTTON_CLICK } = analyticsEvent
const {
  WORKOUT_DETAILS: { LOGIN_OR_SIGN_UP_TO_WORK_OUT, UPGRADE_TO_JOIN },
} = analyticsEventLabel

const pickBtnSizeBasedOnWindowWidth = (width: number) =>
  width > 1200 ? 'large' : width > 576 ? 'default' : 'xsmall'

const WorkoutVideoPlaceholder = ({
  className = '',
  isLoggedIn,
  onLoginClick,
  ...props
}: WorkoutVideoPlaceholderProps) => {
  const deviceWidth = useDeviceWidth()

  const heading = isLoggedIn
    ? 'Oops, looks like you are not eligible for On-Demand workouts.'
    : 'Oops, look like you are not currently signed in.'
  const buttonText = isLoggedIn
    ? 'Upgrade to Join'
    : 'Login or Sign Up to Work Out'

  const handleOnLoginClick = () => {
    if (isLoggedIn) {
      trackCustomEvent(BUTTON_CLICK(UPGRADE_TO_JOIN))
      window.open(cgWebsiteRegisterUrl, '_blank')
    } else {
      trackCustomEvent(BUTTON_CLICK(LOGIN_OR_SIGN_UP_TO_WORK_OUT))
      onLoginClick()
    }
  }

  return (
    <div {...props} className={classNames(styles.wrapper, className)}>
      <div className={styles.placeholder}>
        <div className={styles.content}>
          <Heading className={styles.info} type="h4">
            {heading}
          </Heading>
          <Button
            size={pickBtnSizeBasedOnWindowWidth(deviceWidth)}
            onClick={handleOnLoginClick}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default memo(WorkoutVideoPlaceholder)
