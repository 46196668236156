import { useEffect, useRef } from 'react'
import classNames from 'classnames'
import videojs, { VideoJsPlayer } from 'video.js'
import 'video.js/dist/video-js.css'
import { VideoJSProps } from '../../OnDemandWorkoutsDetail.d'
import './VideoJS.scss'

const VideoJS = ({
  options = {},
  onReady = () => {},
  className = '',
  children,
  ...props
}: VideoJSProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const playerRef = useRef<VideoJsPlayer | null>(null)

  useEffect(() => {
    if (!playerRef.current && !!videoRef.current) {
      const videoElement = videoRef.current
      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player)
      }))
    }
  }, [options, videoRef, onReady])

  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  return (
    <div data-vjs-player>
      <video
        ref={videoRef}
        className={classNames('video-js', 'vjs-big-play-centered', className)}
        data-setup='{"controlBar": {"pictureInPictureToggle": false}}'
        playsInline
        {...props}
      />
      {children}
    </div>
  )
}

export default VideoJS
