import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ListMenuProps } from '@campgladiator/cgui-core.molecules.list-menu'
import { SelectedRecord } from '@campgladiator/cgui-core.molecules.select'
import { debounce } from 'lodash'
import { findMatchingTrainers } from '../../../pages/VirtualWorkouts/logic/filter'
import { Trainer, trainersApi } from '../../networking/trainers'
import {
  analytics,
  analyticsEvent,
  analyticsEventLabel,
} from '../../utility/analytics'

type UseSearchFieldArgs = {
  onItemSelect: (id: string) => void
  onClear: () => void
}

const { fetchTrainerIdByName } = trainersApi()
const noRecordsFoundItem: ListMenuProps['items'] = [
  {
    type: 'item',
    itemId: '0',
    text: 'No results found',
    active: false,
    disablePointer: true,
  },
]

const { trackCustomEvent } = analytics
const { INPUT } = analyticsEvent
const {
  WORKOUTS: { TRAINER_SELECTED },
} = analyticsEventLabel

const useSearchField = ({ onItemSelect, onClear }: UseSearchFieldArgs) => {
  const searchFieldRef = useRef<HTMLInputElement>(null)
  const [filteredPredictions, setFilteredPredictions] = useState<
    ListMenuProps['items']
  >([])
  const [userInput, setUserInput] = useState<string>('')
  const [selectedTrainerId, setSelectedTrainerId] = useState<string>('')
  const [trainers, setTrainers] = useState<Trainer[]>([])

  const searchTrainers = useCallback(() => {
    fetchTrainerIdByName({ trainerName: userInput }).then((trainers) =>
      setTrainers(findMatchingTrainers(trainers)),
    )
  }, [userInput])

  const searchWithDebounce = useMemo(
    () => debounce(searchTrainers, 500),
    [searchTrainers],
  )

  const handleOnChange = (value: string) => setUserInput(value)

  const handleOnOptionSelect = async (record: SelectedRecord) => {
    const { itemId, children: trainerName } = record
    onItemSelect(itemId)
    const inputEventLabel = `${TRAINER_SELECTED} - ${trainerName}`
    trackCustomEvent(INPUT(inputEventLabel))
  }

  const handleOnCancelIconClick = useCallback(() => {
    setTrainers([])
    setSelectedTrainerId('')
    setFilteredPredictions([])
    setUserInput('')
    onItemSelect('')
    onClear()
  }, [onClear, onItemSelect])

  const formatClickableMenuItem = (record: SelectedRecord) => {
    setFilteredPredictions([])
    setUserInput((record.children as string) || '')
    setSelectedTrainerId(record.itemId)
    handleOnOptionSelect(record)
  }

  useEffect(() => {
    if (filteredPredictions.length > 0) return
    setFilteredPredictions(noRecordsFoundItem)
  }, [filteredPredictions])

  useEffect(() => {
    setFilteredPredictions(
      trainers.map((trainer) => {
        return {
          type: 'item',
          text: `${trainer.firstName} ${trainer.lastName}`,
          itemId: trainer.id,
        }
      }),
    )
  }, [trainers])

  useEffect(() => {
    if (userInput.length > 2) {
      searchWithDebounce()
    }
    return searchWithDebounce.cancel
  }, [userInput, searchWithDebounce, handleOnCancelIconClick])

  return {
    filteredPredictions,
    searchFieldRef,
    selectedTrainerId,
    trainers,
    formatClickableMenuItem,
    handleOnCancelIconClick,
    handleOnChange,
  }
}

export default useSearchField
