const { NODE_ENV } = process.env

const isDevelopment = NODE_ENV === 'development'

const log = (...args: string[]): any => isDevelopment && console.log(...args)

const warn = (...args: string[]): any => isDevelopment && console.warn(...args)

const error = (...args: string[]): any =>
  isDevelopment && console.error(...args)

const logger = {
  log,
  warn,
  error,
}

export default logger
