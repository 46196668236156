import moment from 'moment'
import configuration from '../../../../configuration'
import { getNextDateByDayOfWeekWithLocalTZ } from '../../../utility/dates'
import { WorkoutApiParams } from '../../workouts'

const { workoutsPageSize } = configuration

const setToStartOfDay = (date: moment.Moment) => {
  return date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
}

export const parseParameters = (workoutApiParams: WorkoutApiParams) => {
  const params = new URLSearchParams()

  params.append(
    'type',
    workoutApiParams.isOnDemandFilter ? 'ONDEMAND' : 'ONLINE',
  )

  const pageParam = workoutApiParams.page || 0
  const sizeParam = workoutApiParams.size || workoutsPageSize

  params.append('size', sizeParam.toString())
  params.append('page', pageParam.toString())

  if (workoutApiParams.tagFilters.length > 0) {
    params.append('tags', workoutApiParams.tagFilters.join(','))
  }

  if (workoutApiParams.favoriteFilter) {
    params.append('favorite', 'true')
  }

  if (workoutApiParams.hideCompletedFilter) {
    params.append('checkedIn', 'false')
  }

  params.append('trainerId', workoutApiParams.trainerId ?? '')

  if (workoutApiParams.timeFilter) {
    params.append('startTime', workoutApiParams.startTimeFilter || '')
    params.append('endTime', workoutApiParams.endTimeFilter || '')
  } else if (
    workoutApiParams.dayOfWeekFilter !== undefined &&
    workoutApiParams.dayOfWeekFilter >= 0
  ) {
    const nextDateOfWeek = getNextDateByDayOfWeekWithLocalTZ(
      workoutApiParams.dayOfWeekFilter,
    )
    const nextDateOfWeekNextDay = moment
      .utc(nextDateOfWeek)
      .local()
      .add(1, 'day')

    setToStartOfDay(nextDateOfWeek)
    setToStartOfDay(nextDateOfWeekNextDay)

    params.append('startTime', nextDateOfWeek.format())
    params.append('endTime', nextDateOfWeekNextDay.format())
  }

  params.append(
    'includeDeleted',
    workoutApiParams.isOnDemandFilter ? 'true' : 'false',
  )

  return `?${params.toString()}`
}
