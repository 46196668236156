import { memo } from 'react'
import classNames from 'classnames'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import * as grid from '@campgladiator/cgui-core.styles.helpers.grid'
import {
  TextSizesModel,
  WorkoutHighlightsAndOverviewProps,
  WorkoutOverviewProps,
} from '../../OnDemandWorkoutsDetail.d'
import { WorkoutHighlights } from '../WorkoutHighlights'
import styles from './WorkoutHighlightsAndOverview.module.scss'

const pickTextSizesBasedOnWindowWidth = (width: number): TextSizesModel =>
  width > 576
    ? { title: 'default', text: 'small' }
    : { title: 'small', text: 'xsmall' }

const WorkoutOverview = ({ overview, textSizes }: WorkoutOverviewProps) => (
  <>
    <Heading className={styles.heading} type="h5">
      Overview
    </Heading>
    <Paragraph size={textSizes.text} weight="book">
      {overview}
    </Paragraph>
  </>
)

const WorkoutHighlightsAndOverview = ({
  workout,
  deviceWidth,
}: WorkoutHighlightsAndOverviewProps) => (
  <div className={classNames(grid.row, styles.content)}>
    <div className={classNames(grid.col12, grid.colMd6)}>
      <WorkoutHighlights
        textSizes={pickTextSizesBasedOnWindowWidth(deviceWidth)}
        workout={workout}
      />
    </div>
    {workout.description && (
      <div className={classNames(grid.col12, grid.colMd6)}>
        <WorkoutOverview
          textSizes={pickTextSizesBasedOnWindowWidth(deviceWidth)}
          overview={workout.description}
        />
      </div>
    )}
  </div>
)

export default memo(WorkoutHighlightsAndOverview)
