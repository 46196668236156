import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import { FavoriteState } from '.'

const favoriteAdapter = createEntityAdapter<FavoriteState>({
  selectId: (favorite) => favorite.workoutId,
})

export const favoriteSlice = createSlice({
  name: 'favorite',
  initialState: favoriteAdapter.getInitialState(),
  reducers: {
    favoritePending: (state, action) => {
      favoriteAdapter.setOne(state, action.payload)
    },
    favoriteAdded: (state, action) => {
      favoriteAdapter.setOne(state, action.payload)
    },
    favoriteRemoved: (state, action) => {
      favoriteAdapter.removeOne(state, action.payload)
    },
    favoriteFailure: (state, action) => {
      favoriteAdapter.setOne(state, action.payload)
    },
  },
})

export const {
  favoritePending,
  favoriteAdded,
  favoriteRemoved,
  favoriteFailure,
} = favoriteSlice.actions

export const favoriteSelector = favoriteAdapter.getSelectors<RootState>(
  (state) => state.favorite,
)

export default favoriteSlice.reducer
