import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Avatar } from '@campgladiator/cgui-core.atoms.avatar'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Logo } from '@campgladiator/cgui-core.atoms.logo'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { CardPartProps } from '../../../../../pages/OnDemandWorkouts/OnDemandWorkouts.d'
import { trainerFullName } from '../../../../utility/trainer'
import { PlayIconOverlay } from '../PlayIconOverlay'
import styles from './UpperPartCard.module.scss'

const UpperPartCard = ({ children }: { children: ReactNode }) => <>{children}</>

const Ondemand = ({ workout, redirectRoute }: CardPartProps) => {
  const trainerName = workout.trainer ? trainerFullName(workout.trainer) : null
  return (
    <UpperPartCard>
      <Link
        className={styles.blockLink}
        to={{
          pathname: redirectRoute!(workout),
        }}
      >
        <div
          className={styles.upperPart}
          style={{
            backgroundImage: `url(${workout.photo ?? workout.trainer?.photo})`,
          }}
        >
          {workout.trainer && trainerName ? (
            <div className={styles.detailsWrapper}>
              <div className={styles.details}>
                <div className={styles.trainerInfo}>
                  <Avatar
                    alt={trainerName}
                    size="40px"
                    src={workout.trainer.photo}
                  />
                  <div className={styles.nameWrapper}>
                    <Paragraph size="xsmall" weight="book">
                      Trainer
                    </Paragraph>
                    <Paragraph size="small" weight="bold">
                      {trainerName}
                    </Paragraph>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <PlayIconOverlay />
        </div>
      </Link>
    </UpperPartCard>
  )
}

const Virtual = ({
  workout,
  isLoggedIn,
  onJoinLiveClick = () => {},
  user = {},
}: CardPartProps) => {
  const { link, isLive, trainer, duration } = workout
  const { hasOnlineAccess } = user
  const hasTrainerPhoto = !!trainer?.photo
  const trainerPhotoBackgroundStyle = {
    backgroundImage: `url(${trainer?.photo})`,
  }
  const cardBackground = hasTrainerPhoto
    ? trainerPhotoBackgroundStyle
    : undefined
  const trainerName = trainer ? trainerFullName(trainer) : null
  const shouldShowWorkoutLink = isLoggedIn && isLive && hasOnlineAccess

  const targetBasedOnIsWorkoutLive = shouldShowWorkoutLink ? '_blank' : '_self'
  const workoutLinkIfIsLive = shouldShowWorkoutLink ? link : ''

  const onWorkoutClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    shouldShowWorkoutLink &&
      onJoinLiveClick({ target: targetBasedOnIsWorkoutLive, workout })
  }

  return (
    <UpperPartCard>
      <a
        className={styles.blockLink}
        href={workoutLinkIfIsLive}
        onClick={onWorkoutClick}
        target={targetBasedOnIsWorkoutLive}
        rel="noreferrer"
      >
        <div className={styles.upperPart} style={cardBackground}>
          {!hasTrainerPhoto && (
            <Logo
              type="icon"
              iconType="white"
              width="100px"
              className={styles.placeholderLogo}
            />
          )}
          {trainer && trainerName ? (
            <div className={styles.detailsWrapper}>
              <div className={styles.details}>
                <div className={styles.trainerInfo}>
                  <div className={styles.nameWrapper}>
                    <Paragraph size="small" weight="bold">
                      {trainerName}
                    </Paragraph>
                    {trainer.localeSummary?.regionName && (
                      <div className={styles.region}>
                        <Icon.Line
                          name="icon-location-point"
                          className={styles.icon}
                        />
                        <Paragraph size="xsmall" weight="book">
                          {trainer.localeSummary?.regionName}
                        </Paragraph>
                      </div>
                    )}
                  </div>
                </div>
                {duration && (
                  <div className={styles.duration}>
                    <Paragraph size="tiny" weight="bold">
                      {duration} min
                    </Paragraph>
                  </div>
                )}
              </div>
            </div>
          ) : null}
          <PlayIconOverlay />
        </div>
      </a>
    </UpperPartCard>
  )
}

export default { UpperPartCard, Ondemand, Virtual }
