type CheckInErrorsType = {
  alreadyCheckedInToday: string
  checkInLimitReached: string
  tokenExpired: string
}

export const checkInErrors: CheckInErrorsType = {
  alreadyCheckedInToday:
    "You've already Checked-in to this workout today. We'll see you again tomorrow!",
  checkInLimitReached:
    "You've Reached Your Daily Check-In Limit. We'll see you again tomorrow!",
  tokenExpired: 'validateAccess.arg1.token: must not be blank',
}
