import * as useCgCommonTracing from '@campgladiator/cg-common.utility.hooks.tracing'
import configuration from '../../configuration'

const useTracing = () => {
  const { otelUrl, isOtelActive } = configuration

  const { initTracing, traceSpan } = useCgCommonTracing.useTracing({
    serviceName: 'ondemand-frontend',
    enableTracing: isOtelActive,
    otelUrl: otelUrl,
  })

  return { initTracing, traceSpan }
}
export default useTracing
