import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import { Workout } from '../onDemandWorkouts'
import { WorkoutState } from '.'

export const initialState: WorkoutState = {
  error: '',
  isLoading: false,
  selectedWorkout: {} as Workout,
}

export const selectedWorkoutSlice = createSlice({
  name: 'selectedWorkouts',
  initialState,
  reducers: {
    getWorkoutPending: (
      state: WorkoutState,
      action: PayloadAction<boolean>,
    ) => {
      state.isLoading = action.payload
      state.selectedWorkout = initialState.selectedWorkout
    },
    getWorkoutSuccess: (
      state: WorkoutState,
      action: PayloadAction<Workout>,
    ) => {
      state.selectedWorkout = action.payload
      state.error = initialState.error
      state.isLoading = initialState.isLoading
    },
    getWorkoutFailure: (
      state: WorkoutState,
      action: PayloadAction<WorkoutState['error']>,
    ) => {
      state.error = action.payload
      state.selectedWorkout = initialState.selectedWorkout
    },
    selectedWorkoutReset: () => initialState,
    selectedWorkoutFavoriteUpdate: (state: WorkoutState) => {
      state.selectedWorkout.favorite = !state.selectedWorkout.favorite
    },
  },
})

export const {
  getWorkoutPending,
  getWorkoutSuccess,
  getWorkoutFailure,
  selectedWorkoutFavoriteUpdate,
  selectedWorkoutReset,
} = selectedWorkoutSlice.actions

export const selectedWorkoutSelector = (state: RootState) =>
  state.selectedWorkout

export default selectedWorkoutSlice.reducer
