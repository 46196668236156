import { Avatar } from '@campgladiator/cgui-core.atoms.avatar'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { testIdAttribute, testIds } from '../../../../common/utility/labels'
import { trainerFullName } from '../../../../common/utility/trainer'
import { WorkoutTrainerProps } from '../../OnDemandWorkoutsDetail.d'
import styles from './WorkoutTrainer.module.scss'

const WorkoutTrainer = ({ trainer, onTrainerClick }: WorkoutTrainerProps) => {
  const trainerName = trainerFullName(trainer)
  const avatarTestId = testIdAttribute(testIds.WORKOUT_DETAILS.TRAINER_IMAGE)

  return (
    <div className={styles.trainerInfo}>
      <div className={styles.blockLink} onClick={() => onTrainerClick()}>
        <Avatar src={trainer.photo} {...avatarTestId} />
        <div className={styles.meta}>
          <Paragraph size="xsmall" weight="book">
            Trainer
          </Paragraph>
          {trainerName ? (
            <Paragraph
              size="small"
              weight="bold"
              className={styles.trainerName}
            >
              {trainerName}
            </Paragraph>
          ) : null}
        </div>
        <Icon.Line name="icon-angle-right-b" className={styles.arrow} />
      </div>
    </div>
  )
}

export default WorkoutTrainer
