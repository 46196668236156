import amplitude from 'amplitude-js'
import configuration from '../../../configuration'
import store from '../../../redux/store'
import logger from '../logger'

const { amplitudeApiKey } = configuration

type EventData = {
  Event: string
  Label?: string
  Screen: string
}

let amplitudeInstance = amplitude.getInstance()

amplitudeInstance.init(amplitudeApiKey)

const trackUserProperty = () => {
  const userProperty = new amplitude.Identify().set(
    'URL Domain',
    window.location.host,
  )
  amplitudeInstance.identify(userProperty)
}

const setAnalyticsUserId = (userId?: string) => {
  amplitudeInstance = amplitude.getInstance()
  amplitudeInstance?.setUserId(userId || null)
}

const waitForUserId = async () => {
  let userId = store.getState().auth.user?.id
  let attempts = 0

  while (!userId && attempts < 5) {
    await new Promise((resolve) => setTimeout(resolve, 1000))
    userId = store.getState().auth.user?.id
    attempts++
  }
}

const trackCustomEvent = async (eventData: EventData) => {
  const { Event, ...rest } = eventData

  if (!Event) {
    return logger.error(`Invalid Custom Event payload: { eventName: ${Event} }`)
  }
  await waitForUserId()
  trackUserProperty()
  logger.log(JSON.stringify(eventData))
  amplitudeInstance.logEvent(Event, rest)
}

const analytics = {
  trackCustomEvent,
  setAnalyticsUserId,
}

export default analytics
