import configuration from '../../configuration'
import { authApi } from '../networking/auth'
import { authBasePath } from '../networking/auth/api'
import { setAuthToken } from './authTokenStorage'
import { buildData } from './fetch'
import {
  GatewayAuthErrors,
  RefreshAuthTokenAndRetryFetch,
} from './types/interceptor'

const { apiGatewayUrl } = configuration

export const handleGatewayAuthErrors = async ({
  response,
  fetchData,
  authToken,
  invalidateUserAccessCallback = () => {},
  updateAuthTokenCallback = () => {},
}: GatewayAuthErrors) => {
  const isAuthError = response.status === 401
  const isClientError = response.status >= 400 && response.status < 500
  const isGatewayRequest =
    typeof response.url === 'string' && response.url.includes(apiGatewayUrl)
  const isAuthEndpoint = response.url.includes(authBasePath)

  if (isGatewayRequest && isClientError && isAuthEndpoint) {
    invalidateUserAccessCallback('Session expired')
    return Promise.reject(response)
  } else if (isGatewayRequest && isAuthError && !isAuthEndpoint && authToken) {
    try {
      return await refreshAuthTokenAndRetryFetch({
        response,
        authToken,
        requestUrl: response.url,
        fetchData,
        updateAuthTokenCallback,
        invalidateUserAccessCallback,
      })
    } catch {
      invalidateUserAccessCallback('Session expired')
      return Promise.reject(response)
    }
  } else {
    return Promise.resolve(response)
  }
}

const refreshAuthTokenAndRetryFetch = async ({
  response,
  authToken,
  fetchData,
  requestUrl,
  updateAuthTokenCallback,
  invalidateUserAccessCallback,
}: RefreshAuthTokenAndRetryFetch) => {
  const { verifySession } = authApi()
  try {
    if (!authToken) throw new Error('No auth token')

    const newAuthToken = await verifySession(authToken)

    setAuthToken(newAuthToken)

    return await retryResponseWithNewAuthToken({
      response,
      authToken: newAuthToken,
      fetchData,
      updateAuthTokenCallback,
      requestUrl,
    })
  } catch (error) {
    if (!!invalidateUserAccessCallback)
      invalidateUserAccessCallback('Session expired')

    return await retryResponseWithNewAuthToken({
      response,
      authToken: undefined,
      fetchData,
      updateAuthTokenCallback,
      requestUrl,
    })
  }
}

const retryResponseWithNewAuthToken = async ({
  authToken,
  fetchData,
  requestUrl,
  updateAuthTokenCallback,
}: RefreshAuthTokenAndRetryFetch) => {
  if (authToken) updateAuthTokenCallback(authToken)
  const newFetchData = buildData({
    ...fetchData,
    authToken: authToken?.token,
  })
  const retryFetch = await fetch(requestUrl, newFetchData).then(
    (retryResponse) => retryResponse,
  )
  return retryFetch
}
