import { memo } from 'react'
import { Icon } from '@campgladiator/cgui-core.atoms.icon'
import { Heading, Paragraph } from '@campgladiator/cgui-core.atoms.typography'
import { Tag as TagModel } from '../../../../redux/features/OnDemand/onDemandWorkouts'
import {
  DurationProps,
  WorkoutHighlightsProps,
} from '../../OnDemandWorkoutsDetail.d'
import { StyleTagHighlights } from '../StyleTagHighlights'
import styles from './WorkoutHighlights.module.scss'

const Duration = ({ durationTag, textSizes }: DurationProps) => (
  <div className={styles.iconAndText}>
    <Icon.Line className={styles.icon} name="icon-clock" />
    <Paragraph size={textSizes.title} className={styles.text}>
      {`${durationTag.title.toLowerCase()}`}
    </Paragraph>
  </div>
)

const workoutEquipmentTagArrayToText = (equipment: TagModel[]) => {
  if (equipment.length) {
    const equipmentItems = equipment.map((tag) =>
      tag.title.toLowerCase().replace(/\b(\w)/g, (text) => text.toUpperCase()),
    )

    const equipmentList = equipmentItems.join(', ')

    return equipmentList
  } else {
    return 'No equipment needed'
  }
}

const WorkoutHighlights = ({ workout, textSizes }: WorkoutHighlightsProps) => {
  const durationTag = workout.tags.find((tag) => tag.tagType === 'duration')
  const styleTag = workout.tags.find((tag) => tag.tagType === 'style')
  const equipmentTags = workout.tags.filter(
    (tag) => tag.tagType === 'equipment',
  )

  return (
    <>
      <Heading className={styles.heading} type="h5">
        Highlights
      </Heading>
      {styleTag && (
        <StyleTagHighlights textSizes={textSizes} styleTag={styleTag} />
      )}
      {durationTag && (
        <Duration textSizes={textSizes} durationTag={durationTag} />
      )}
      <div className={styles.iconAndText}>
        <Icon.Line className={styles.icon} name="icon-dumbbell" />
        <Paragraph size={textSizes.title} className={styles.text}>
          {workoutEquipmentTagArrayToText(equipmentTags)}
        </Paragraph>
      </div>
    </>
  )
}

export default memo(WorkoutHighlights)
