const sessionExpiredErrorMessage = [
  'is invalid. Please make a new sign-in request',
  '[401 unauthorized] during [POST]',
]

export const parseCheckInError = (errorMessage: any) => {
  if (typeof errorMessage !== 'string') return 'unknownError'

  const hasInvalidCredentials = sessionExpiredErrorMessage.some((message) =>
    errorMessage.toLowerCase().includes(message.toLowerCase()),
  )

  return hasInvalidCredentials ? 'Session Expired' : errorMessage
}
