import { Button } from '@campgladiator/cgui-core.atoms.button'
import { WorkoutCheckInProps } from '../../OnDemandWorkoutsDetail.d'
import styles from './WorkoutCheckIn.module.scss'

const WorkoutCheckIn = ({
  onCheckInClick,
  disabled = false,
  checkInStatus,
}: WorkoutCheckInProps) => (
  <div className={styles.workoutCheckIn}>
    <div className={styles.btnWrapper}>
      <Button
        size="default"
        className={styles.checkInButton}
        disabled={disabled}
        variation={disabled ? 'outline' : 'solid'}
        emphasis={disabled ? 'negative' : 'primary'}
        onClick={onCheckInClick}
      >
        {checkInStatus === 'tokenExpired' ? 'Login to check in' : 'Check In'}
      </Button>
    </div>
  </div>
)

export default WorkoutCheckIn
