import configuration from '../../../configuration'
import { AuthState, LoginParams } from '../../../redux/features/Auth'
import {
  AuthToken,
  AuthorizationResponse,
  KeanuUser,
  KeanuUserRole,
  SubscriptionSummary,
} from '../../../redux/features/Auth/auth'
import { request } from '../../utility/fetch'
import { setLoginError } from './logic/setLoginError'

const { apiGatewayUrl } = configuration

export const authBasePath = '/auth/v1'

const authApi = () => {
  const verifySession = async (authToken: AuthToken): Promise<AuthToken> => {
    const baseUrl = apiGatewayUrl
    const endpoint = `${authBasePath}/refresh-tokens/${authToken.refreshToken}`
    const method = 'GET'
    const response = await request({
      baseUrl,
      endpoint,
      method,
    })
    const result = await response.json()

    return response.ok
      ? ({ ...result } as AuthToken)
      : Promise.reject(new Error(result.error as AuthState['error']))
  }

  const loginUser = async (params: LoginParams): Promise<AuthToken> => {
    const baseUrl = apiGatewayUrl
    const endpoint = `${authBasePath}/authentications`
    const method = 'POST'
    const body = JSON.stringify({
      email: params.email,
      password: params.password,
    })
    const response = await request({ baseUrl, endpoint, method, body })
    const result = await response.json()

    return response.ok
      ? (result as AuthToken)
      : Promise.reject(
          new Error(setLoginError(result.message as AuthState['error'])),
        )
  }

  const fetchAuthorizationByToken = async (
    token: string,
    claims: KeanuUserRole[],
  ): Promise<AuthorizationResponse> => {
    const baseUrl = apiGatewayUrl
    const endpoint = `${authBasePath}/authorizations`
    const method = 'POST'
    const body = JSON.stringify({
      token,
      claims,
    })
    const response = await request({ baseUrl, endpoint, method, body })
    const result = await response.json()

    return response.ok
      ? ({ ...result } as AuthorizationResponse)
      : Promise.reject(new Error(result.error as AuthState['error']))
  }

  const fetchKeanuUserById = async (
    userServiceId: string,
  ): Promise<KeanuUser> => {
    const baseUrl = apiGatewayUrl
    const endpoint = `/user/v1/users/${userServiceId}`
    const method = 'GET'
    const response = await request({
      baseUrl,
      endpoint,
      method,
    })
    const result = await response.json()

    return response.ok
      ? ({ ...result } as KeanuUser)
      : Promise.reject(new Error(result.error as AuthState['error']))
  }

  const fetchUserSubscriptionsSummaries = async ({
    userId,
    status,
  }: {
    userId: string
    status?: SubscriptionSummary['status']
  }): Promise<SubscriptionSummary[]> => {
    const baseUrl = apiGatewayUrl
    const params = status ? `?status=${status}` : undefined
    const endpoint = `/billing/v1/users/${userId}/subscriptions/summaries`
    const method = 'GET'
    const response = await request({
      baseUrl,
      endpoint,
      method,
      params,
    })
    const result = await response.json()

    return response.ok
      ? (result as SubscriptionSummary[])
      : Promise.reject(new Error(result.error as AuthState['error']))
  }

  const fetchRefreshToken = async (
    refreshToken: AuthToken['refreshToken'],
  ): Promise<AuthorizationResponse> => {
    const baseUrl = apiGatewayUrl
    const endpoint = `${authBasePath}/refresh-tokens/${refreshToken}`
    const method = 'GET'
    const response = await request({
      baseUrl,
      endpoint,
      method,
    })
    const result = await response.json()

    return response.ok
      ? Promise.resolve({ ...result } as AuthorizationResponse)
      : Promise.reject(result.error as AuthState['error'])
  }

  const logoutExpireToken = async (authToken: AuthToken): Promise<boolean> => {
    const baseUrl = apiGatewayUrl
    const endpoint = '/auth/v1/logout'
    const method = 'POST'
    const response = await request({
      baseUrl,
      endpoint,
      method,
      authToken,
    })
    return response.ok ? true : Promise.reject((await response.json()).message)
  }

  return {
    fetchAuthorizationByToken,
    fetchKeanuUserById,
    fetchRefreshToken,
    fetchUserSubscriptionsSummaries,
    loginUser,
    verifySession,
    logoutExpireToken,
  }
}

export default authApi
