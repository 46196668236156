import { ReactNode } from 'react'
import { Card } from '@campgladiator/cgui-core.atoms.card'
import { WorkoutCardProps } from '../../../pages/OnDemandWorkouts/OnDemandWorkouts.d'
import { Workout } from '../../../redux/features/OnDemand/onDemandWorkouts'
import { Favorite } from '../Favorite'
import { BottomPartCard } from './components/BottomPartCard'
import { LiveBadge } from './components/LiveBadge'
import { UpperPartCard } from './components/UpperPartCard'
import styles from './WorkoutCard.module.scss'

type WorkoutCardType = { children: ReactNode }

const dynamicPathToDetailsPage = (workout: Workout) => `/workout/${workout.id}`

const WorkoutCard = ({ children }: WorkoutCardType) => <>{children}</>

const Ondemand = ({
  workout,
  isLoggedIn,
  onFavoriteClick,
  isLoadingFavorite,
}: WorkoutCardProps) => (
  <WorkoutCard>
    <Card className={styles.card} raised>
      {isLoggedIn && (
        <div className={styles.artifacts}>
          <Favorite
            condensed
            isLoadingFavorite={isLoadingFavorite}
            isFavorite={workout.favorite}
            workoutId={workout.id}
            onClick={() => onFavoriteClick(workout.id, workout.favorite)}
          />
        </div>
      )}
      <UpperPartCard.Ondemand
        workout={workout}
        redirectRoute={dynamicPathToDetailsPage}
      />
      <BottomPartCard.Ondemand
        workout={workout}
        redirectRoute={dynamicPathToDetailsPage}
      />
    </Card>
  </WorkoutCard>
)

const Virtual = ({
  workout,
  isLoggedIn,
  onFavoriteClick,
  onJoinLiveClick = () => {},
  onLoginClick = () => {},
  isLoadingFavorite,
  hasJoinedWorkout,
  token,
  type,
  user,
  onCheckInClick,
  isCheckinLoading,
  checkedInWorkout,
}: WorkoutCardProps) => (
  <WorkoutCard>
    <Card className={styles.card} raised>
      {isLoggedIn && (
        <div className={styles.artifacts}>
          {workout.isLive && <LiveBadge />}
          <Favorite
            condensed
            isLoadingFavorite={isLoadingFavorite}
            isFavorite={workout.favorite}
            workoutId={workout.id}
            onClick={() => onFavoriteClick(workout.id, workout.favorite)}
          />
        </div>
      )}
      <UpperPartCard.Virtual
        workout={workout}
        isLoggedIn={isLoggedIn}
        onJoinLiveClick={onJoinLiveClick}
        user={user}
      />
      <BottomPartCard.Virtual
        workout={workout}
        isLoggedIn={isLoggedIn}
        redirectRoute={dynamicPathToDetailsPage}
        hasJoinedWorkout={hasJoinedWorkout}
        user={user}
        onJoinLiveClick={onJoinLiveClick}
        onLoginClick={onLoginClick}
        token={token}
        type={type}
        onCheckInClick={onCheckInClick}
        isCheckinLoading={isCheckinLoading}
        checkedInWorkout={checkedInWorkout}
      />
    </Card>
  </WorkoutCard>
)

export default { WorkoutCard, Ondemand, Virtual }
